import React, {FC, useState, useRef, useEffect} from "react";
import { stringify } from 'qs';

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { EmployeeAttendanceDayReport, EmployeeAttendanceDayReportQto } from './../../type/EmployeeAttendanceDayReportType';

import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormDatePicker, ProFormInstance, ProFormText } from "@ant-design/pro-form";
import moment from "moment";
import EmployeeAttendanceReportApi from "../../api/EmployeeAttendanceReportApi";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import AttendanceResultTypeDisplay from "../AttendanceLog/AttendanceResultTypeDisplay";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";

interface EmployeeAttendanceDayReportModalProps {
    onCancel: ()=>void,
    queryParams: {
        reportDate: string,
        tenantId?: string,
        orgId?: string,
        workTeamId?: string,
        workType?: string,
        withAttendanceLogsFlag?: boolean,
    }
}

const EmployeeAttendanceDayReportModal:React.FC<EmployeeAttendanceDayReportModalProps> = (props)=>{

    const queryFormRef = useRef<ProFormInstance<EmployeeAttendanceDayReportQto>>();

    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<EmployeeAttendanceDayReport>>([]); //表格列
    const [queryParams, setQueryParams] = useState<any>(props.queryParams); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态

    const defaultColumns:ColumnsType<EmployeeAttendanceDayReport> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "jobNo",
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "orgName",
            title: "参建单位",
            dataIndex: "orgName",
            width: 220,
            ellipsis: true,
        },
        {
            key: "workTeamName",
            title: "班组",
            dataIndex: "workTeamName",
            width: 140,
            ellipsis: true,
        },
        {
            key: "workType",
            title: "工种",
            dataIndex: "workType",
            width: 140,
            ellipsis: true,
            render:(val,record)=>{
                if(record.workRole=='10'){
                    return (
                        <DictItemDisplay
                            entryCode="JZ_MANAGER_POST_TYPE"
                            value={record.manageType}
                        />
                    );
                }else{
                    return (
                        <DictItemDisplay
                            entryCode="HR_WORK_TYPE"
                            value={record.workType}
                        />
                    );
                }
            }
        },
        {
            key: "checkDate",
            title: "出勤日期",
            dataIndex: "checkDate",
            width: 180,
            ellipsis: true,
            render:(val)=>{
                return val?.toString()?.substring(0,10)
            }
        },
        {
            key: "firstCheckTime",
            title: "首次打卡",
            dataIndex: "firstCheckTime",
            width: 120,
            ellipsis: true,
            render:(val)=>{
                return val?.toString()?.substring(11, 19)
            },
        },
        {
            key: "lastCheckTime",
            title: "末次打卡",
            dataIndex: "lastCheckTime",
            width: 120,
            ellipsis: true,
            render:(val)=>{
                return val?.toString()?.substring(11, 19)
            },
        },
        {
            key: "checkHours",
            title: "工时数",
            dataIndex: "checkHours",
            width: 120,
            ellipsis: true,
            render: (val)=>{
                return val?.toFixed(1)
            }
        },
        {
            key: "checkTimes",
            title: "考勤次数",
            dataIndex: "checkTimes",
            width: 120,
            ellipsis: true,
        },
        {
            key: "resultType",
            title: "考勤结果",
            dataIndex: "resultType",
            width: 120,
            ellipsis: true,
            render:(val)=>{
                return <AttendanceResultTypeDisplay value={val} />
            }
        },
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);

        const newQueryParams = {
            ...queryParams,
            ...params,
            deletedFlag: false,
        }
        setQueryParams(newQueryParams);

        EmployeeAttendanceReportApi.attendanceDayReport(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});
    }, []);

    return (
        <Modal
            title="人员考勤信息"
            visible={true}
            width={1200}
            onCancel={props.onCancel}
        >
            <div>
                <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                    <div style={{fontWeight:'bold', display:'inline-block'}}>
                        人员考勤日统计
                    </div>
                    <div style={{display: 'inline-block', float: 'right'}}>
                        <Space size={16}>
                            <Dropdown.Button
                                overlay={
                                <Menu
                                        onClick={({key})=>{
                                            if(key==='batchExport'){
                                                const newQueryParams = {
                                                    ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current?.getFieldsFormatValue()||{}),
                                                    Authorization: localStorage.getItem('Authorization')||"",
                                                };

                                                window.open(`/api/tenant/hr/employee-attendance-report/employee-attendance-day-report/excel?${stringify(newQueryParams)}`);
                                            }
                                        }}
                                        items={[{
                                            key: 'batchExport',
                                            label: '导出',
                                        }]}
                                />
                                }
                            >
                                Excel导出
                            </Dropdown.Button>
                            <RedoOutlined
                                style={{fontSize:16, cursor:'pointer'}}
                                onClick={()=>{
                                    reloadData({});
                                }}
                            />
                            <TableSizeSetting
                                onChange={(val)=>{
                                    setTableSize(val)
                                }}
                            />
                            <TableColumnSetting
                                columns={columns}
                                onChange={(value)=>{
                                    setColumns(value)
                                }}
                            />
                        </Space>
                    </div>
                </div>
                <div>
                        <Spin spinning={spinning}>
                            <Table
                                bordered
                                dataSource={pageData?.list}
                                pagination={{
                                    current: queryParams?.page||1,
                                    pageSize: queryParams?.pageSize||10,
                                    total: pageData?.total,
                                    pageSizeOptions: [10, 20, 50, 100],
                                    showQuickJumper: true,
                                    showSizeChanger: true,
                                    onChange:(page, pageSize)=>{
                                        reloadData({
                                            page: page,
                                            pageSize: pageSize,
                                        });
                                    },
                                    onShowSizeChange:(page, pageSize)=>{
                                        reloadData({
                                            page: page,
                                            pageSize: pageSize,
                                        });

                                    }
                                }}
                                size={tableSize}
                                rowKey="id"
                                columns={columns}
                                scroll={{x: TableUtils.getTableScrollX(columns), scrollToFirstRowOnChange:true}}
                            />
                        </Spin>
                    </div>
            </div>
        </Modal>
    );
};

export default EmployeeAttendanceDayReportModal;