import React, { Component, useState, useEffect, useContext, createContext, Children } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import {useSelector, useDispatch} from 'dva';

import {Button, Dropdown, Layout, Menu, Tabs} from 'antd';
import {LoginOutlined} from '@ant-design/icons';

import Footer from './../../components/Footer';
import GlobalHeaderRight from '../../components/RightContent';
import StaffPositionAuthorizedResourceApi from '../../api/StaffPositionAuthorizedResourceApi';

import './HomePage.less';
import Error404 from '../error/Error404';
import Dashboard from './Dashboard';
import DictItemApi from '../../../system/api/DictItemApi';
import { DictItem } from '../../../system/type/DictItemType';
import ProjectInfoApi from '../../../jz/api/ProjectInfoApi';

const parseMenus = (items:any)=>{
    if(!items){
        return null;
    }

    const menus = items.map((i:any)=>({
        label: i.name,
        title: i.name,
        key: i.id,
        children: parseMenus(i.children)
    }));

    return menus;
}

const findMenuById:any = (id:any, menus:any)=>{

    if(menus==null){
        return null;
    }

    let menu = null;

    for(let i=0; i<menus.length; i++){
        if(menus[i].id===id){
            return menus[i];
        }

        menu = findMenuById(id, menus[i].children);

        if(menu!=null){
            return menu;
        }
    }

    return null;
}

export default ()=>{

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [accessToken, setAccessToken] = useState<string|null>();
    const [menus, setMenus] = useState<any>([]);
    const [tabKeyList, setTabKeyList] = useState<any>([]);
    const [activeTabKey, setActiveTabKey] = useState<string>('/home');
    const {settings, routeList, staffPosition} = useSelector((state:any)=>(state['global']));
    const [menuCollapsed, setMenuCollapsed] = useState<boolean>(false);

    const token = localStorage.getItem("Authorization");

    if(token!=accessToken){
        setAccessToken(token);
    }
    
    useEffect(()=>{
    
        if(!token) {
            navigate("/login");
        }

        StaffPositionAuthorizedResourceApi.getCurrentPosition().then((staffPosition)=>{
            ProjectInfoApi.findProjectInfoById(staffPosition.tenantId).then((projectInfo)=>{
                dispatch({
                    type: 'global/updateState',
                    payload: {
                        staffPosition,
                        projectInfo: projectInfo,
                    },
                });
            })
        });

        StaffPositionAuthorizedResourceApi.findAuthorizedMenuTree({}).then((menus)=>{
            setMenus(menus);
        });
        
        DictItemApi.findDictItemList({
            limit: 10000000,
            deletedFlag: false,
        }).then((dictList:any)=>{
            const dictEntryMap:{[propName:string]:any} = {};
            const dictEntryList:{[propName:string]:any} = {};

            (dictList||[]).forEach((item:DictItem)=>{
                if(item.entryCode){
                    if(!dictEntryMap[item.entryCode]){
                        dictEntryMap[item.entryCode] = {};
                        dictEntryList[item.entryCode] = [];
                    }
                    dictEntryMap[item.entryCode][item.value] = item;
                    dictEntryList[item.entryCode].push(item);
                }
            });

            dispatch({
                type: 'global/updateState',
                payload: {
                    dictEntryMap,
                    dictEntryList,
                },
            });
        });

    }, [accessToken]);

    if((location.pathname==='' || location.pathname==='/' || location.pathname==='/home') && activeTabKey!=='/home') {
        setActiveTabKey('/home');
    } else if(tabKeyList.indexOf(location.pathname)<0){
        
        let hasPage = false;

        if(location.pathname=='/home' || location.pathname==='/'){
            hasPage = true;
        }else{
            routeList.forEach((i:any)=>{
                if(i.path==location.pathname){
                    hasPage = true;
                }
            });
        }

        if(!hasPage){
            navigate('/error/404');
        }else{
            tabKeyList.push(location.pathname);
            setTabKeyList([...tabKeyList]);
            setActiveTabKey(location.pathname);
        }
    } else if(location.pathname!==activeTabKey){
        setActiveTabKey(location.pathname);
    }

    return (
        <div className='HomePage'>
            <Layout style={{width:'100%', height:'100%'}}>
                <Layout.Header
                    className='HomeHeader'
                >
                    <div className="LogoTitle">乐建云工程建设项目现场管理系统 1.0</div>

                    <div>
                        <Dropdown
                            overlay={
                                <Menu 
                                    onClick={({key})=>{
                                        if(key==='logout') {
                                            localStorage.clear();
                                            navigate("/login");
                                        }
                                    }}
                                    items={[{
                                        key: 'logout',
                                        icon: <LoginOutlined />,
                                        label: (
                                            <a>注销</a>
                                        ),
                                    }]}
                                />
                            }
                        >
                            <a>
                                {staffPosition?.staff?.name} ({staffPosition?.org?.name})
                            </a>
                        </Dropdown>
                    </div>
                </Layout.Header>

                <Layout>
                    <Layout.Sider style={{backgroundColor: '#ffffff', overflow: 'auto'}}>
                        <Menu
                            mode="inline"
                            theme='light'
                            items={parseMenus(menus)}
                            inlineCollapsed={menuCollapsed}
                            onClick={({key})=>{
                                const menu = findMenuById(key, menus);

                                navigate(menu.linkPath)
                            }}
                        >

                        </Menu>
                    </Layout.Sider>
                    <Layout.Content>
                        <Tabs 
                            size='small'
                            type="editable-card" 
                            className="HomeContent"
                            tabBarStyle={{paddingTop:2}}
                            activeKey={activeTabKey}
                            hideAdd={true}
                            onChange={(activeKey)=>{
                            if(activeKey!==activeTabKey){
                                    navigate(activeKey);
                            }
                            }}
                            onEdit={(targetKey, action)=>{
                                if(action==='remove'){
                                    const newTabKeyList = tabKeyList.filter((i:any)=>i!==targetKey);
                                    setTabKeyList(newTabKeyList);
                                    if(targetKey===activeTabKey){
                                        navigate('/home');
                                    }
                                }
                            }}
                        >
                            <Tabs.TabPane 
                                tab="首页" 
                                tabKey="/home" 
                                key="/home" 
                                closable={false} 
                                className='HomeContentTabBody'
                            >
                                <div className='TabPaneBody'>
                                    <Dashboard />
                                </div>
                            </Tabs.TabPane>

                            {
                                routeList.map(({
                                    path,
                                    title,
                                    component : Component
                                }:{
                                    path: string,
                                    title: string,
                                    component: React.FC
                                })=>{
                                    if(path==="/"){
                                        return null;
                                    }
                                    if(tabKeyList.indexOf(path)>=0){
                                        return (
                                            <Tabs.TabPane 
                                                tab={title} 
                                                tabKey={path} 
                                                key={path} 
                                                closable={true}
                                                className='HomeContentTabBody'
                                            >
                                                <div className='TabPaneBody'>
                                                    <Component />
                                                </div>
                                            </Tabs.TabPane>
                                        );
                                    }
                                })
                            }
                        </Tabs>
                    </Layout.Content>
                </Layout>
            </Layout>
            
        </div>
    );
}
