import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import type {ProFormProps} from '@ant-design/pro-form';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDigit, } from '@ant-design/pro-form';

import OrganizationSelector from "./../../../system/pages/Organization/OrganizationSelector";

import { Organization } from './../../type/OrganizationType';
import OrganizationApi from '../../api/OrganizationApi';
import { message } from 'antd';

export interface OrganizationAddFormProps extends DrawerProps {
    onRefresh?:()=>void,
    onClose:()=>void,
};


const OrganizationAddForm:React.FC<OrganizationAddFormProps> = (props)=>{

    const [form] = Form.useForm<Organization>();
    const {onClose} = props;

    const formItemLayout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 14 },
    };

    return (
        <Drawer
            title="新建机构"
            destroyOnClose={false}
            closable={false}
            width={800}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onRefresh')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right'}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Organization)=>{
                    await OrganizationApi.addOrganization(values);
                    form.resetFields();
                    message.success("新建机构成功！");
                    onClose();
                    return true;
                }}
                form={form}
                layout={'horizontal'}
                {...formItemLayout}
            >
                <Row>
                        <Col span={12}>
                            <OrganizationSelector
                                name={['parentId']}
                                label="上级机构ID"
                                tooltip=""
                                placeholder=""
                                required={false}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['code']}
                                label="机构编码"
                                tooltip=""
                                placeholder=""
                                required={true}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['name']}
                                label="机构名称"
                                tooltip=""
                                placeholder=""
                                required={true}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['nameAbbr']}
                                label="机构简称"
                                tooltip=""
                                placeholder=""
                                required={false}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                name={['sortNo']}
                                label="显示顺序"
                                tooltip=""
                                placeholder=""
                                required={false}
                            />
                        </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

    export default OrganizationAddForm;