import _ from 'lodash';

import { DrawerProps, Modal } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef, useState } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormSwitch, ProFormText, ProFormDigit, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';
import OrganizationSelector from "./../../../system/pages/Organization/OrganizationSelector";

import { LedMachine } from './../../type/LedMachineType';
import LedMachineApi from '../../api/LedMachineApi';
import { message } from 'antd';
import LedMachineMetaInfoApi from '../../../iot/api/LedMachineMetaInfoApi';

export interface LedMachineAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const LedMachineAddForm:React.FC<LedMachineAddFormProps> = (props)=>{

    const [form] = Form.useForm<LedMachine>();
    const {onFail, onSuccess} = props;
    const [rowsCount, setRowsCount] = useState<number>(0);
    const rows = [];
    for(let i=0; i<rowsCount; i++){
        rows.push(i);
    }

    return (
        <Drawer
            title="新建LED屏幕"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  LedMachine)=>{
                    const ledMachineList = await LedMachineMetaInfoApi.findLedMachineMetaInfoList({
                        sn: values?.sn||"undefined"
                    });

                    if(!ledMachineList || ledMachineList.length<1){
                        
                        Modal.error({
                            title: '错误提示',
                            content:"设备[" + values.sn + "]未注册，请联系平台先注册该设备！"
                        });
                        return false;
                    }
                    values.id=ledMachineList[0].id;

                    await LedMachineApi.addLedMachine(values);
                    form.resetFields();
                    message.success("新建LED屏幕成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                onValuesChange={(values)=>{
                    if(values.rowCount){
                        setRowsCount(values.rowCount);
                    }
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <OrganizationSelector
                            name={['orgId']}
                            label="组织机构ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "组织机构ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enabled']}
                            label="启用"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "启用不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="设备名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['sn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['width']}
                            label="宽度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "宽度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['height']}
                            label="高度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "高度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={24}>
                                <ProFormDigit
                                    name={['rowCount']}
                                    label="行数"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "行数不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>

                            {
                                rows.map(i=>{
                                    return (
                                        <Col span={24}>
                                            <ProFormText
                                                name={['content', i]}
                                                label={"第" + (1+i) + "行"}
                                                tooltip=""
                                                placeholder=""
                                                rules={[{required:true, message: "行内容不能为空"}]}
                                                {...FormLayout.itemLayout.span12}
                                            />
                                        </Col>
                                    );
                                })
                            }
                        </Row>
                    </Col>
                    <Col span={12}>
                        
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['description']}
                            label="描述"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "描述不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default LedMachineAddForm;