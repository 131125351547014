
import {AttendanceMachineMetaInfo, AttendanceMachineMetaInfoQto} from './../type/AttendanceMachineMetaInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addAttendanceMachineMetaInfo = async (params:AttendanceMachineMetaInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/attendance-machine-meta-info`, params);
};


const editAttendanceMachineMetaInfo = async (params:AttendanceMachineMetaInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/attendance-machine-meta-info/${params.id}`, params);
};


const patchEditAttendanceMachineMetaInfo = async (params:AttendanceMachineMetaInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/attendance-machine-meta-info/${params.id}`, params);
};


const deleteAttendanceMachineMetaInfo = async (params:AttendanceMachineMetaInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/attendance-machine-meta-info/${params.id}`, params);
};


const findAttendanceMachineMetaInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-meta-info/${params}`, params);
};

const findAttendanceMachineMetaInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-meta-info/list`, params);
};

const findAttendanceMachineMetaInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/attendance-machine-meta-info/page`, params);
};



export default {
    addAttendanceMachineMetaInfo,
    editAttendanceMachineMetaInfo,
    patchEditAttendanceMachineMetaInfo,
    deleteAttendanceMachineMetaInfo,
    findAttendanceMachineMetaInfoById,
    findAttendanceMachineMetaInfoList,
    findAttendanceMachineMetaInfoPage,
};


