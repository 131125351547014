import type { CSSProperties } from 'react';
import { useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";

import { Tabs, Space, Image, Popover } from 'antd';
import { LoginForm, ProFormText, ProFormCheckbox } from '@ant-design/pro-form';
import {
  UserOutlined,
  LockOutlined,
  WindowsOutlined,
  AppleOutlined,
  AndroidOutlined,
} from '@ant-design/icons';

import AuthenticationApi from '../../api/AuthenticationApi';

import './LoginPage.less';
import I from './../../../../assets/images/laowuqing_qr_code.jpg';
import GlobalConst from '../../../../utils/GlobalConst';


type LoginType = 'project'|'employee'|'corpration'|'government'|'platform';


export default () => {
  const [loginType, setLoginType] = useState<LoginType>('project');
  const navigate = useNavigate();

  return (
    <div className='loginPage'>
      
      <div className='pageContent'>
        <div className='left'>

        </div>

        <div className='right'>
          <div className='rightRow1'>

          </div>

          
          <div className='rightRow2'>
          <LoginForm
            contentStyle={{minWidth:420}}
            style={{backgroundColor:'white', padding:32, minWidth:420}}
                        title="系统登入"
                        subTitle="乐建云工程建设项目现场管理系统"
                        actions={
                            <Space>

                            </Space>
                        }
                        onFinish={async (values)=>{

                          const token = await AuthenticationApi.login(values);
                          localStorage.setItem("Authorization", token);
                          setTimeout(()=>{
                            navigate("/home");
                          }, 500);
                        }}
          >
                <Tabs activeKey={loginType} onChange={(activeKey) => setLoginType(activeKey as LoginType)}>
                  <Tabs.TabPane key={'employee'} tab="工人端">
                        <div style={{padding:16, textAlign:'center'}}>
                          <Image
                              style={{marginLeft:'auto', marginRight:'auto'}}
                                src={I}
                                width={160}
                                height={160}
                            />

                            <div style={{color:'red', fontSize:'14px'}}>请用微信扫码关注“劳务清”公众号</div>
                        </div>

                  </Tabs.TabPane>
                  <Tabs.TabPane key={'project'} tab={'项目端'}>
                            <ProFormText
                              name="username"
                              fieldProps={{
                                  size: 'large',
                                  prefix: <UserOutlined className={'prefixIcon'} />,
                              }}
                              placeholder={'请输入用户名'}
                              rules={[
                                  {
                                  required: true,
                                  message: '用户名不能为空',
                                  },
                              ]}
                            />
                            <ProFormText.Password
                              name="password"
                              fieldProps={{
                                  size: 'large',
                                  prefix: <LockOutlined className={'prefixIcon'} />,
                              }}
                              placeholder={'请输入密码'}
                              rules={[
                                  {
                                  required: true,
                                  message: '密码不能为空',
                                  },
                              ]}
                            />
                            <div
                              style={{
                                  marginBottom: 24,
                              }}
                              >
                                客户端下载：
                              <Space>
                                <Popover title="Windows桌面客户端" content={
                                  <div style={{lineHeight:'2em'}}>
                                    客户端支持Widnows 7/8/10/11<br/> <a target={'_blank'} href={GlobalConst.DESKTOP_APP_DOWNLOAD_URL}>开始下载</a>
                                  </div>
                                }>
                                  <WindowsOutlined style={{fontSize:'24px'}} />
                                </Popover>

                                <Popover title="Android移动客户端" content={
                                  <div style={{lineHeight:'2em'}}>
                                    客户端支持Android12以上<br/> 
                                    <div style={{textAlign:'center'}}>
                                      <Image 
                                        src={GlobalConst.ANDROID_APP_DOWNLOAD_URL}
                                        fallback={GlobalConst.EMPTY_IMAGE}
                                        width={160}
                                        height={160}
                                        style={{marginLeft:'auto', marginRight: 'auto'}}
                                      />
                                    </div>
                                  </div>
                                }>
                                  <AndroidOutlined style={{fontSize:'24px'}} />
                                </Popover>

                                <Popover title="IOS移动客户端" content={
                                  <div style={{lineHeight:'2em'}}>
                                    客户端支持IOS12以上<br/> 
                                    <div style={{textAlign:'center'}}>
                                      <Image 
                                        src={GlobalConst.IOS_APP_DOWNLOAD_URL}
                                        fallback={GlobalConst.EMPTY_IMAGE}
                                        width={160}
                                        height={160}
                                        style={{marginLeft:'auto', marginRight: 'auto'}}
                                      />
                                    </div>
                                  </div>
                                }>
                                  <AppleOutlined style={{fontSize:'24px'}}  />
                                </Popover>
                              </Space>
                            </div>
                        
                  </Tabs.TabPane>
                </Tabs>
            </LoginForm>
          </div>

          <div  className='rightRow3'>

          </div>
        </div>
      </div>
      
      <div className='pageFooter'>
        版权所有：浙江鸿正软件科技有限公司&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;QQ：1065809955
      </div>
    </div>
  );
};