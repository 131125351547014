
import {StaffPosition, StaffPositionQto} from './../type/StaffPositionType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addStaffPosition = async (params:StaffPosition)=>{

    return HttpInvoker.postObject(`/api/tenant/system/staff-position`, params);
};


const editStaffPosition = async (params:StaffPosition)=>{

    return HttpInvoker.putObject(`/api/tenant/system/staff-position/${params.id}`, params);
};


const patchEditStaffPosition = async (params:StaffPosition)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/staff-position/${params.id}`, params);
};


const deleteStaffPosition = async (params:StaffPosition)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/staff-position/${params.id}`, params);
};


const findStaffPositionById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff-position/${params}`, params);
};

const findStaffPositionList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff-position/list`, params);
};

const findStaffPositionPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/staff-position/page`, params);
};



export default {
    addStaffPosition,
    editStaffPosition,
    patchEditStaffPosition,
    deleteStaffPosition,
    findStaffPositionById,
    findStaffPositionList,
    findStaffPositionPage,
};


