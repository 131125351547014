
import {ReportedAttendanceMachine, ReportedAttendanceMachineQto} from './../type/ReportedAttendanceMachineType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addReportedAttendanceMachine = async (params:ReportedAttendanceMachine)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/reported-attendance-machine`, params);
};


const editReportedAttendanceMachine = async (params:ReportedAttendanceMachine)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/reported-attendance-machine/${params.id}`, params);
};


const patchEditReportedAttendanceMachine = async (params:ReportedAttendanceMachine)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/reported-attendance-machine/${params.id}`, params);
};


const deleteReportedAttendanceMachine = async (params:ReportedAttendanceMachine)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/reported-attendance-machine/${params.id}`, params);
};


const findReportedAttendanceMachineById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/reported-attendance-machine/${params}`, params);
};

const findReportedAttendanceMachineList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/reported-attendance-machine/list`, params);
};

const findReportedAttendanceMachinePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/reported-attendance-machine/page`, params);
};



export default {
    addReportedAttendanceMachine,
    editReportedAttendanceMachine,
    patchEditReportedAttendanceMachine,
    deleteReportedAttendanceMachine,
    findReportedAttendanceMachineById,
    findReportedAttendanceMachineList,
    findReportedAttendanceMachinePage,
};


