
import {EmployeeBankCard, EmployeeBankCardQto} from './../type/EmployeeBankCardType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addEmployeeBankCard = async (params:EmployeeBankCard)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/employee-bank-card`, params);
};


const editEmployeeBankCard = async (params:EmployeeBankCard)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/employee-bank-card/${params.id}`, params);
};


const patchEditEmployeeBankCard = async (params:EmployeeBankCard)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/employee-bank-card/${params.id}`, params);
};


const deleteEmployeeBankCard = async (params:EmployeeBankCard)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/employee-bank-card/${params.id}`, params);
};


const findEmployeeBankCardById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-bank-card/${params}`, params);
};

const findEmployeeBankCardList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-bank-card/list`, params);
};

const findEmployeeBankCardPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-bank-card/page`, params);
};



export default {
    addEmployeeBankCard,
    editEmployeeBankCard,
    patchEditEmployeeBankCard,
    deleteEmployeeBankCard,
    findEmployeeBankCardById,
    findEmployeeBankCardList,
    findEmployeeBankCardPage,
};


