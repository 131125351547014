
import {SubWorkType, SubWorkTypeQto} from './../type/SubWorkTypeType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addSubWorkType = async (params:SubWorkType)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/sub-work-type`, params);
};


const editSubWorkType = async (params:SubWorkType)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/sub-work-type/${params.id}`, params);
};


const patchEditSubWorkType = async (params:SubWorkType)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/sub-work-type/${params.id}`, params);
};


const deleteSubWorkType = async (params:SubWorkType)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/sub-work-type/${params.id}`, params);
};


const findSubWorkTypeById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/sub-work-type/${params}`, params);
};

const findSubWorkTypeList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/sub-work-type/list`, params);
};

const findSubWorkTypePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/sub-work-type/page`, params);
};



export default {
    addSubWorkType,
    editSubWorkType,
    patchEditSubWorkType,
    deleteSubWorkType,
    findSubWorkTypeById,
    findSubWorkTypeList,
    findSubWorkTypePage,
};


