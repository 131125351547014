import ProForm, { ProFormDatePicker, ProFormDigit, ProFormRadio, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Modal, Space, Form, Row, Col, Tag, Divider } from "antd";
import React, { useState } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import EmployeeExitApi from "../../api/EmployeeExitApi";
import { Employee } from "../../type/EmployeeType";

interface EmployeeBatchExitProps {
    employees: Employee[],
    onSuccess: ()=>void,
    onFail: ()=>void,
    visible: boolean,
}

const EmployeeBatchExit:React.FC<EmployeeBatchExitProps> = (props)=>{
    
    const [form] = Form.useForm<any>();
    const {employees, onSuccess, onFail, visible} = props;

    return (
        <Modal
            title={"人员离职"}
            visible={visible}
            footer={null}
            onCancel={()=>{
                onFail();
            }}
        >
            <div>
                {(employees||[]).map(e=>{
                    return (
                        <Tag key={e.id}>{e.name}({e.jobNo})</Tag>
                    );
                })}
            </div>
            <Divider orientation="left">离职信息</Divider>
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:any)=>{
                    for(let i=0; i<employees.length; i++){
                        const emp = employees[i];

                        const newValues = {
                            employeeId: emp.id,
                            ...values,
                        };

                        try{
                            await EmployeeExitApi.addEmployeeExit(newValues);

                            onSuccess();
                        }catch(ex){
                            return false;
                        }
                    }
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                        <Col span={24}>
                            <ProFormDatePicker
                                name={['exitTime']}
                                label="离职日期"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "离职时间不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                </Row>
            </ProForm>
        </Modal>
    );
};

export default EmployeeBatchExit;