import _ from 'lodash';

import { Divider, DrawerProps, TimePicker } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormProps, ProFormTimePicker} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDigit, ProFormDatePicker, ProFormSwitch, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';
import OrganizationSelector from "./../../../system/pages/Organization/OrganizationSelector";

import { WorkTeam } from './../../type/WorkTeamType';
import WorkTeamApi from '../../api/WorkTeamApi';
import { message } from 'antd';

export interface WorkTeamAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const WorkTeamAddForm:React.FC<WorkTeamAddFormProps> = (props)=>{

    const [form] = Form.useForm<WorkTeam>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建班组"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  WorkTeam)=>{
                    await WorkTeamApi.addWorkTeam(values);
                    form.resetFields();
                    message.success("新建班组成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
                initialValues={{
                    sortNo:10,
                    checkBeginTime: '00:00',
                    checkEndTime: '23:59',
                    checkTimes: 2,
                    validRangeMinutes: 360,
                    halfCheckTimes: 0,
                    halfValidRangeMinutes: 0,
                }}
            >
                <Row>
                    <Col span={12}>
                        <OrganizationSelector
                            name={['orgId']}
                            label="参建单位"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "参建单位不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="班组名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "班组名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['sortNo']}
                            label="显示顺序"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "显示顺序不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['description']}
                            label="描述"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "描述不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation='left' style={{fontWeight:'bold'}}>进离场信息</Divider>
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['entryTime']}
                            label="进场日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "进场日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['exitTime']}
                            label="离场日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "离场日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation='left' style={{fontWeight:'bold'}}>考勤设置</Divider>
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enableMobileCheckWithoutImage']}
                            label="启用手机端无照考勤"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "启用手机端无照考勤不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enableMobileCheckWithImage']}
                            label="启用手机端照片考勤"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "启用手机端照片考勤不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormTimePicker
                            name={['checkBeginTime']}
                            label="考勤开始时段"
                            tooltip=""
                            fieldProps={{
                                format:'HH:mm'
                            }}
                            rules={[{required:true, message: "考勤开始时段不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormTimePicker
                            name={['checkEndTime']}
                            label="考勤结束时段"
                            tooltip=""
                            fieldProps={{
                                format:'HH:mm'
                            }}
                            rules={[{required:true, message: "考勤结束时段不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['checkTimes']}
                            label="打卡次数"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "打卡次数不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['validRangeMinutes']}
                            label="两卡间隔分钟"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "两卡时间间隔不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['halfCheckTimes']}
                            label="半日打卡次数"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "半日打卡次数不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['halfValidRangeMinutes']}
                            label="半日两卡时间间隔"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "半日两卡时间间隔不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default WorkTeamAddForm;