import React from 'react';
import {useSelector, useDispatch} from 'dva';

import { ProFormSelect } from '@ant-design/pro-form';
import { group } from 'console';

interface DictItemDisplayProps {
    entryCode: string,
    value: string,
    defaultText?: string,
    groupKey?: string,
    [propName:string]:any,
}

const DictItemDisplay:React.FC<DictItemDisplayProps> = (props)=>{

    const {entryCode, value, groupKey, defaultText} = props;
    const {dictEntryList} = useSelector((state:any)=>(state['global']));

    let items = (dictEntryList[entryCode]||[]).filter((i:any)=>{
        return !groupKey || i.groupKey===groupKey;
    });

    let text = null;

    for(let i=0; i<items.length; i++){
        if(items[i].value===value){
            text = items[i].text;
            break;
        }
    }

    return (
        <span>
            {text || defaultText || "-"}
        </span>
    );
}

export default DictItemDisplay;