import _, { values } from 'lodash';

import { Alert, DrawerProps, Image, Modal } from 'antd';
import {useSelector, useDispatch} from 'dva';

import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormInstance, ProFormProps, ProFormRadio, StepsForm} from '@ant-design/pro-form';

import React, { useRef, useState } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormSwitch, ProFormDatePicker, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { Employee } from './../../type/EmployeeType';
import EmployeeApi from '../../api/EmployeeApi';
import { message } from 'antd';
import SingleImageSelector from '../../../file/component/SingleImageSelector';
import DictItemSelector from '../../../system/pages/DictItem/DictItemSelector';
import SubWorkTypeSelector from '../SubWorkType/SubWorkTypeSelector';
import WorkTeamSelector from '../WorkTeam/WorkTeamSelector';
import OrganizationSelector from '../../../system/pages/Organization/OrganizationSelector';
import ContractorApi from '../../api/ContractorApi';
import { Contractor } from '../../type/ContractorType';
import PersonApi from '../../../jz/api/PersonApi';

import I from './../../../../assets/images/check_samples.png';
import EmployeeAttendanceInfoApi from '../../api/EmployeeAttendanceInfoApi';
import FileApi from './../../../file/api/FileApi';
import moment from 'moment';
import GlobalConst from '../../../../utils/GlobalConst';

export interface EmployeeAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};

declare global {
    interface Window {
        IDCardReader: any,
        store: any,
    }
}

const EmployeeAddForm:React.FC<EmployeeAddFormProps> = (props)=>{

    const mobileForm = useRef<ProFormInstance>();
    const basicForm = useRef<ProFormInstance>();
    const contactForm = useRef<ProFormInstance>();
    const workInfoForm = useRef<ProFormInstance>();

    const {onFail, onSuccess} = props;
    const [formStepKey, setFormStepKey] = useState();
    const [employee, setEmployee] = useState<any>({});
    const [formValues, setFormValues] = useState({});
    const [attendanceInfoFormValues, setAttendanceInfoFormValues] = useState({});
    const {staffPosition} = useSelector((state:any)=>(state['global']));
    const [contractor, setContractor] = useState<Contractor>({});
    const [workRole, setWorkRole] = useState<string>("10");

    return (
        <Drawer
            title="新建工人信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <StepsForm<{
                    name: string
                }>
                submitter={{
                    render:(props)=>{
                        if(props.step===0) {
                            return (
                                <Button key="next" type="primary" onClick={() => props.onSubmit?.()}>
                                    下一步 {'>'}
                                </Button>
                            );
                        }

                        if(props.step===1){
                            return [
                                <Button key="pre" onClick={() => props.onPre?.()}>
                                    上一步
                                </Button>,
                                <Button key="next" type="primary" onClick={() => props.onSubmit?.()}>
                                    下一步 {'>'}
                                </Button>,
                                <Button key="readIdCard" type="primary" onClick={()=>{
                                    if(!window.IDCardReader){
                                        message.error("不支持WEB网页进行此操作，请打开劳务清桌面软件进行此操作");
                                        return;
                                    }

                                    window.IDCardReader.readBaseInfosAndPhoto("IDR210", async (result:any)=>{
                                        console.log(result)
                                        const info = result.data;
                                        let img1Url = null;
                                        let img2Url = null;
                                        let img3Url = null;
              
                                        if(!info){
                                          return;
                                        }
              
                                        if(info.idCardAvatar){
                                          const img = await FileApi.uploadBase64({
                                            file: info.idCardAvatar,
                                            rootFolder: 'id-card-image'
                                          });
                                          img1Url = img.fileUrl;
                                        }
              
                                        if(info.frontOfIdCardImage){
                                          const img = await FileApi.uploadBase64({
                                            file: info.frontOfIdCardImage,
                                            rootFolder: 'id-card-image'
                                          });
                                          img2Url = img.fileUrl;
                                        }
              
                                        if(info.backOfIdCardImage){
                                          const img = await FileApi.uploadBase64({
                                            file: info.backOfIdCardImage,
                                            rootFolder: 'id-card-image'
                                          });
                                          img3Url = img.fileUrl;
                                        }
              
                                        const nationMap = (window.store.getState().global.dictEntryMap||{})['GB_NATION']||{};
                                        let nation = null;
                                        for(let key in nationMap){
                                          if(nationMap[key].text==info.nation){
                                            nation =key;
                                          }
                                        }

                                        let values = {
                                            name: info.name,
                                            sex: info.gender=='男'?"1":"2",
                                            nation: nation,
                                            idCardInfo: {
                                                idCardType: "01",
                                                idCardNo: info.code,
                                                idCardIssuedBy: info.agency,
                                                idCardBeginDate: moment(info.expireStart, 'YYYY-MM-DD'),
                                                idCardEndDate: moment(info.expireEnd, 'YYYY-MM-DD').isValid()?moment(info.expireEnd, 'YYYY-MM-DD'):null,
                                                idCardNoExpirationFlag: moment(info.expireEnd, 'YYYY-MM-DD').isValid()?false:true,
                                                idCardAddress: info.address,
                                                idCardAvatarImageUrl: img1Url,
                                                idCardFrontImageUrl: img2Url,
                                                idCardBackImageUrl: img3Url,
                                            },
                                        }
                                        setEmployee({
                                            ...employee,
                                            ...values,
                                        });
                                        setFormValues({
                                            ...employee,
                                            ...values,
                                        });
                                        basicForm.current?.setFieldsValue(values);
              
                                      }, (result:any)=>{
                                        message.error(result.message)
                                      });
                                }}>
                                    读取身份证信息
                                </Button>,
                            ]
                        }

                        if(props.step===4){
                            return [
                                <Button key="pre" onClick={() => props.onPre?.()}>
                                    上一步
                                </Button>,
                                <Button key="next" type="primary" onClick={() => props.onSubmit?.()}>
                                    提交 {'√'}
                                </Button>,
                            ]
                        }

                        return [
                            <Button key="pre" onClick={() => props.onPre?.()}>
                                上一步
                            </Button>,
                            <Button key="next" type="primary" onClick={() => props.onSubmit?.()}>
                                下一步 {'>'}
                            </Button>,
                        ];
                    }
                }}
            >
                <StepsForm.StepForm<{
                        name: string;
                    }>
                    formRef={mobileForm}
                    name="mobile"
                    title="手机号"
                    layout='horizontal'
                    stepProps={{
                        description: '请输入手机号',
                    }}
                    onFinish={async (values:any) => {
                        try {
                            let list:any = await EmployeeApi.findEmployeeList({
                                mobile: values.contactInfo.mobile,
                                tenantId: staffPosition.tenantId,
                            });
    
                            let employee = list[0];
    
                            if(employee) { //该项目已存在该手机号逻辑
                                if(!employee.deletedFlag) {
                                    Modal.error({
                                        title: '错误提示',
                                        content: "已存在工人" + employee.name + "的手机号为" + values.contactInfo.mobile,
                                    });
                                
                                    return false;
                                } else {
                                    Modal.confirm({
                                        title: '提醒',
                                        content: "存在已删除工人" + employee.name + "的手机号为" + values.contactInfo.mobile + ", 确认恢复该工人吗？",
                                        onOk:async ()=>{
                                            await EmployeeApi.patchEditEmployee({
                                                id: employee.id,
                                                deletedFlag: false,
                                            });
    
                                            Modal.success({
                                                title: "信息提示",
                                                content: "工人已经恢复，请对该工人重做入职操作。"
                                            });
                                        }
                                    });

                                    return false;
                                }
                            }

                            const person = await PersonApi.findPersonByMobile(values.contactInfo.mobile);

                            if(person){
                                employee = {
                                    name: person.name,
                                    sex: person.sex,
                                    nation: person.nation,
                                    idCardInfo: {
                                        idCardType: "01",
                                        idCardNo: person.idCardNo,
                                        idCardIssuedBy: person.idCardIssuedBy,
                                        idCardBeginDate: person.idCardBeginDate,
                                        idCardEndDate: person.idCardEndDate,
                                        idCardNoExpirationFlag: person.idCardNoExpirationFlag,
                                        idCardAddress: person.idCardAddress,
                                        idCardAvatarImageUrl: person.idCardAvatarImageUrl,
                                        idCardFrontImageUrl: person.idCardFrontImageUrl,
                                        idCardBackImageUrl: person.idCardBackImageUrl,
                                    },
                                    contactInfo: {
                                        mobile: person.mobile,
                                    }
                                }
                            }

                            if(employee){
                                setEmployee(employee);
                            }else{
                                setEmployee(values);
                            }

                            basicForm.current?.setFieldsValue(employee?employee:values);
                            contactForm.current?.setFieldsValue(employee?employee:values);
                            
                            return true;
                        }catch(ex){
                            return false;
                        }
                    }}
                >
                    <ProFormText
                            name={['contactInfo','mobile']}
                            label="手机全号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "手机全号不能为空"}, {len:11, message:'手机号只能是11位'}]}
                            {...FormLayout.itemLayout.span12}
                     />
                </StepsForm.StepForm>

                <StepsForm.StepForm<{
                        name: string;
                    }>
                    formRef={basicForm}
                    name="basic"
                    title="基本信息"
                    layout='horizontal'
                    stepProps={{
                        description: '采集基本信息',
                    }}
                    initialValues={{
                        maritalStatus: '20',
                        politicalStatus: '13',
                        firstDegree: '02',

                    }}
                    onFinish={async (values:any) => {
                        if(values.idCardInfo.idCardAvatarImageUrl && values.idCardInfo.idCardAvatarImageUrl[0]){
                            values.idCardInfo.idCardAvatarImageUrl = values?.idCardInfo?.idCardAvatarImageUrl[0]?.response.fileUrl;
                        }
                        if(values.idCardInfo.idCardFrontImageUrl && values.idCardInfo.idCardFrontImageUrl[0]){
                            values.idCardInfo.idCardFrontImageUrl = values.idCardInfo.idCardFrontImageUrl[0].response.fileUrl;
                        }
                        if(values.idCardInfo.idCardBackImageUrl && values.idCardInfo.idCardBackImageUrl[0]){
                            values.idCardInfo.idCardBackImageUrl = values.idCardInfo.idCardBackImageUrl[0].response.fileUrl;
                        }
                        
                        const newValues = {
                            ...formValues,
                            ...values,
                        };

                        setFormValues(newValues);

                        return true;
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormText
                                name={['name']}
                                label="姓名"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "姓名不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='GB_SEX'
                                name={['sex']}
                                label="性别"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "性别不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='GB_NATION'
                                name={['nation']}
                                label="民族"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "民族不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='HR_CER_TYPE'
                                name={['idCardInfo','idCardType']}
                                label="证件类型"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "证件类型不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['idCardInfo','idCardNo']}
                                label="证件号码"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "证件号码不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['idCardInfo','idCardIssuedBy']}
                                label="发证机关"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "发证机关不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDatePicker
                                name={['idCardInfo','idCardBeginDate']}
                                label="有效期开始日期"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "有效期开始日期不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={8}>
                            <ProFormDatePicker
                                name={['idCardInfo','idCardEndDate']}
                                label="有效期结束日期"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:false, message: "有效期结束日期不能为空"}]}
                                {...FormLayout.itemLayout.span8}
                            />
                        </Col>
                        <Col span={4}>
                            <ProFormSwitch
                                name={['idCardInfo','idCardNoExpirationFlag']}
                                label="长期"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:false, message: "证件长期有效"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={24}>
                            <ProFormText
                                name={['idCardInfo','idCardAddress']}
                                label="证件地址"
                                tooltip=""
                                placeholder=""
                                readonly={true}
                                rules={[{required:true, message: "证件地址不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                        <Col span={24}>
                            <Row style={{marginBottom:16}}>
                                <Col span={8}>
                                    <Row>
                                        <Col span={FormLayout.itemLayout.span8.labelCol.span} style={{textAlign:'right'}}>
                                            身份证头像:&nbsp;&nbsp;
                                        </Col>
                                        <Col span={FormLayout.itemLayout.span8.wrapperCol.span} >
                                            <Image 
                                                width={80}
                                                height={100}
                                                src={GlobalConst.FILE_SERVER + employee?.idCardInfo?.idCardAvatarImageUrl}
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={FormLayout.itemLayout.span8.labelCol.span} style={{textAlign:'right'}}>
                                            证件正面照:&nbsp;&nbsp;
                                        </Col>
                                        <Col span={FormLayout.itemLayout.span8.wrapperCol.span} >
                                            <Image 
                                                width={160}
                                                height={100}
                                                src={GlobalConst.FILE_SERVER + employee?.idCardInfo?.idCardFrontImageUrl}
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={8}>
                                    <Row>
                                        <Col span={FormLayout.itemLayout.span8.labelCol.span} style={{textAlign:'right'}}>
                                            证件反面照:&nbsp;&nbsp;
                                        </Col>
                                        <Col span={FormLayout.itemLayout.span8.wrapperCol.span} >
                                            <Image 
                                                width={160}
                                                height={100}
                                                src={GlobalConst.FILE_SERVER + employee?.idCardInfo?.idCardBackImageUrl}
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='GB_MARITAL_STATUS'
                                name={['maritalStatus']}
                                label="婚姻状态"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "婚姻状态不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='GB_POLITICAL_STATUS'
                                name={['politicalStatus']}
                                label="政治面貌"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "政治面貌不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <DictItemSelector
                                entryCode='HR_EDU_LEVEL'
                                name={['firstDegree']}
                                label="学历"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "第一学历不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDatePicker
                                name={['birthday']}
                                label="生日"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "生日不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                    </Row>
                </StepsForm.StepForm>

                <StepsForm.StepForm<{
                        name: string;
                    }>
                    formRef={contactForm}
                    name="contactInfo"
                    title="联系信息"
                    layout='horizontal'
                    stepProps={{
                        description: '采集联系信息',
                    }}
                    onValuesChange={(values)=>{
                        console.log(values);
                    }}
                    onFinish={async (values) => {

                        setFormValues({
                            ...formValues,
                            ...values,
                        });

                        return true;
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','mobile']}
                                label="手机全号"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "手机全号不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','cornet']}
                                label="手机短号"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "手机短号不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','telephone']}
                                label="固定电话"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "固定电话不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','email']}
                                label="电子邮箱"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "电子邮箱不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','wechat']}
                                label="微信"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "微信不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['contactInfo','qq']}
                                label="QQ"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "QQ不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={24}>
                            <ProFormText
                                name={['contactInfo','liveAddress']}
                                label="居住地址"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "居住地址不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                    </Row>
                </StepsForm.StepForm>


                <StepsForm.StepForm<{
                        name: string;
                    }>
                    formRef={workInfoForm}
                    name="workInfo"
                    title="入职信息"
                    layout='horizontal'
                    stepProps={{
                        description: '采集入职信息',
                    }}
                    initialValues={{
                        syncToGov:true,
                        workInfo: {
                            workRole: '10',
                            teamLeaderFlag: false,
                            insuranceFlag: true,
                            majorDiseaseHistoryFlag:false,
                        }
                    }}
                    onValuesChange={async (values)=>{
                        if(values?.orgId){
                            const contractor = await ContractorApi.findContractorById(values.orgId);

                            setContractor(contractor);
                        }

                        if(values?.workInfo?.workRole){
                            setWorkRole(values.workInfo.workRole);
                        }

                        if(values?.orgId || values?.workInfo?.workRole){
                            workInfoForm.current?.setFieldsValue({
                                workInfo: {
                                    workTeamId:'',
                                }
                            });
                        }
                    }}
                    onFinish={async (values) => {

                        const newFormValues:any = {
                            ...formValues,
                            ...values,
                        }

                        if(newFormValues.workInfo.workRole=='10'){
                            newFormValues.workInfo.workType='900';
                        }else{
                            newFormValues.workInfo.manageType=null;
                        }

                        try{
                            const employee = await EmployeeApi.addEmployee(newFormValues);

                            setEmployee(employee);

                            message.success("人员档案新建成功，请继续办理考勤资料信息。");

                            return true;
                        }catch(ex){
                            return false;
                        }
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormSwitch
                                name={['syncToGov']}
                                label="上报监管机构"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "上报监管机构不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormRadio.Group
                                name={['workInfo','workRole']}
                                label="工人角色"
                                tooltip="管理人员只能是合同上指定的持证管理人员或监理单位的管理人员，其他人都为建筑工人"
                                placeholder=""
                                rules={[{required:true, message: "工人角色不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                                options={[{
                                    label: '管理人员',
                                    value: '10'
                                }, {
                                    label: '建筑工人',
                                    value: '20',
                                }]}
                            />
                        </Col>
                        <Col span={12}>
                            <OrganizationSelector
                                name={['orgId']}
                                label="参建单位"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "参建单位不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <WorkTeamSelector
                                params={{orgId: contractor?.id, managerTeamFlag: workRole==='10'}}
                                name={['workInfo','workTeamId']}
                                label="所属班组"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "班组不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        {
                            workRole==='20' &&
                            <Col span={12}>
                                <DictItemSelector
                                    entryCode='HR_WORK_TYPE'
                                    excludeValues={['900']}
                                    name={['workInfo','workType']}
                                    label="工种类型"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "工种类型不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>
                        }
                        {
                            workRole==='10' &&
                            <Col span={12}>
                                <DictItemSelector
                                    entryCode='JZ_MANAGER_POST_TYPE'
                                    groupKey={contractor.type}
                                    name={['workInfo','manageType']}
                                    label="管理类型"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "管理类型不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>
                        }
                        <Col span={12}>
                            <ProFormDatePicker
                                name={['workInfo','firstEntryTime']}
                                label="入职时间"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "入职时间不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormSwitch
                                name={['workInfo','teamLeaderFlag']}
                                label="班组长"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "班组长不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormSwitch
                                name={['workInfo','insuranceFlag']}
                                label="保险购买"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "保险购买不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormSwitch
                                name={['workInfo','majorDiseaseHistoryFlag']}
                                label="重疾病史"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "重疾病史不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                    </Row>
                </StepsForm.StepForm>

                <StepsForm.StepForm<{
                        name: string;
                    }>
                    name="attendanceInfo"
                    title="考勤信息"
                    layout='horizontal'
                    stepProps={{
                        description: '采集考勤信息',
                    }}
                    onFinish={async (values) => {

                        await EmployeeAttendanceInfoApi.patchEditEmployeeAttendanceInfo({
                            ...attendanceInfoFormValues,
                            id: employee.id,
                        });

                        onSuccess();

                        return true;
                    }}
                    onValuesChange={async (values)=>{
                        if(values.faceImageUrl && values.faceImageUrl[0]){
                            values.faceImageUrl = values.faceImageUrl[0].response.fileUrl;
                        }

                        setAttendanceInfoFormValues({
                            ...attendanceInfoFormValues,
                            ...values,
                        });
                    }}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormText
                                name={['icCardNo']}
                                label="IC卡号"
                                tooltip="工地不支持IC卡刷卡，则留空"
                                placeholder=""
                                rules={[{required:false, message: "工人IC卡卡号不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>

                        </Col>
                        <Col span={8}>
                            <SingleImageSelector
                                name={['faceImageUrl']}
                                label="考勤图片"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "考勤图片不能为空"}]}
                                data= {{rootFolder: 'check-image',}}
                                {...FormLayout.itemLayout.span8}
                                onChange={(info:any)=>{
                                    setAttendanceInfoFormValues({
                                        ...attendanceInfoFormValues,
                                        faceImageUrl: info.fileUrl,
                                    });
                                }}
                            />
                        </Col>
                        <Col span={16}>
                            <Image
                                src={I}
                                width={460}
                                height={100}
                            />
                            <Alert 
                                style={{marginTop:8}}
                                type='error' 
                                message="照片保证正面，且只支持JPEG/JPG/PNG" 
                            />
                        </Col>
                    </Row>
                </StepsForm.StepForm>
            </StepsForm>
        </Drawer>
    );
};

export default EmployeeAddForm;