
import {DustMachine, DustMachineQto} from './../type/DustMachineType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addDustMachine = async (params:DustMachine)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/dust-machine`, params);
};


const editDustMachine = async (params:DustMachine)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/dust-machine/${params.id}`, params);
};


const patchEditDustMachine = async (params:DustMachine)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/dust-machine/${params.id}`, params);
};


const deleteDustMachine = async (params:DustMachine)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/dust-machine/${params.id}`, params);
};


const findDustMachineById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-machine/${params}`, params);
};

const findDustMachineList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-machine/list`, params);
};

const findDustMachinePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-machine/page`, params);
};



export default {
    addDustMachine,
    editDustMachine,
    patchEditDustMachine,
    deleteDustMachine,
    findDustMachineById,
    findDustMachineList,
    findDustMachinePage,
};


