import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormProps, ProFormTextArea} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from '../../../../utils/ui/layout/FormLayout';

import { P330800DirectPersonInfo } from '../../type/P330800DirectPersonInfoType';
import P330800DirectPersonInfoApi from '../../api/P330800DirectPersonInfoApi';
import { message } from 'antd';

export interface P330800DirectPersonInfoAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const P330800DirectPersonInfoAddForm:React.FC<P330800DirectPersonInfoAddFormProps> = (props)=>{

    const [form] = Form.useForm<P330800DirectPersonInfo>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="读取浙里建人员"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  any)=>{
                    values.jsonData = JSON.parse(values.jsonData);
                    await P330800DirectPersonInfoApi.readFromZheliBuildJsonData(values);
                    form.resetFields();
                    message.success("读取人员成功!");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={24}>
                        <ProFormTextArea
                            name={['jsonData']}
                            label="人员JSON"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "人员JSON为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default P330800DirectPersonInfoAddForm;