import _ from 'lodash';

import { DrawerProps, Modal } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { Elevator } from './../../type/ElevatorType';
import ElevatorApi from '../../api/ElevatorApi';
import { message } from 'antd';
import ElevatorMachineMetaInfoApi from '../../../iot/api/ElevatorMachineMetaInfoApi';

export interface ElevatorAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const ElevatorAddForm:React.FC<ElevatorAddFormProps> = (props)=>{

    const [form] = Form.useForm<Elevator>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建扬尘设备"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Elevator)=>{

                    const elevatorMachineList = await ElevatorMachineMetaInfoApi.findElevatorMachineMetaInfoList({
                        sn: values?.sn||"undefined"
                    });

                    if(!elevatorMachineList || elevatorMachineList.length<1){
                        
                        Modal.error({
                            title: '错误提示',
                            content:"设备[" + values.sn + "]未注册，请联系平台先注册该设备！"
                        });
                        return false;
                    }
                    values.id=elevatorMachineList[0].id;

                    await ElevatorApi.addElevator(values);
                    form.resetFields();
                    message.success("新建扬尘设备成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['sn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="设备名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['plateNo']}
                            label="车牌号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "车牌号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['remark']}
                            label="备注信息"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "备注信息不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default ElevatorAddForm;