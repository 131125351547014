import _ from 'lodash';

import { Card, DrawerProps, Image, Tabs, Tag } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import { Employee } from '../../type/EmployeeType';
import EmployeeCheckImageDataApi from '../../api/EmployeeCheckImageData';

import './index.less';
import GlobalConst from '../../../../utils/GlobalConst';
import SingleImageSelector from '../../../file/component/SingleImageSelector';
import { ActionType } from '@ant-design/pro-table';
import { EmployeeCheckImageData } from '../../type/EmployeeCheckImageData';

export interface EmployeeCheckImageDataProps extends DrawerProps {
    employee?: Employee,
    onFail:()=>void,
    onSuccess:()=>void,
};

const EmployeeCheckImageDataDetail:React.FC<EmployeeCheckImageDataProps> = (props)=>{

    const {employee, onFail, onSuccess} = props;
    const [title, setTitle] = useState<string|undefined>(employee?.name + "手机考勤照片库维护");
    const attendanceInfoRef = useRef<ActionType>();

    return (
        <Drawer
            title={
                <div>
                    {title} 
                </div>
            }
            className="EmployeeCheckImageData"
            width={960}
            visible={true}
            closable={false}
            destroyOnClose={true}
            bodyStyle={{paddingTop:0}}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<EmployeeCheckImageData>
                column={3}
                bordered
                request={async ()=>{
                    const result:EmployeeCheckImageData = await EmployeeCheckImageDataApi.findEmployeeCheckImageDataById(employee?.id||"");
                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            requestData[field] = newData[field].fileUrl;
                        }

                        await EmployeeCheckImageDataApi.patchEditEmployeeCheckImageData(requestData);
                    }
                }}
            >
                <ProDescriptions.Item>
                    第1天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl1']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl1}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl2']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl1}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                <ProDescriptions.Item>
                    第2天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl3']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl3}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl4']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl4}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


            <ProDescriptions.Item>
                    第3天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl5']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl5}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl6']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl6}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                <ProDescriptions.Item>
                    第4天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl7']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl7}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl8']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl8}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                <ProDescriptions.Item>
                    第5天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl9']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl9}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl10']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl10}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                <ProDescriptions.Item>
                    第6天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl11']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl11}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl12']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl12}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                <ProDescriptions.Item>
                    第7天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl13']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl13}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl14']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl14}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                <ProDescriptions.Item>
                    第8天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl15']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl15}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl16']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl16}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                <ProDescriptions.Item>
                    第9天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl17']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl17}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl18']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl18}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />




                <ProDescriptions.Item>
                    第10天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl19']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl19}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl20']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl20}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                

                <ProDescriptions.Item>
                    第11天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl21']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl21}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl22']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl22}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                

                <ProDescriptions.Item>
                    第12天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl23']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl23}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl24']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl24}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />



                

                <ProDescriptions.Item>
                    第13天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl25']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl25}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl26']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl26}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                <ProDescriptions.Item>
                    第14天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl27']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl27}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl28']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl28}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />


                <ProDescriptions.Item>
                    第15天
                </ProDescriptions.Item>
                <ProDescriptions.Item
                    dataIndex={['imgUrl29']}
                    label="上班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        console.log( entity);
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl29}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['imgUrl30']}
                    label="下班照片"
                    valueType="text"
                    copyable={true}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                <Image 
                                    width={80}
                                    height={108}
                                    src={GlobalConst.FILE_SERVER+ entity?.imgUrl30}
                                    fallback={GlobalConst.EMPTY_IMAGE}
                                />
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <SingleImageSelector 
                            data={{
                                rootFolder: 'check-image'
                            }}
                        />;
                    }}
                />





            </ProDescriptions>
        </Drawer>
    );
};

export default EmployeeCheckImageDataDetail;