
import {AttendanceLog, AttendanceLogQto} from './../type/AttendanceLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addAttendanceLog = async (params:AttendanceLog)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/attendance-log`, params);
};


const editAttendanceLog = async (params:AttendanceLog)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/attendance-log/${params.id}`, params);
};


const patchEditAttendanceLog = async (params:AttendanceLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/attendance-log/${params.id}`, params);
};


const deleteAttendanceLog = async (params:AttendanceLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/attendance-log/${params.id}`, params);
};


const findAttendanceLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-log/${params}`, params);
};

const findAttendanceLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-log/list`, params);
};

const findAttendanceLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-log/page`, params);
};



export default {
    addAttendanceLog,
    editAttendanceLog,
    patchEditAttendanceLog,
    deleteAttendanceLog,
    findAttendanceLogById,
    findAttendanceLogList,
    findAttendanceLogPage,
};


