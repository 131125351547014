
import {Post, PostQto} from './../type/PostType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addPost = async (params:Post)=>{

    return HttpInvoker.postObject(`/api/tenant/system/post`, params);
};


const editPost = async (params:Post)=>{

    return HttpInvoker.putObject(`/api/tenant/system/post/${params.id}`, params);
};


const patchEditPost = async (params:Post)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/post/${params.id}`, params);
};


const deletePost = async (params:Post)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/post/${params.id}`, params);
};


const findPostById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/post/${params}`, params);
};

const findPostList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/post/list`, params);
};

const findPostPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/post/page`, params);
};

const findPostTree = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/post/tree`, params);
};


export default {
    addPost,
    editPost,
    patchEditPost,
    deletePost,
    findPostById,
    findPostList,
    findPostPage,
    findPostTree,
};


