import React, {FC, useState, useRef} from "react";

import {Table, Space, Modal, Dropdown, Menu, Button, message} from 'antd';
import { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {DownOutlined} from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { Staff } from './../../type/StaffType';
import StaffApi from "../../api/StaffApi";
import StaffAddForm from "./StaffAddForm";
import StaffDetail from "./StaffDetail";

const StaffPage:React.FC = ()=>{

    const tableActionRef = useRef<ActionType>();
    const detailActionRef = useRef<ProDescriptionsActionType>();

    const [addFormVisible, setAddFormVisible] = useState<boolean>(false);
    const [detailVisible, setDetailVisible] = useState<boolean>(false);
    const [currentStaff, setCurrentStaff] = useState<Staff>({});

    const refreshData = ()=>{
        tableActionRef.current?.reload();
    }

    const deleteStaff = (staff:Staff)=>{
        Modal.confirm({
            title: "删除人员确认",
            content: "确定删除该人员吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await StaffApi.deleteStaff(staff);
                tableActionRef.current?.reload();
            }
        });
    }

    const showAddForm = ()=>{
        setAddFormVisible(true);
    }

    const hideAddForm = ()=>{
        setAddFormVisible(false);
    }

    const showDetail = (staff:Staff)=>{
        setCurrentStaff(staff);
        setDetailVisible(true);
    }

    const hideDetail = ()=>{
        setCurrentStaff({});
        setDetailVisible(false);
    }

    const rowExtraButtons : React.FC<{
        item: Staff
    }> = ({item}) => {
        return (
            <Dropdown
                key="row-extra-btns"
                overlay={
                    <Menu
                        onClick={({key})=>{
                            if(key==='delete') {
                                deleteStaff(item);
                            }
                        }}
                    >
                        <Menu.Item key="delete">
                            删除
                        </Menu.Item>
                    </Menu>
                }
            >
                <a>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const columns:ProColumns<Staff>[] = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 60,
            hideInSearch: true,
            hideInSetting: true,
            hideInForm: true,
            copyable: false,
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "jobNo",
            width: 120,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 120,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "sex",
            title: "性别",
            dataIndex: "sex",
            width: 120,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "mobile",
            title: "手机号",
            dataIndex: "mobile",
            width: 160,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "email",
            title: "电子邮箱",
            dataIndex: "email",
            width: 180,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "status",
            title: "员工状态",
            dataIndex: "status",
            width: 180,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            title: '操作',
            valueType: 'option',
            dataIndex: 'actions',
            render: (text, record:Staff, _, action) =>{
                return [
                    <a key='view' onClick={()=>{
                        setCurrentStaff(record);
                        setDetailVisible(true);
                        }}>
                        查看
                    </a>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    return (
        <div>
            <ProTable<Staff, Pagination>
                headerTitle="人员列表"
                rowKey="id"
                columns={columns}
                actionRef={tableActionRef}
                scroll={{x: TableUtils.getTableScrollX(columns)}}
                rowSelection={{
                    alwaysShowAlert:true,
                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                    defaultSelectedRowKeys: [],
                }}
                tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => (
                    <Space size={24}>
                          <span>
                            已选 {selectedRowKeys.length} 项
                            <a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                              取消选择
                            </a>
                          </span>
                    </Space>
                )}
                tableAlertOptionRender={({selectedRowKeys}) => {
                    return (
                        <Space size={16}>
                            <a onClick={async ()=>{
                                Modal.confirm({
                                    title: '删除人员',
                                    content: '确认删除所选人员吗？',
                                    okText: '删除所选',
                                    cancelText: '取消',
                                    onOk:async ()=>{
                                        const hide = message.loading("数据删除中....");
                                        for(let i=0; i<selectedRowKeys.length; i++){
                                            let id:string = selectedRowKeys[i]+"";
                                            try{
                                                await StaffApi.deleteStaff({id:id});
                                            }catch(ex){

                                            }
                                        }
                                        hide();
                                        tableActionRef.current?.reload();
                                        message.success("数据删除完成！");
                                    }
                                });
                            }}>
                                批量删除
                            </a>
                        </Space>
                    );
                }}
                toolBarRender={()=>{
                    return [
                        <Button
                            type="primary"
                            onClick={showAddForm}
                        >
                            新建
                        </Button>
                    ];
                }}
                request={async (params={}, sort, filter)=>{

                    params.page = params.current;
                    const pageInfo = await StaffApi.findStaffPage({
                        deletedFlag: false,
                        ...params,
                    });

                    pageInfo.list.forEach((item:any, idx:number)=>{
                        item.rowNo = ((params.current||1)-1)*(params.pageSize||20)+idx+1;
                    });

                    return {
                        success: true,
                        data: pageInfo.list,
                    };
                }}
            />

            <StaffAddForm
                visible={addFormVisible}
                onFail={hideAddForm}
                onSuccess={refreshData}
            />

            <StaffDetail
                visible={detailVisible}
                staff={currentStaff}
                actionRef={detailActionRef}
                onFail={hideDetail}
                onSuccess={refreshData}
            />
        </div>
    );
};

export default StaffPage;