
import {TowerCraneLog, TowerCraneLogQto} from './../type/TowerCraneLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addTowerCraneLog = async (params:TowerCraneLog)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/tower-crane-log`, params);
};


const editTowerCraneLog = async (params:TowerCraneLog)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/tower-crane-log/${params.id}`, params);
};


const patchEditTowerCraneLog = async (params:TowerCraneLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/tower-crane-log/${params.id}`, params);
};


const deleteTowerCraneLog = async (params:TowerCraneLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/tower-crane-log/${params.id}`, params);
};


const findTowerCraneLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane-log/${params}`, params);
};

const findTowerCraneLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane-log/list`, params);
};

const findTowerCraneLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane-log/page`, params);
};



export default {
    addTowerCraneLog,
    editTowerCraneLog,
    patchEditTowerCraneLog,
    deleteTowerCraneLog,
    findTowerCraneLogById,
    findTowerCraneLogList,
    findTowerCraneLogPage,
};


