import React, {FC, useState, useRef, useEffect} from "react";
import { stringify } from 'qs';

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert, List, Card, Image, AutoComplete} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { Employee, EmployeeQto } from './../../type/EmployeeType';
import EmployeeApi from "../../api/EmployeeApi";
import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormInstance, ProFormText } from "@ant-design/pro-form";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import moment from "moment";
import { left } from "@antv/g2plot/lib/plots/sankey/sankey";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";
import EmployeeAttendanceReportApi from "../../api/EmployeeAttendanceReportApi";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import AttendanceLogListModal from "../AttendanceLog/AttendanceLogListModal";
import GlobalConst from "../../../../utils/GlobalConst";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const EmployeePage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<ProFormInstance<EmployeeQto>>(null);

    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [currentEmployee, setCurrentEmployee] = useState<Employee>({}); //当前记录
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态
    const [selectedRows, setSelectedRows] = useState<Employee[]>([]);

    const [detailQueryParams, setDetailQueryParams] = useState({});
    const [detaiAttendanceLogModalVisble, setDetailAttendanceLogModalVisble] = useState<boolean>(false);

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current.getFieldsFormatValue()||{}),
            ...(params||{}),
            withAttendanceLogsFlag: true,
            reportDate:moment().format("YYYY-MM-DD") + " 00:00:00",
            deletedFlag: false,
            
        };
        setQueryParams(newQueryParams);

        EmployeeAttendanceReportApi.attendanceDayReport(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    //表格大小变化事件
    useEffect(()=>{

        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                console.log(tableHeight);
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='orgId' style={{display:'inline-block', height:40, width:260}}>
            <OrganizationSelector
                label="参建单位"
                name="orgId"
            />
        </div>,
        <div key='workTeamId' style={{display:'inline-block', height:40, width:260}}>
            <WorkTeamSelector
                label="所属班组"
                name="workTeamId"
            />
        </div>,
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <ProForm
                    formRef={queryFormRef}
                    submitter={false}
                    layout={"horizontal"}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </ProForm>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    今日出勤人员
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        <Button type="link">
                            今日出勤 <strong>{pageData?.total}</strong> 人
                        </Button>
                        <Button type="primary"
                            onClick={()=>{
                                const newQueryParams = {
                                    ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current?.getFieldsFormatValue()||{}),
                                    withAttendanceLogsFlag: true,
                                    reportDate:moment().format("YYYY-MM-DD") + " 00:00:00",
                                    deletedFlag: false,
                                    Authorization: localStorage.getItem('Authorization')||"",
                                };

                                window.open(`/api/tenant/hr/employee-attendance-report/employee-attendance-day-report/excel?${stringify(newQueryParams)}`);
                            }}
                        >
                            导出Excel
                        </Button>
                        <Button type="primary" danger>
                            今日缺勤人员
                        </Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute', backgroundColor:'#ffffff', padding:"0px 16px 16px 16px"}}
                >
                    <Spin spinning={spinning}>
                        <List 
                            bordered
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            grid={{
                                gutter:16,
                                xs: 1,
                                sm: 2,
                                md: 3,
                                lg: 4,
                                xl: 4,
                                xxl: 6,
                            }}
                            style={{padding:16}}
                            renderItem={(item)=>{
                                return (
                                    <List.Item style={{
                                            width:'100%', 
                                            border:'1px solid rgb(204, 204, 204)', 
                                            borderRadius:4, 
                                            textAlign: 'center',
                                        }}>
                                        <div>
                                            <div style={{
                                                    textAlign: 'center',
                                                    fontSize: '12px',
                                                    lineHeight: '28px',
                                                    paddingTop:16,
                                                    paddingBottom: 16,
                                                }}>
                                                <Image 
                                                    width={74}
                                                    height={98}
                                                    src={GlobalConst.FILE_SERVER + item.avatarUrl}
                                                    style={{position: 'relative', marginLeft:'auto', marginRight:'auto'}}
                                                    fallback={GlobalConst.EMPTY_IMAGE}
                                                />
                                                <div style={{fontWeight:'bold'}}>
                                                    {item.name}({item.jobNo})
                                                </div>
                                                <div>
                                                    所属班组：{item.workTeamName}
                                                </div>
                                                <div>
                                                    工种类型：
                                                    {
                                                        item.workRole=='10' &&
                                                        <DictItemDisplay value={item.manageType} entryCode="JZ_MANAGER_POST_TYPE" />
                                                    }
                                                    {
                                                        item.workRole=='20' &&
                                                        <DictItemDisplay value={item.workType} entryCode="HR_WORK_TYPE" />
                                                    }
                                                </div>
                                                <div>
                                                    最早进门打卡：{item.firstCheckTime?.toString().substring(11,19)}
                                                </div>
                                                <div>
                                                    最晚出门打卡：{item.lastCheckTime?.toString().substring(11,21)}
                                                </div>
                                                <div style={{textAlign: 'right'}}>
                                                    <Button type="link"
                                                        onClick={()=>{
                                                            setDetailQueryParams({
                                                                checkTimeBegin: moment().startOf('day').format("YYYY-MM-DD HH:mm:ss"),
                                                                checkTimeEnd: moment().endOf('day').format("YYYY-MM-DD HH:mm:ss"),
                                                                employeeId: item.employeeId,
                                                            });
                                                            setDetailAttendanceLogModalVisble(true);
                                                        }}
                                                    >查看详情</Button>
                                                </div>
                                            </div>
                                            <div>

                                            </div>
                                        </div>
                                    </List.Item>
                                );
                            }}
                        />
                    </Spin>
                </div>
            </div>
            {
                detaiAttendanceLogModalVisble &&
                <AttendanceLogListModal 
                    params={detailQueryParams}
                    onCancel={()=>{
                        setDetailAttendanceLogModalVisble(false);
                    }}
                />
            }
        </div>
    );
};

export default EmployeePage;