
import {TowerCrane, TowerCraneQto} from './../type/TowerCraneType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addTowerCrane = async (params:TowerCrane)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/tower-crane`, params);
};


const editTowerCrane = async (params:TowerCrane)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/tower-crane/${params.id}`, params);
};


const patchEditTowerCrane = async (params:TowerCrane)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/tower-crane/${params.id}`, params);
};


const deleteTowerCrane = async (params:TowerCrane)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/tower-crane/${params.id}`, params);
};


const findTowerCraneById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane/${params}`, params);
};

const findTowerCraneList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane/list`, params);
};

const findTowerCranePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/tower-crane/page`, params);
};



export default {
    addTowerCrane,
    editTowerCrane,
    patchEditTowerCrane,
    deleteTowerCrane,
    findTowerCraneById,
    findTowerCraneList,
    findTowerCranePage,
};


