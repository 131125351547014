import { Modal, Spin, Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { useEffect, useState } from "react";
import { Pagination } from "../../../../utils/data/Type";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import AttendanceLogApi from "../../api/AttendanceLogApi";
import { AttendanceLog } from "../../type/AttendanceLogType";

interface AttendanceLogListModalProps {
    params: any,
    onCancel: () => void,
}

const AttendanceLogListModal: React.FC<AttendanceLogListModalProps> = (props) => {

    const { params, onCancel } = props;

    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态

    const defaultColumns: ColumnsType<AttendanceLog> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "jobNo",
            width: 80,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 100,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "workTeamName",
            title: "班组名称",
            dataIndex: "workTeamName",
            width: 120,
            ellipsis: true,
        },
        {
            key: "workType",
            title: "工种类型",
            dataIndex: "workType",
            width: 120,
            ellipsis: true,
            render: (val, record) => {

                if (record.workRole === '10') {
                    return (
                        <DictItemDisplay
                            entryCode="JZ_MANAGER_POST_TYPE"
                            value={record.manageType}
                        />
                    );
                } else {
                    return (
                        <DictItemDisplay
                            entryCode="HR_WORK_TYPE"
                            value={val}
                        />
                    );
                }
            },
        },
        {
            key: "checkTime",
            title: "考勤时间",
            dataIndex: "checkTime",
            width: 160,
            ellipsis: true,
            render:(val)=>{
                return val?.substring(0, 19);
            }
        },
    ];

    const reloadData = (queryParams: object | undefined) => {
        console.log(params);
        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params || {}),
        };
        setQueryParams(newQueryParams);

        AttendanceLogApi.findAttendanceLogPage(newQueryParams).then(pageInfo => {
            pageInfo.list.forEach((i: any, idx: number) => {
                i.rowNo = idx + 1 + (pageInfo.pageNum - 1) * pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(() => {
            setSpinning(false);
        });
    }

    useEffect(()=>{
        reloadData({});
    }, [params]);

    return (
        <Modal
            title={"考勤记录列表"}
            width={960}
            onCancel={onCancel}
            footer={null}
            visible={true}
        >
            <Spin spinning={spinning}>
                <Table
                    dataSource={pageData?.list}
                    pagination={{
                        current: queryParams?.page || 1,
                        pageSize: queryParams?.pageSize || 10,
                        total: pageData?.total,
                        pageSizeOptions: [10, 20, 50, 100],
                        showQuickJumper: true,
                        showSizeChanger: true,
                        onChange: (page, pageSize) => {
                            reloadData({
                                page: page,
                                pageSize: pageSize,
                            });
                        },
                        onShowSizeChange: (page, pageSize) => {
                            reloadData({
                                page: page,
                                pageSize: pageSize,
                            });

                        }
                    }}
                    rowKey="id"
                    columns={defaultColumns}
                />
            </Spin>
        </Modal>
    );
}

export default AttendanceLogListModal;