
import React, {FC, useState, useRef} from "react";
import { ProFormText, ProFormUploadButton } from "@ant-design/pro-form";
import { Button, Col, Input, InputProps, Row, Upload, UploadProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import ImgCrop from "antd-img-crop";



const SingleImageSelector:React.FC<any> = (props)=>{

    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState<any>({});

    console.log(props);

    return (
        <ImgCrop rotate>
            <Upload
                action={"/api/tenant/file/file/upload"}
                name= "file"
                listType='picture-card'
                headers= {{
                    'Authorization': localStorage.getItem('Authorization')||"",
                }}
                maxCount={1}
                {...props}
                onChange={(info:any)=>{
                    if (info.file.status === 'uploading') {
                        setLoading(true);
                    }else{
                        setLoading(false);
                    }
                    
                    if (info.file.status === 'done') {
                        setFileInfo(info.file.response.data);

                        if(props.onChange){
                            props.onChange(info.file.response.data);
                        }
                    }
                }}
            >
                <Button>上传</Button>
            </Upload>
        </ImgCrop>
    );
};

export default SingleImageSelector;