import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { EmployeeBankCard } from './../../type/EmployeeBankCardType';
import EmployeeBankCardApi from '../../api/EmployeeBankCardApi';
import { message } from 'antd';

export interface EmployeeBankCardAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const EmployeeBankCardAddForm:React.FC<EmployeeBankCardAddFormProps> = (props)=>{

    const [form] = Form.useForm<EmployeeBankCard>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建银行卡信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  EmployeeBankCard)=>{
                    await EmployeeBankCardApi.addEmployeeBankCard(values);
                    form.resetFields();
                    message.success("新建银行卡信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['bankCode']}
                            label="银行代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "银行代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['bankName']}
                            label="银行名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "银行名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['bankAccount']}
                            label="银行账号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "银行账号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['bankCardFrontImageUrl']}
                            label="银行卡正面图片"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "银行卡正面图片不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['bankCardBackImageUrl']}
                            label="银行卡背面图片"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "银行卡背面图片不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default EmployeeBankCardAddForm;