
import {Employee, EmployeeQto} from './../type/EmployeeType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addEmployee = async (params:Employee)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/employee`, params);
};


const editEmployee = async (params:Employee)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/employee/${params.id}`, params);
};


const patchEditEmployee = async (params:Employee)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/employee/${params.id}`, params);
};


const deleteEmployee = async (params:Employee)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/employee/${params.id}`, {});
};


const findEmployeeById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee/${params}`, params);
};

const findEmployeeList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee/list`, params);
};

const findEmployeePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee/page`, params);
};



export default {
    addEmployee,
    editEmployee,
    patchEditEmployee,
    deleteEmployee,
    findEmployeeById,
    findEmployeeList,
    findEmployeePage,
};


