import React, {FC, useState, useRef, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { TowerCrane } from './../../type/TowerCraneType';
import TowerCraneApi from "../../api/TowerCraneApi";
import TowerCraneAddForm from "./TowerCraneAddForm";
import TowerCraneDetail from "./TowerCraneDetail";
import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormText } from "@ant-design/pro-form";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const TowerCranePage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);

    const [addFormVisible, setAddFormVisible] = useState<boolean>(false); //新建表单可见状态
    const [detailVisible, setDetailVisible] = useState<boolean>(false); //明细可见状态
    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [currentTowerCrane, setCurrentTowerCrane] = useState<TowerCrane>({}); //当前记录
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<TowerCrane>>([]); //表格列
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态
    const [selectedRows, setSelectedRows] = useState<TowerCrane[]>([]);

    const defaultColumns:ColumnsType<TowerCrane> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "sn",
            title: "设备序列号",
            dataIndex: "sn",
            width: 100,
            ellipsis: true,
        },
        {
            key: "name",
            title: "设备名称",
            dataIndex: "name",
            width: 100,
            ellipsis: true,
        },
        {
            key: "plateNo",
            title: "车牌号",
            dataIndex: "plateNo",
            width: 100,
            ellipsis: true,
        },
        {
            key: "remark",
            title: "备注信息",
            dataIndex: "remark",
            width: 100,
            ellipsis: true,
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        setCurrentTowerCrane(record);
                        setDetailVisible(true);
                        }}>
                        查看
                    </a>,
                    <Divider type="vertical" />,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
        };
        setQueryParams(newQueryParams);

        TowerCraneApi.findTowerCranePage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const batchDelete = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "批量删除",
            content: "确定删除所选塔吊黑匣子吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await TowerCraneApi.deleteTowerCrane(row);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                console.log(tableHeight);
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const deleteTowerCrane = (towerCrane:TowerCrane)=>{
        Modal.confirm({
            title: "删除塔吊黑匣子确认",
            content: "确定删除该塔吊黑匣子吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await TowerCraneApi.deleteTowerCrane(towerCrane);
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: TowerCrane
    }> = ({item}) => {
        return (
            <Dropdown
                key="row-extra-btns"
                overlay={
                    <Menu
                        onClick={({key})=>{
                            if(key==='delete') {
                                deleteTowerCrane(item);
                            }
                        }}
                    >
                        <Menu.Item key="delete">
                            删除
                        </Menu.Item>
                    </Menu>
                }
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='name' style={{display:'inline-block', height:40, width:200}}>
            <ProFormText
                label="设备名称"
                name="nameLike"
            />
        </div>,
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        setQueryParams({
                            ...queryParams,
                            ...values,
                        })
                    }}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    塔吊黑匣子列表
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        {
                            selectedRows.length>0 &&
                            <Button type="link">
                                已选择 <strong>{selectedRows.length}</strong> 条
                            </Button>
                        }

                        <Button
                            type="primary"
                            onClick={()=>{
                                setAddFormVisible(true);
                            }}
                        >
                            新建
                        </Button>
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        if(key==='batchDelete'){
                                            batchDelete();
                                        }
                                    }}
                                    items={[{
                                        key: 'batchDelete',
                                        label: '批量删除',
                                    }]}
                               />
                            }
                        >
                            批量操作
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                            rowSelection={{
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                defaultSelectedRowKeys: [],
                                onChange:(selectedRowKeys, selectedRows)=>{
                                    setSelectedRows(selectedRows);
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>


            {
                addFormVisible &&
                <TowerCraneAddForm
                    visible={true}
                    onSuccess={()=>{
                        setAddFormVisible(false);
                        reloadData({});
                    }}
                    onFail={()=>{
                        setAddFormVisible(false);
                    }}
                />
            }

            {
                detailVisible &&
                <TowerCraneDetail
                    towerCrane={currentTowerCrane}
                    visible={true}
                    onSuccess={()=>{
                        setDetailVisible(false);
                        reloadData({});
                    }}
                    onFail={()=>{
                        setDetailVisible(false);
                    }}
                />
            }
        </div>
    );
};

export default TowerCranePage;