import _ from 'lodash';

import { Divider, DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormText, } from '@ant-design/pro-form';
import { Contractor } from './../../type/ContractorType';
import ContractorApi from '../../api/ContractorApi';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';
import DictItemSelector from '../../../system/pages/DictItem/DictItemSelector';
import ContractorTypeDisplay from './ContractorTypeDisplay';

export interface ContractorDetailProps extends DrawerProps {
    contractor?: Contractor,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const ContractorDetail:React.FC<ContractorDetailProps> = (props)=>{

    const {contractor, onFail, onSuccess} = props;
    const [title, setTitle] = useState<string|undefined>(contractor?.name);
    const [type, setType] = useState<string>(contractor?.type);

    return (
        <Drawer
            title={(
                <div>
                    {title}&nbsp;&nbsp;
                    <ContractorTypeDisplay
                        type={type}
                   />
                </div>
            )}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<Contractor>
                column={6}
                request={async ()=>{
                    const result:Contractor = await ContractorApi.findContractorById(contractor?.id||"");
                    setTitle(result?.name);
                    setType(result?.type);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        const result = await ContractorApi.patchEditContractor(requestData);
                        setTitle(result?.name);
                        setType(result?.type);
                    }
                }}
            >
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['uniCreditCode']}
                        label="社会统一信用代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['name']}
                        label="单位名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['type']}
                        label="参见类型"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <DictItemDisplay
                                    entryCode='PROJECT_CORP_TYPE'
                                    value={dom?.toString()||""}
                                />
                            );
                        }}
                        renderFormItem={() => {
                            return (
                                <DictItemSelector 
                                    entryCode='PROJECT_CORP_TYPE'
                                />
                            );
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['regulatoryAgenciesAreaCode']}
                        label="管理地区代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={6}
                        dataIndex={['registeredAddress']}
                        label="注册地址"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={6}
                        dataIndex={['regulatoryAgenciesAddress']}
                        label="管理部门地址"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />

                    <ProDescriptions.Item span={6}>
                        <div style={{fontWeight:'bold', marginTop:16}}>法人信息</div>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['legalMan']}
                        label="法人姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['legalManIdCardType']}
                        label="法人证件类型"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <DictItemDisplay
                                    entryCode="HR_CER_TYPE"
                                    value={dom?.toString()||""}
                                />
                            );
                        }}
                        renderFormItem={() => {
                            return (
                                <DictItemSelector 
                                    entryCode='HR_CER_TYPE'
                                />
                            );
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['legalManIdCardNo']}
                        label="法人证件号码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['legalManMobile']}
                        label="法人电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />

                    <ProDescriptions.Item span={6}>
                        <div style={{fontWeight:'bold', marginTop:16}}>联系人信息</div>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['linkmanName']}
                        label="联系人姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['linkmanMobile']}
                        label="联系人电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['linkmanCornet']}
                        label="联系人短号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['hrmName']}
                        label="劳务专员姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['hrmMobile']}
                        label="劳务专员电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['hrmCornet']}
                        label="劳务专员短号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />

                    <ProDescriptions.Item span={6}>
                        <div style={{fontWeight:'bold', marginTop:16}}>进离场信息</div>
                    </ProDescriptions.Item>

                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['entryDate']}
                        label="进场日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom?.toString()?.substring(0, 10)}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker />;
                        }}
                    />
                    <ProDescriptions.Item
                        span={3}
                        dataIndex={['exitDate']}
                        label="离场日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                   {dom?.toString()?.substring(0, 10)}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker />;
                        }}
                    />
                    
            </ProDescriptions>
        </Drawer>
    );
};

export default ContractorDetail;