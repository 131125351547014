import React, {FC, useState, useRef, useEffect} from "react";
import {stringify} from 'qs';

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert, Tag, Image} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { Employee, EmployeeQto } from './../../type/EmployeeType';
import EmployeeApi from "../../api/EmployeeApi";
import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormInstance, ProFormText } from "@ant-design/pro-form";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";
import GlobalConst from "../../../../utils/GlobalConst";
import EmployeeStatusDisplay from "../Employee/EmployeeStatusDisplay";
import EmployeeWorkingStatusDisplay from "../Employee/EmployeeWorkingStatusDisplay";
import EmployeeDetail from "../Employee/EmployeeDetail";
import EmployeeBatchExit from "../Employee/EmployeeBatchExit";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39*2;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47*2;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55*2;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const EmployeePage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<ProFormInstance<EmployeeQto>>(null);

    const [addFormVisible, setAddFormVisible] = useState<boolean>(false); //新建表单可见状态
    const [detailVisible, setDetailVisible] = useState<boolean>(false); //明细可见状态
    const [exportVisible, setExportVisible] = useState<boolean>(false); //导出设置状态
    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [currentEmployee, setCurrentEmployee] = useState<Employee>({}); //当前记录
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<Employee>>([]); //表格列
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态
    const [selectedRows, setSelectedRows] = useState<Employee[]>([]);
    const [batchEntryFormVisible, setBatchEntryFormVisible] = useState<boolean>(false);
    const [batchExitFormVisible, setBatchExitFormVisible] = useState<boolean>(false);

    const defaultColumns:ColumnsType<Employee> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 60,
            fixed: 'left',
        },
        {
            key: "idCardAvatarImageUrl",
            title: "头像",
            dataIndex: "idCardInfo",
            width: 80,
            ellipsis: true,
            fixed: 'left',
            render:(val:any)=>{
                return (
                    <Image 
                        fallback={GlobalConst.EMPTY_IMAGE}
                        src={GlobalConst.FILE_SERVER + val.idCardAvatarImageUrl}
                        width={48}
                        height={60}
                    />
                );
            }
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "jobNo",
            width: 100,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render:(val, record)=>{
                return (
                    <div>
                        {val}&nbsp;
                        <EmployeeStatusDisplay value={record?.workInfo?.status} />
                    </div>
                );
            }
        },
        {
            key: 'idCardInfo',
            title: '证件信息',
            children: [
                {
                    key: "idCardType",
                    title: "证件类型",
                    dataIndex: "idCardInfo",
                    width: 120,
                    ellipsis: true,
                    render:(val)=>{
                        return (
                            <DictItemDisplay 
                                entryCode="HR_CER_TYPE"
                                value={val?.idCardType}
                            />
                        );
                    },
                },
                {
                    key: "idCardNo",
                    title: "证件号码",
                    dataIndex: "idCardInfo",
                    width: 180,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.idCardNo
                    }
                },
            ]
        },
        {
            key: 'workInfo',
            title: '务工信息',
            children: [
                {
                    key: "orgName",
                    title: "参建单位",
                    dataIndex: "org",
                    width: 180,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.name;
                    }
                },
                {
                    key: "workTeamName",
                    title: "所属班组",
                    dataIndex: "workTeam",
                    width: 160,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.name;
                    }
                },
                {
                    key: "workType",
                    title: "工种类型",
                    dataIndex: "workInfo",
                    width: 120,
                    ellipsis: true,
                    render:(val)=>{
                        if(val?.workType==='900'){
                            return (
                                <Tag color={'#108ee9'}>
                                    <DictItemDisplay 
                                        entryCode="JZ_MANAGER_POST_TYPE"
                                        value={val?.manageType}
                                    />
                                </Tag>
                            );
                        } else {
                            return (
                                <Tag>
                                    <DictItemDisplay 
                                        entryCode="HR_WORK_TYPE"
                                        value={val?.workType}
                                    />
                                </Tag>
                            );
                        }
                    }
                },
            ]
        },
        {
            key: "lastAttendanceTime",
            title: "最近考勤时间",
            dataIndex: "workInfo",
            width: 160,
            ellipsis: true,
            render:(val)=>{
                return val?.noAttendanceBeginTime?.substring(0, 19);
            }
        },
        {
            key: "noAttendanceDays",
            title: "连续无考勤天数",
            dataIndex: "workInfo",
            width: 160,
            ellipsis: true,
            render:(val)=>{
                return val?.noAttendanceDays;
            }
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    <a key='view' onClick={()=>{
                        setCurrentEmployee(record);
                        setDetailVisible(true);
                        }}>
                        查看
                    </a>,
                    <Divider key="splitter1" type="vertical" />,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
            status: ['EMPLOYMENT', 'TEMP_RESIGNED'],
            orderBy: "no_attendance_begin_time asc",
        };
        setQueryParams(newQueryParams);

        EmployeeApi.findEmployeePage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const batchDelete = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "批量删除",
            content: "确定删除所选工人信息吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await EmployeeApi.deleteEmployee(row);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    const batchEntry = async ()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        for(let i=0; i<selectedRows.length; i++){
            if(selectedRows[i]?.workInfo?.status==='EMPLOYMENT'){
                Modal.error({
                    title: '错误提示',
                    content: selectedRows[i].name + "已经在职，无法重新入职",
                });

                return;
            }
        }

        setBatchEntryFormVisible(true);
    }

    const batchExit = async ()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }
        for(let i=0; i<selectedRows.length; i++){
            if(selectedRows[i]?.workInfo?.status==='RESIGNED'){
                Modal.error({
                    title: '错误提示',
                    content: selectedRows[i].name + "已经离职，无法重新离职",
                });
                
                return;
            }
        }

        setBatchExitFormVisible(true);
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                console.log(tableHeight);
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const deleteEmployee = (employee:Employee)=>{

        if(employee?.workInfo?.status!=='RESIGNED'){
            Modal.error({
                title: "错误提示",
                content: employee.name + "还在职，请先离职再删除人员。",
            });

            return;
        }

        Modal.confirm({
            title: "删除工人信息确认",
            content: "确定删除该工人信息吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await EmployeeApi.deleteEmployee(employee);
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: Employee
    }> = ({item}) => {

        const items = [];

        if(item?.workInfo?.status==='RESIGNED'){
            items.push({
                key:'entry',
                label: '进场'
            });
        } else if(item?.workInfo?.status==='EMPLOYMENT') {
            items.push({
                key:'exit',
                label: '离场'
            });
        } else if(item?.workInfo?.status==='RESIGNED') {
            items.push({
                key:'delete',
                label: '删除'
            });
        }

        return (
            <Dropdown
                key="row-extra-btns"
                overlay={
                    <Menu
                        onClick={({key})=>{
                            if(key==='delete') {
                                deleteEmployee(item);
                            }
                        }}
                        items={items}
                    >
                    </Menu>
                }
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='jobNo' style={{display:'inline-block', height:40, width:140}}>
            <ProFormText
                label="工号"
                name="jobNo"
            />
        </div>,
        <div key='nameLike' style={{display:'inline-block', height:40, width:140}}>
            <ProFormText
                label="姓名"
                name="nameLike"
            />
        </div>,
        <div key='idCardNo' style={{display:'inline-block', height:40, width:260}}>
            <ProFormText
                label="证件号码"
                name="idCardNo"
            />
        </div>,
        <div key='mobile' style={{display:'inline-block', height:40, width:200}}>
            <ProFormText
                label="手机号"
                name="mobile"
            />
        </div>,
        <div key='orgId' style={{display:'inline-block', height:40, width:260}}>
            <OrganizationSelector
                label="参建单位"
                name="orgId"
            />
        </div>,
        <div key='workTeamId' style={{display:'inline-block', height:40, width:260}}>
            <WorkTeamSelector
                label="所属班组"
                name="workTeamId"
            />
        </div>,
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <ProForm<EmployeeQto>
                    formRef={queryFormRef}
                    submitter={false}
                    layout={'horizontal'}
                    initialValues={{

                    }}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </ProForm>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    工人信息列表
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        {
                            selectedRows.length>0 &&
                            <Button type="link">
                                已选择 <strong>{selectedRows.length}</strong> 条
                            </Button>
                        }

                        <Button
                            type="primary"
                            onClick={()=>{
                                setAddFormVisible(true);
                            }}
                        >
                            新建
                        </Button>
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        if(key==='batchDelete'){
                                            batchDelete();
                                        }else if(key==='batchEntry'){
                                            batchEntry();
                                        }else if(key==='batchExit'){
                                            batchExit();
                                        }
                                    }}
                                    items={[{
                                        key: 'batchEntry',
                                        label: '批量入职',
                                    }, {
                                        key: 'batchExit',
                                        label: '批量离职',
                                    }, {
                                        key: 'batchDelete',
                                        label: '批量删除',
                                    }]}
                               />
                            }
                        >
                            批量操作
                        </Dropdown.Button>
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        if(key==='exportExcelAll'){
                                            setExportVisible(true);
                                        }else if(key==='exportExcelByWorkTeam'){
                                            const newQueryParams = {
                                                ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current?.getFieldsFormatValue()||{}),
                                                Authorization: localStorage.getItem('Authorization')||"",
                                            };

                                            window.open(`/api/tenant/hr/employee-report/employee-roster/excel?${stringify(newQueryParams)}`);
                                        }
                                    }}
                                    items={[{
                                        key: 'exportExcelAll',
                                        label: '导出所有',
                                    }, {
                                        key: 'exportExcelByWorkTeam',
                                        label: '按班组导出',
                                    }]}
                               />
                            }
                        >
                            Excel导出
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            bordered
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                            rowSelection={{
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                defaultSelectedRowKeys: [],
                                onChange:(selectedRowKeys, selectedRows)=>{
                                    setSelectedRows(selectedRows);
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>


            {
                detailVisible &&
                <EmployeeDetail
                    employee={currentEmployee}
                    visible={true}
                    onSuccess={()=>{
                        setDetailVisible(false);
                        reloadData({});
                    }}
                    onFail={()=>{
                        setDetailVisible(false);
                    }}
                />
            }

            {
                batchExitFormVisible &&
                <EmployeeBatchExit
                    employees={selectedRows}
                    visible={batchExitFormVisible}
                    onSuccess={()=>{
                        reloadData({});

                        setBatchExitFormVisible(false);
                    }}
                    onFail={()=>{
                        setBatchExitFormVisible(false);
                    }}
                />
            }

        </div>
    );
};

export default EmployeePage;