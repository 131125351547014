import _ from 'lodash';

import { Card, Descriptions, DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormDigit, ProFormProps, ProFormRadio} from '@ant-design/pro-form';

import React, { useRef, useState } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormDatePicker, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { LaborContract } from './../../type/LaborContractType';
import LaborContractApi from '../../api/LaborContractApi';
import { message } from 'antd';
import { Employee } from '../../type/EmployeeType';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';

export interface LaborContractAddFormProps extends DrawerProps {
    employee: Employee,
    onFail:()=>void,
    onSuccess:()=>void,
};



const LaborContractAddForm:React.FC<LaborContractAddFormProps> = (props)=>{

    const [form] = Form.useForm<LaborContract>();
    const {onFail, onSuccess, employee} = props;
    const [periodType, setPeriodType] = useState<string>('FIXED_RANGE');
    const [wageType, setWageType] = useState<string>('DAILY');

    return (
        <Drawer
            title="新建人事合同"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <Descriptions
                title={"人员基本信息"}
                column={3}
            >
                <Descriptions.Item
                    label="工号"
                >
                    {employee.jobNo}
                </Descriptions.Item>
                <Descriptions.Item
                    label="姓名"
                >
                    {employee.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="手机号码"
                >
                    {employee?.contactInfo?.mobile}
                </Descriptions.Item>
                <Descriptions.Item
                    label="证件类型"
                >
                   <DictItemDisplay 
                        entryCode='HR_CER_TYPE'
                        value={employee?.idCardInfo?.idCardType}
                   />
                </Descriptions.Item>
                <Descriptions.Item
                    label="证件号码"
                >
                    {employee.idCardInfo.idCardNo}
                </Descriptions.Item>
                <Descriptions.Item
                    label="工人类型"
                >
                    {employee.workInfo.workRole=='10'?'管理人员':'建筑工人'}
                </Descriptions.Item>
                <Descriptions.Item
                    label="参建单位"
                >
                    {employee?.org?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="所属班组"
                >
                    {employee?.workTeam?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="工种类型"
                >
                    {
                        employee.workInfo.workRole=='10'?
                        <DictItemDisplay 
                            entryCode='JZ_MANAGER_POST_TYPE'
                            value={employee?.workInfo?.manageType}
                        /> : 
                        <DictItemDisplay 
                            entryCode='HR_WORK_TYPE'
                            value={employee?.workInfo?.workType}
                        /> 
                    }
                </Descriptions.Item>
            </Descriptions>

            <Card
                title={"合同内容"}
            >
                <ProForm
                    submitter={{
                        render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                    }}
                    onFinish={async (values:  LaborContract)=>{
                        await LaborContractApi.addLaborContract({
                            ...values,
                            employeeId: employee.id,
                        });
                        form.resetFields();
                        message.success("新建人事合同成功！");
                        if(onSuccess){
                            onSuccess();
                        }else{
                            onFail();
                        }
                        return true;
                    }}
                    onValuesChange={(values)=>{
                        if(values.periodType){
                            setPeriodType(values.periodType);
                        }
                        if(values.wageType){
                            setWageType(values.wageType);
                        }
                    }}
                    form={form}
                    layout={'horizontal'}
                >
                    <Row>
                        <Col span={12}>
                            <ProFormDatePicker
                                name={['contractDate']}
                                label="签订日期"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "签订日期不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormRadio.Group
                                name={['periodType']}
                                label="签订类型"
                                tooltip="定期合同指的是固定期限的合同，定量合同指的是以完成工作为期限的合同"
                                placeholder=""
                                rules={[{required:true, message: "签订类型不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                                options={[{
                                    label: '定期合同',
                                    value: 'FIXED_RANGE'
                                }, {
                                    label: '定量合同',
                                    value: 'WORK_COMPLETED',
                                }]}
                            />
                        </Col>
                        {
                            periodType==='FIXED_RANGE' &&
                            <Col span={12}>
                                <ProFormDatePicker
                                    name={['beginDate']}
                                    label="开始日期"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "开始日期不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>
                        }
                        {
                            periodType==='FIXED_RANGE' &&
                            <Col span={12}>
                                <ProFormDatePicker
                                    name={['endDate']}
                                    label="结束日期"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "结束日期不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>
                        }
                        {
                            periodType==='WORK_COMPLETED' &&
                            <Col span={12}>
                                <ProFormDigit
                                    name={['workload']}
                                    label="总工作量"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:false, message: "总工作量不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                />
                            </Col>
                        }
                        {
                            periodType==='WORK_COMPLETED' &&
                            <Col span={12}>
                                <ProFormRadio.Group
                                    name={['measureUnit']}
                                    label="计量单位"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "计量单位不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                    options={[{
                                        label: '米',
                                        value: 'METRE'
                                    }, {
                                        label: '平方米',
                                        value: 'SQUARE_METRE',
                                    }, {
                                        label: '立方米',
                                        value: 'CUBIC_METRE',
                                    }]}
                                />
                            </Col>
                        }
                        <Col span={12}>
                            <ProFormRadio.Group
                                    name={['wageType']}
                                    label="结算方式"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "结算方式不能为空"}]}
                                    {...FormLayout.itemLayout.span12}
                                    options={[{
                                        label: '日结',
                                        value: 'DAILY'
                                    }, {
                                        label: '月结',
                                        value: 'MONTHLY',
                                    }, {
                                        label: '计量',
                                        value: 'UNIT',
                                    }]}
                                />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                name={['unitPrice']}
                                label="结算单价"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "结算单价不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['lostUnitPrice']}
                                label="误工费"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "误工费不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['salaryDate']}
                                label="发薪日"
                                tooltip=""
                                placeholder=""
                                rules={[{required:false, message: "发薪日不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                    </Row>
                </ProForm>
            </Card>
        </Drawer>
    );
};

export default LaborContractAddForm;