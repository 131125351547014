
import {LaborContractSetting, LaborContractSettingQto} from '../type/LaborContractSettingType';
import HttpInvoker from '../../../utils/http/HttpInvoker';


const addLaborContractSetting = async (params:LaborContractSetting)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/labor-contract-setting`, params);
};


const editLaborContractSetting = async (params:LaborContractSetting)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/labor-contract-setting/${params.id}`, params);
};


const patchEditLaborContractSetting = async (params:LaborContractSetting)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/labor-contract-setting/${params.id}`, params);
};


const deleteLaborContractSetting = async (params:LaborContractSetting)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/labor-contract-setting/${params.id}`, params);
};


const findLaborContractSettingById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract-setting/${params}`, params);
};

const findLaborContractSettingList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract-setting/list`, params);
};

const findLaborContractSettingPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract-setting/page`, params);
};



export default {
    addLaborContractSetting,
    editLaborContractSetting,
    patchEditLaborContractSetting,
    deleteLaborContractSetting,
    findLaborContractSettingById,
    findLaborContractSettingList,
    findLaborContractSettingPage,
};


