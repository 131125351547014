import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { TowerCraneLog } from './../../type/TowerCraneLogType';
import TowerCraneLogApi from '../../api/TowerCraneLogApi';
import { message } from 'antd';

export interface TowerCraneLogAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const TowerCraneLogAddForm:React.FC<TowerCraneLogAddFormProps> = (props)=>{

    const [form] = Form.useForm<TowerCraneLog>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建塔吊黑匣子记录"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  TowerCraneLog)=>{
                    await TowerCraneLogApi.addTowerCraneLog(values);
                    form.resetFields();
                    message.success("新建塔吊黑匣子记录成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceSn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceTime']}
                            label="设备时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['weight']}
                            label="当前吊重(千克)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前吊重(千克)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['maxWeight']}
                            label="安全吊重(千克)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "安全吊重(千克)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['height']}
                            label="当前高度(米)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前高度(米)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['rotary']}
                            label="当前旋转角度(度)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前旋转角度(度)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['radius']}
                            label="当前幅度(米)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前幅度(米)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['momentOfForcePercent']}
                            label="力矩百分比"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "力矩百分比不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['ropeMagnification']}
                            label="吊绳倍率"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "吊绳倍率不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['slantAngel']}
                            label="倾斜角度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "倾斜角度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['slantDirection']}
                            label="倾斜方向"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "倾斜方向不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['windSpeed']}
                            label="当前风速(米/秒)"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "当前风速(米/秒)不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['warningStatus']}
                            label="系统预警状态编码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "系统预警状态编码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['alarmStatus']}
                            label="系统报警状态编码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "系统报警状态编码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['irregularitiesStatus']}
                            label="系统违规操作状态编码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "系统违规操作状态编码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['firstRunDate']}
                            label="首次运行时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "首次运行时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['lastRunDate']}
                            label="末次运行时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "末次运行时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['openTime']}
                            label="本次开机时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "本次开机时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['lastUpdateTime']}
                            label="最近更新时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "最近更新时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['driverId']}
                            label="驾驶员ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "驾驶员ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['driverName']}
                            label="驾驶员姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "驾驶员姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['createdTime']}
                            label="创建时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "创建时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default TowerCraneLogAddForm;