import HttpInvoker from "../../../utils/http/HttpInvoker";


const attendanceDayReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/tenant-attendance-report/tenant-attendance-day-report`, params);
};

const attendanceMonthReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/tenant-attendance-report/tenant-attendance-month-report`, params);
};

export default {
    attendanceDayReport,
    attendanceMonthReport,
}