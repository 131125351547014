import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormProps, ProFormTextArea} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Modal, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormDatePicker, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { EmployeeExit } from './../../type/EmployeeExitType';
import EmployeeExitApi from '../../api/EmployeeExitApi';
import { message } from 'antd';

export interface EmployeeExitAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};

const EmployeeExitAddForm:React.FC<EmployeeExitAddFormProps> = (props)=>{

    const [form] = Form.useForm<EmployeeExit>();
    const {onFail, onSuccess} = props;

    return (
        <Modal
            title="新建人员离职信息"
            destroyOnClose={false}
            closable={true}
            width={480}
            footer={null}
            onCancel={()=>{
                onFail();
            }}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  EmployeeExit)=>{
                    await EmployeeExitApi.addEmployeeExit(values);
                    form.resetFields();
                    message.success("新建人员离职信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={24}>
                        <ProFormDatePicker
                            name={['exitTime']}
                            label="离职时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "离职时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormTextArea
                            name={['exitComment']}
                            label="离职说明"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "离职说明不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Modal>
    );
};

export default EmployeeExitAddForm;