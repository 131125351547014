
import {LedMachineMetaInfo, LedMachineMetaInfoQto} from './../type/LedMachineMetaInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addLedMachineMetaInfo = async (params:LedMachineMetaInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/led-machine-meta-info`, params);
};


const editLedMachineMetaInfo = async (params:LedMachineMetaInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/led-machine-meta-info/${params.id}`, params);
};


const patchEditLedMachineMetaInfo = async (params:LedMachineMetaInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/led-machine-meta-info/${params.id}`, params);
};


const deleteLedMachineMetaInfo = async (params:LedMachineMetaInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/led-machine-meta-info/${params.id}`, params);
};


const findLedMachineMetaInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-meta-info/${params}`, params);
};

const findLedMachineMetaInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-meta-info/list`, params);
};

const findLedMachineMetaInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/led-machine-meta-info/page`, params);
};



export default {
    addLedMachineMetaInfo,
    editLedMachineMetaInfo,
    patchEditLedMachineMetaInfo,
    deleteLedMachineMetaInfo,
    findLedMachineMetaInfoById,
    findLedMachineMetaInfoList,
    findLedMachineMetaInfoPage,
};


