import _ from 'lodash';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import type {ProFormProps} from '@ant-design/pro-form';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import RoleSelector from "./../../../system/pages/Role/RoleSelector";

import { Role, RoleAddFormProps } from './../../type/RoleType';
import RoleApi from '../../api/RoleApi';
import { message } from 'antd';

const RoleAddForm:React.FC<RoleAddFormProps> = (props)=>{

    const [form] = Form.useForm<Role>();
    const {onClose} = props;

    const formItemLayout = {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 },
    };

    return (
        <Drawer
            title="新建系统角色"
            destroyOnClose={false}
            closable={false}
            width={800}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onRefresh')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right'}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Role)=>{form.resetFields();
                    await RoleApi.addRole(values);
                    form.resetFields();
                    message.success("新建系统角色成功！");
                    onClose();
                    return true;
                }}
                form={form}
                layout={'horizontal'}
                {...formItemLayout}
            >
                <Row>
                    <Col span={12}>
                        <RoleSelector
                                name={['code']}
                                label="角色编码"
                                tooltip=""
                                placeholder=""
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                                name={['name']}
                                label="角色名称"
                                tooltip=""
                                placeholder=""
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                                name={['nameAbbr']}
                                label="角色简称"
                                tooltip=""
                                placeholder=""
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                                name={['nameAlias']}
                                label="角色别名"
                                tooltip=""
                                placeholder=""
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                                name={['sortNo']}
                                label="显示顺序"
                                tooltip=""
                                placeholder=""
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default RoleAddForm;