
import {Elevator, ElevatorQto} from './../type/ElevatorType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addElevator = async (params:Elevator)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/elevator`, params);
};


const editElevator = async (params:Elevator)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/elevator/${params.id}`, params);
};


const patchEditElevator = async (params:Elevator)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/elevator/${params.id}`, params);
};


const deleteElevator = async (params:Elevator)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/elevator/${params.id}`, params);
};


const findElevatorById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator/${params}`, params);
};

const findElevatorList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator/list`, params);
};

const findElevatorPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator/page`, params);
};



export default {
    addElevator,
    editElevator,
    patchEditElevator,
    deleteElevator,
    findElevatorById,
    findElevatorList,
    findElevatorPage,
};


