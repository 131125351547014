
interface AttendanceResultTypeDisplayProps {
    value:string
}

const AttendanceResultTypeDisplay:React.FC<AttendanceResultTypeDisplayProps> = (props)=>{

    switch(props.value){
        case 'LOST':
            return <span style={{color:'red'}}>⊙</span>;
        case 'HALF':
            return <span style={{color:'orange'}}>※</span>;
        case 'NORMAL':
            return <span style={{color:'green'}}>√</span>;
        default:
            return <span style={{color:'gray'}}>X</span>;
            
    }
};


export default AttendanceResultTypeDisplay;