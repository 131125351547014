
import {Contractor, ContractorQto} from './../type/ContractorType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addContractor = async (params:Contractor)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/contractor`, params);
};


const editContractor = async (params:Contractor)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/contractor/${params.id}`, params);
};


const patchEditContractor = async (params:Contractor)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/contractor/${params.id}`, params);
};


const deleteContractor = async (params:Contractor)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/contractor/${params.id}`, params);
};


const findContractorById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/contractor/${params}`, params);
};

const findContractorList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/contractor/list`, params);
};

const findContractorPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/contractor/page`, params);
};



export default {
    addContractor,
    editContractor,
    patchEditContractor,
    deleteContractor,
    findContractorById,
    findContractorList,
    findContractorPage,
};


