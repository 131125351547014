import HttpInvoker from "../../../utils/http/HttpInvoker";


const attendanceDayReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/work-team-attendance-report/work-team-attendance-day-report`, params);
};

const attendanceDayReportBarChart = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/work-team-attendance-report/work-team-attendance-day-report/bar-chart`, params);
};

const attendanceMonthReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/work-team-attendance-report/work-team-attendance-month-report`, params);
};

export default {
    attendanceDayReport,
    attendanceDayReportBarChart,
    attendanceMonthReport,
}