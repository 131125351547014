
import {Organization, OrganizationQto} from './../type/OrganizationType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addOrganization = async (params:Organization)=>{

    return HttpInvoker.postObject(`/api/tenant/system/organization`, params);
};


const editOrganization = async (params:Organization)=>{

    return HttpInvoker.putObject(`/api/tenant/system/organization/${params.id}`, params);
};


const patchEditOrganization = async (params:Organization)=>{

    return HttpInvoker.patchObject(`/api/tenant/system/organization/${params.id}`, params);
};


const deleteOrganization = async (params:Organization)=>{

    return HttpInvoker.deleteObject(`/api/tenant/system/organization/${params.id}`, params);
};


const findOrganizationById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/system/organization/${params}`, params);
};

const findOrganizationList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/organization/list`, params);
};

const findOrganizationPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/organization/page`, params);
};

const findOrganizationTree = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/system/organization/tree`, params);
};


export default {
    addOrganization,
    editOrganization,
    patchEditOrganization,
    deleteOrganization,
    findOrganizationById,
    findOrganizationList,
    findOrganizationPage,
    findOrganizationTree,
};


