
import {EmployeeCheckImageData, EmployeeCheckImageDataQto} from '../type/EmployeeCheckImageData';
import HttpInvoker from '../../../utils/http/HttpInvoker';


const addEmployeeCheckImageData = async (params:EmployeeCheckImageData)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/check-image-data`, params);
};


const editEmployeeCheckImageData = async (params:EmployeeCheckImageData)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/check-image-data/${params.id}`, params);
};


const patchEditEmployeeCheckImageData = async (params:EmployeeCheckImageData)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/check-image-data/${params.id}`, params);
};


const deleteEmployeeCheckImageData = async (params:EmployeeCheckImageData)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/check-image-data/${params.id}`, {});
};


const findEmployeeCheckImageDataById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/check-image-data/${params}`, params);
};

const findEmployeeCheckImageDataList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/check-image-data/list`, params);
};

const findEmployeeCheckImageDataPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/check-image-data/page`, params);
};



export default {
    addEmployeeCheckImageData,
    editEmployeeCheckImageData,
    patchEditEmployeeCheckImageData,
    deleteEmployeeCheckImageData,
    findEmployeeCheckImageDataById,
    findEmployeeCheckImageDataList,
    findEmployeeCheckImageDataPage,
};


