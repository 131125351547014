
import {EmployeeExit, EmployeeExitQto} from './../type/EmployeeExitType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addEmployeeExit = async (params:EmployeeExit)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/employee-exit`, params);
};


const editEmployeeExit = async (params:EmployeeExit)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/employee-exit/${params.id}`, params);
};


const patchEditEmployeeExit = async (params:EmployeeExit)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/employee-exit/${params.id}`, params);
};


const deleteEmployeeExit = async (params:EmployeeExit)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/employee-exit/${params.id}`, params);
};


const findEmployeeExitById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-exit/${params}`, params);
};

const findEmployeeExitList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-exit/list`, params);
};

const findEmployeeExitPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-exit/page`, params);
};



export default {
    addEmployeeExit,
    editEmployeeExit,
    patchEditEmployeeExit,
    deleteEmployeeExit,
    findEmployeeExitById,
    findEmployeeExitList,
    findEmployeeExitPage,
};


