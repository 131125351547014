
import ContractorPage from "./pages/Contractor";
import WorkTeamPage from "./pages/WorkTeam";
import SubWorkTypePage from "./pages/SubWorkType";
import EmployeePage from "./pages/Employee";
import EmployeeEntry from "./pages/EmployeeEntry";
import EmployeeExit from "./pages/EmployeeExit";
import LaborContractPage from './pages/LaborContract';
import EmployeeBankCardPage from './pages/EmployeeBankCard';
import AttendanceMachinePage from "./pages/AttendanceMachine";
import LedMachinePage from "./pages/LedMachine";
import AttendanceLogPage from "./pages/AttendanceLog";
import EmployeeAttendanceDayReportPage from "./pages/EmployeeAttendanceDayReport";
import WorkTeamAttendanceDayReportPage from "./pages/WorkTeamAttendanceDayReport";
import WorkTypeAttendanceDayReportPage from "./pages/WorkTypeAttendanceDayReport";
import ContractorAttendanceDayReportPage from "./pages/ContractorAttendanceDayReport";
import EmployeeEntryExitDayReportPage from './pages/EmployeeEntryExitDayReport';
import EmployeeAttendanceMonthReportPage from './pages/EmployeeAttendanceMonthReport';
import WorkTeamAttendanceMonthReportPage from './pages/WorkTeamAttendanceMonthReport';
import WorkTeamAttendanceRateMonthReportPage from './pages/WorkTeamAttendanceRateMonthReport';
import WorkTypeAttendanceMonthReportPage from './pages/WorkTypeAttendanceMonthReport';
import WorkTypeAttendanceRateMonthReportPage from './pages/WorkTypeAttendanceRateMonthReport';
import ContractorAttendanceMonthReportPage from './pages/ContractorAttendanceMonthReport';
import ContractorAttendanceRateMonthReportPage from './pages/ContractorAttendanceRateMonthReport';
import EmployeeEntryExitMonthReportPage from './pages/EmployeeEntryExitMonthReport';
import EmployeeAbsencePage from './pages/EmployeeAbsence';
import EmployeeTodayCheckedPage from './pages/EmployeeTodayChecked';
import EmployeeAttendanceTodayReportPage from "./pages/EmployeeAttendanceTodayReport";
import ReportedAttendanceMachinePage from "./pages/ReportedAttendanceMachine";


export default [{
    path: '/hr/contractor',
    title: '参建单位',
    component: ContractorPage
}, {
    path: '/hr/work-team',
    title: '班组信息',
    component: WorkTeamPage
}, {
    path: '/hr/sub-work-type',
    title: '工种信息',
    component: SubWorkTypePage
}, {
    path: '/hr/employee',
    title: '工人信息',
    component: EmployeePage
}, {
    path: '/hr/absence-status',
    title: '持续无考勤人员',
    component: EmployeeAbsencePage
}, {
    path: '/hr/employee-entry',
    title: '工人进场信息',
    component: EmployeeEntry
}, {
    path: '/hr/employee-exit',
    title: '工人离场信息',
    component: EmployeeExit
}, {
    path: '/hr/contract',
    title: '人事合同',
    component: LaborContractPage
}, {
    path: '/hr/bank-card',
    title: '工资卡信息',
    component: EmployeeBankCardPage,
}, {
    path: '/hr/attendance-machine',
    title: '考勤机',
    component: AttendanceMachinePage
}, {
    path: '/hr/reported-attendance-machine',
    title: '考勤机备案',
    component: ReportedAttendanceMachinePage
}, {
    path: '/hr/led-machine',
    title: 'LED屏幕',
    component: LedMachinePage
}, {
    path: '/hr/today-checked-employee',
    title: '今日出勤人员',
    component: EmployeeTodayCheckedPage
}, {
    path: '/hr/today-attendance-report',
    title: '今日出勤统计',
    component: EmployeeAttendanceTodayReportPage
}, {
    path: '/hr/attendance-log',
    title: '考勤记录',
    component: AttendanceLogPage
}, {
    path: '/hr/employee-attendance-day-report',
    title: '人员日考勤表',
    component: EmployeeAttendanceDayReportPage
}, {
    path: '/hr/work-team-attendance-day-report',
    title: '班组日考勤表',
    component: WorkTeamAttendanceDayReportPage
}, {
    path: '/hr/work-type-attendance-day-report',
    title: '工种日考勤表',
    component: WorkTypeAttendanceDayReportPage
}, {
    path: '/hr/contractor-attendance-day-report',
    title: '参建单位日考勤表',
    component: ContractorAttendanceDayReportPage
}, {
    path: '/hr/employee-entry-exit-day-report',
    title: '参建单位日考勤表',
    component: EmployeeEntryExitDayReportPage
}, {
    path: '/hr/employee-attendance-month-report',
    title: '人员月考勤表',
    component: EmployeeAttendanceMonthReportPage
}, {
    path: '/hr/work-team-attendance-month-report',
    title: '班组月考勤表',
    component: WorkTeamAttendanceMonthReportPage
}, {
    path: '/hr/work-team-attendance-rate-month-report',
    title: '班组月考勤率统计',
    component: WorkTeamAttendanceRateMonthReportPage
}, {
    path: '/hr/work-type-attendance-month-report',
    title: '工种月考勤表',
    component: WorkTypeAttendanceMonthReportPage
}, {
    path: '/hr/work-type-attendance-rate-month-report',
    title: '工种月考勤率统计',
    component: WorkTypeAttendanceRateMonthReportPage
}, {
    path: '/hr/contractor-attendance-month-report',
    title: '参建单位月考勤表',
    component: ContractorAttendanceMonthReportPage
}, {
    path: '/hr/contractor-attendance-rate-month-report',
    title: '参建单位月考勤率统计',
    component: ContractorAttendanceRateMonthReportPage
}, {
    path: '/hr/employee-entry-exit-month-report',
    title: '进离场月统计',
    component: EmployeeEntryExitMonthReportPage
}]