import { ProColumns } from "@ant-design/pro-table"



const getTableScrollX = (columns: any) => {

    let xWidth = 0;

    for(let i=0; i<columns.length; i++){

        const column = columns[i];

        if(column.children!=null && column.children.length>0){
            xWidth += getTableScrollX(column.children);
        } else {
            if(column.width != null && typeof(column.width)==='number') {
                xWidth += column.width>0?column.width:120;
            } else {
                xWidth += 80;
            }
        }
    }

    return xWidth;
}

export default {

    getTableScrollX,
}