
import {P330800DirectPersonInfo, P330800DirectPersonInfoQto} from './../type/P330800DirectPersonInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addP330800DirectPersonInfo = async (params:P330800DirectPersonInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/hr-sync/p330800-direct-person-info`, params);
};

const readFromZheliBuildJsonData = async (params:any)=>{

    return HttpInvoker.postObject(`/api/tenant/hr-sync/p330800-direct-person-info/zhelibuild/json-data`, params);
};


const editP330800DirectPersonInfo = async (params:P330800DirectPersonInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/hr-sync/p330800-direct-person-info/${params.id}`, params);
};


const patchEditP330800DirectPersonInfo = async (params:P330800DirectPersonInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr-sync/p330800-direct-person-info/${params.id}`, params);
};


const deleteP330800DirectPersonInfo = async (params:P330800DirectPersonInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr-sync/p330800-direct-person-info/${params.id}`, params);
};


const findP330800DirectPersonInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-person-info/${params}`, params);
};

const findP330800DirectPersonInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-person-info/list`, params);
};

const findP330800DirectPersonInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-person-info/page`, params);
};



export default {
    addP330800DirectPersonInfo,
    readFromZheliBuildJsonData,
    editP330800DirectPersonInfo,
    patchEditP330800DirectPersonInfo,
    deleteP330800DirectPersonInfo,
    findP330800DirectPersonInfoById,
    findP330800DirectPersonInfoList,
    findP330800DirectPersonInfoPage,
};


