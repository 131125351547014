
import {P330800DirectDeviceInfo, P330800DirectDeviceInfoQto} from './../type/P330800DirectDeviceInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addP330800DirectDeviceInfo = async (params:P330800DirectDeviceInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/hr-sync/p330800-direct-device-info`, params);
};


const editP330800DirectDeviceInfo = async (params:P330800DirectDeviceInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/hr-sync/p330800-direct-device-info/${params.id}`, params);
};


const patchEditP330800DirectDeviceInfo = async (params:P330800DirectDeviceInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr-sync/p330800-direct-device-info/${params.id}`, params);
};


const deleteP330800DirectDeviceInfo = async (params:P330800DirectDeviceInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr-sync/p330800-direct-device-info/${params.id}`, params);
};


const findP330800DirectDeviceInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-device-info/${params}`, params);
};

const findP330800DirectDeviceInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-device-info/list`, params);
};

const findP330800DirectDeviceInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr-sync/p330800-direct-device-info/page`, params);
};



export default {
    addP330800DirectDeviceInfo,
    editP330800DirectDeviceInfo,
    patchEditP330800DirectDeviceInfo,
    deleteP330800DirectDeviceInfo,
    findP330800DirectDeviceInfoById,
    findP330800DirectDeviceInfoList,
    findP330800DirectDeviceInfoPage,
};


