
import {EmployeeEntry, EmployeeEntryQto} from './../type/EmployeeEntryType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addEmployeeEntry = async (params:EmployeeEntry)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/employee-entry`, params);
};


const editEmployeeEntry = async (params:EmployeeEntry)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/employee-entry/${params.id}`, params);
};


const patchEditEmployeeEntry = async (params:EmployeeEntry)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/employee-entry/${params.id}`, params);
};


const deleteEmployeeEntry = async (params:EmployeeEntry)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/employee-entry/${params.id}`, params);
};


const findEmployeeEntryById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-entry/${params}`, params);
};

const findEmployeeEntryList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-entry/list`, params);
};

const findEmployeeEntryPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-entry/page`, params);
};



export default {
    addEmployeeEntry,
    editEmployeeEntry,
    patchEditEmployeeEntry,
    deleteEmployeeEntry,
    findEmployeeEntryById,
    findEmployeeEntryList,
    findEmployeeEntryPage,
};


