import HttpInvoker from "../../../utils/http/HttpInvoker";

const login = (params:any) =>{

    return HttpInvoker.postObject("/api/client/auth/login/tenant/local", params);
}

const logout = () => {

}

export default {
    login,
    logout,
};