import ProjectInfoPage from './pages/ProjectInfo/ProjectInfoDetail';
import DustMachinePage from './pages/DustMachine';
import DustLogPage from './pages/DustLog';
import ElevatorPage from './pages/Elevator';
import ElevatorLogPage from './pages/ElevatorLog';
import TowerCranePage from './pages/TowerCrane';
import TowerCraneLogPage from './pages/TowerCraneLog';

export default [ {
    path: '/jz/project-info-detail',
    title: '项目管理',
    component: ProjectInfoPage
}, {
    path: '/jz/dust-machine',
    title: '扬尘设备',
    component: DustMachinePage
}, {
    path: '/jz/dust-log',
    title: '扬尘数据',
    component: DustLogPage
}, {
    path: '/jz/elevator',
    title: '升降机黑匣子',
    component: ElevatorPage
}, {
    path: '/jz/elevator-log',
    title: '升降机黑匣子数据',
    component: ElevatorLogPage
}, {
    path: '/jz/tower-crane-machine',
    title: '起重机黑匣子',
    component: TowerCranePage
}, {
    path: '/jz/tower-crane-log',
    title: '起重机黑匣子数据',
    component: TowerCraneLogPage
}]