import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, ProFormDigit, } from '@ant-design/pro-form';
import OrganizationSelector from "./../../../system/pages/Organization/OrganizationSelector";
import { Organization } from './../../type/OrganizationType';
import OrganizationApi from '../../api/OrganizationApi';

export interface OrganizationDetailProps extends DrawerProps {
    organization?: Organization,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onClose:()=>void,
    onRefresh?:()=>void,
};

const OrganizationDetail:React.FC<OrganizationDetailProps> = (props)=>{

    const {organization, onClose, onRefresh, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(organization?.name);

    return (
        <Drawer
            title={title}
            width={800}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onClose}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onRefresh', 'actionRef')}
        >
            <ProDescriptions<Organization>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:Organization = await OrganizationApi.findOrganizationById(organization?.id||"");
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await OrganizationApi.patchEditOrganization(requestData);
                        actionRef?.current?.reload();
                        if(onRefresh){
                            onRefresh();
                        }
                    }
                }}
            >
                <ProDescriptions.Item
                    dataIndex={['parentId']}
                    label="上级机构ID"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <OrganizationSelector />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['code']}
                    label="机构编码"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormText />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['name']}
                    label="机构名称"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormText />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['nameAbbr']}
                    label="机构简称"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormText />;
                    }}
                />
                <ProDescriptions.Item
                    dataIndex={['sortNo']}
                    label="显示顺序"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormDigit />;
                    }}
                />
            </ProDescriptions>
        </Drawer>
    );
};

export default OrganizationDetail;