import { Tag } from "antd";
import React from "react";

interface AttendanceCheckTypeDisplayProps {
    value: string,
}

const COLOR_MAP:any = {
    'CHECK_OUT': 'red',
    'CHECK_IN': 'green',
    'CHECK_AUTO': 'gray',
};

const TEXT_MAP:any = {
    'CHECK_OUT': '出',
    'CHECK_IN': '进',
    'CHECK_AUTO': '自动判定',
};


const AttendanceCheckTypeDisplay:React.FC<AttendanceCheckTypeDisplayProps> = (props)=>{

    const {value} = props;

    return <Tag color={COLOR_MAP[value]}>{TEXT_MAP[value]}</Tag>;
};

export default AttendanceCheckTypeDisplay;