
import {EmployeeAttendanceInfo, EmployeeAttendanceInfoQto} from './../type/EmployeeAttendanceInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addEmployeeAttendanceInfo = async (params:EmployeeAttendanceInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/EmployeeAttendanceInfo`, params);
};


const editEmployeeAttendanceInfo = async (params:EmployeeAttendanceInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/employee-attendance-info/${params.id}`, params);
};


const patchEditEmployeeAttendanceInfo = async (params:EmployeeAttendanceInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/employee-attendance-info/${params.id}`, params);
};


const deleteEmployeeAttendanceInfo = async (params:EmployeeAttendanceInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/employee-attendance-info/${params.id}`, {});
};


const findEmployeeAttendanceInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-attendance-info/${params}`, params);
};

const findEmployeeAttendanceInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-attendance-info/list`, params);
};

const findEmployeeAttendanceInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/employee-attendance-info/page`, params);
};



export default {
    addEmployeeAttendanceInfo,
    editEmployeeAttendanceInfo,
    patchEditEmployeeAttendanceInfo,
    deleteEmployeeAttendanceInfo,
    findEmployeeAttendanceInfoById,
    findEmployeeAttendanceInfoList,
    findEmployeeAttendanceInfoPage,
};


