import React, {useState, useEffect} from "react";
import _ from 'lodash';

import type {SelectProps} from 'antd';
import { Select } from "antd";
import WorkTeamApi from "../../api/WorkTeamApi";
import type { WorkTeam } from "../../type/WorkTeamType";
import { ProFormSelectProps } from "@ant-design/pro-form/es/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const WorkTeamSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);
    const {params} = props;

    useEffect(()=>{
        WorkTeamApi.findWorkTeamList({
            limit: 100,
            deletedFlag: false,
            ...(params||{})
        }).then((result:any)=>{
            const newOptions = result.map((workTeam:WorkTeam)=>{
                return {
                    key: workTeam.id,
                    value: workTeam.id,
                    label: workTeam.name,
                };
            });

            setOptions(newOptions);
        });
    }, [params]);

    return (
        <ProFormSelect
            options={options}
            {...(_.omit(props, "params"))}
        />
    );
};

export default WorkTeamSelector;