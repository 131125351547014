
import React, {FC, useState, useRef} from "react";
import { ProFormText, ProFormUploadButton } from "@ant-design/pro-form";
import { Col, Input, InputProps, Row, Upload, UploadProps } from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";



const SingleFileSelector:React.FC<any> = (props)=>{

    const [loading, setLoading] = useState(false);
    const [fileInfo, setFileInfo] = useState<any>({});

    return (
        <ProFormUploadButton  
                {...props} 
                action={"/api/tenant/file/file/upload"}
                max={1}
                extra={"身份证头像"}
                fieldProps={{
                    name: "file",
                    listType: 'picture-card',
                    headers: {
                        'Authorization': localStorage.getItem('Authorization')||"",
                    }
                }}
                onChange={(info:any)=>{
                    if (info.file.status === 'uploading') {
                        setLoading(true);
                    }else{
                        setLoading(false);
                    }
                    
                    if (info.file.status === 'done') {
                        setFileInfo(info.file.response);

                        if(props.onChange){
                            props.onChange(info.file.response);
                        }
                    }
                }}
            />
    );
};

export default SingleFileSelector;