import { Tag } from "antd";

const EmployeeStatusDisplay:React.FC<{value:string}> = (props)=>{

    let text = "-";
    let color = "";

    switch(props.value){
        case 'OFFERED':
            text="待入职";
            color="#87d068";
            break;
        case 'EMPLOYMENT':
            text="在职";
            color="#2db7f5";
            break;
        case 'RETURN':
            text="返聘";
            color="#108ee9";
            break;
        case 'RESIGNED':
            text="离职";
            color="#f50";
            break;
        case 'RETIRED':
            text="退休";
            color="#f50";
            break;
        case 'DISMISS':
            text="辞退";
            color="#f50";
            break;
        case 'TEMP_RESIGNED':
            text="临时离职";
            color="orange";
            break;
        case 'CANDIDATE':
            text="后备";
            color="";
            break; 
    }

    return (
        <Tag color={color}>
            {text}
        </Tag>
    );
}

export default EmployeeStatusDisplay;