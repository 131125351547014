import React, { useEffect, useState } from 'react';
import {useSelector, useDispatch, useParams} from 'dva';

import { ProFormSelect } from '@ant-design/pro-form';

interface DictItemSelectorProps {
    entryCode: string,
    groupKey?: string,
    excludeValues?: string[],
    [propName:string]:any,
}

const DictItemSelector:React.FC<DictItemSelectorProps> = (props)=>{

    const {entryCode, groupKey, excludeValues} = props;
    const {dictEntryList} = useSelector((state:any)=>(state['global']));
    const [items, setItems] = useState<any>(dictEntryList[entryCode]);

    useEffect(()=>{
        const items = (dictEntryList[entryCode]||[]).filter((i:any)=>{
            return !groupKey || i.groupKey===groupKey;
        }).filter((i:any)=>{
            return (excludeValues||[]).indexOf(i.value)<0;
        });
        setItems(items);
    }, [entryCode, groupKey]);

    const valueEnum:any = {};

    items.forEach((i:any)=>{
        valueEnum[i.value] = i.text;
    });

    return (
        <ProFormSelect 
            valueEnum={valueEnum}
            {...props}
        >

        </ProFormSelect>
    );
}

export default DictItemSelector;