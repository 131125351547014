
import {ElevatorLog, ElevatorLogQto} from './../type/ElevatorLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addElevatorLog = async (params:ElevatorLog)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/elevator-log`, params);
};


const editElevatorLog = async (params:ElevatorLog)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/elevator-log/${params.id}`, params);
};


const patchEditElevatorLog = async (params:ElevatorLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/elevator-log/${params.id}`, params);
};


const deleteElevatorLog = async (params:ElevatorLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/elevator-log/${params.id}`, params);
};


const findElevatorLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator-log/${params}`, params);
};

const findElevatorLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator-log/list`, params);
};

const findElevatorLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/elevator-log/page`, params);
};



export default {
    addElevatorLog,
    editElevatorLog,
    patchEditElevatorLog,
    deleteElevatorLog,
    findElevatorLogById,
    findElevatorLogList,
    findElevatorLogPage,
};


