import ProForm, { ProFormCheckbox, ProFormInstance } from "@ant-design/pro-form";
import { Modal } from "antd";
import { useRef } from "react";

interface EmployeeExportSettingProps {
    onExport:(values:any)=>void,
    onCancel: ()=>void,
}

const EmployeeExportSetting:React.FC<EmployeeExportSettingProps> = (props)=>{

    
    const form = useRef<ProFormInstance>();

    return (
        <Modal
            visible={true}
            title={"人员导出列选择"}
            width={640}
            onCancel={props.onCancel}
            onOk={()=>{
                const values = form.current?.getFieldsFormatValue && form.current?.getFieldsFormatValue();
                
                props.onExport({
                    exportFields: values.exportFields.join(",")
                });
            }}
        >
            <ProForm
                formRef={form}
                submitter={false}
                initialValues={{
                    exportFields: ['jobNo']
                }}
            >
                <ProFormCheckbox.Group 
                    name={"exportFields"}
                    label={"表格列"}
                    options={[{
                        label: "工号",
                        value: "jobNo",
                    }, {
                        label: "姓名",
                        value: "name"
                    }, {
                        label: "性别",
                        value: "sex"
                    }, {
                        label: "民族",
                        value: "nation"
                    }, {
                        label: "证件类型",
                        value: "idCardType"
                    }, {
                        label: "证件号码",
                        value: "idCardNo"
                    }, {
                        label: "手机全号",
                        value: "mobile"
                    }, {
                        label: "手机短号",
                        value: "cornet"
                    }, {
                        label: "参建单位",
                        value: "orgName"
                    }, {
                        label: "所属班组",
                        value: "workTeamName"
                    }, {
                        label: "工种类型",
                        value: "workType"
                    }, {
                        label: "入职日期",
                        value: "entryDate"
                    }, {
                        label: "离职日期",
                        value: "exitDate"
                    }, {
                        label: "在岗状态",
                        value: "status"
                    }, {
                        label: "联系地址",
                        value: "address"
                    }]}
                />
            </ProForm>
        </Modal>
    );
};

export default EmployeeExportSetting;