import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { DustLog } from './../../type/DustLogType';
import DustLogApi from '../../api/DustLogApi';
import { message } from 'antd';

export interface DustLogAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const DustLogAddForm:React.FC<DustLogAddFormProps> = (props)=>{

    const [form] = Form.useForm<DustLog>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建扬尘记录"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  DustLog)=>{
                    await DustLogApi.addDustLog(values);
                    form.resetFields();
                    message.success("新建扬尘记录成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceSn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['deviceTime']}
                            label="设备时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "设备时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['tsp']}
                            label="总悬浮微粒"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "总悬浮微粒不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['pm10']}
                            label="PM10"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "PM10不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['pm2_5']}
                            label="PM2.5"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "PM2.5不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['noise']}
                            label="噪音"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "噪音不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['temperature']}
                            label="温度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "温度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['humidity']}
                            label="湿度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "湿度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['windSpeed']}
                            label="风速"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "风速不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['windDirection']}
                            label="风向"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "风向不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['windDirectionAngle']}
                            label="风向角度"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "风向角度不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['sprayingEnabled']}
                            label="喷淋"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "喷淋不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['fogGunEnabled']}
                            label="雾炮"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "雾炮不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['createdTime']}
                            label="创建时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "创建时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default DustLogAddForm;