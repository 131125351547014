import _ from 'lodash';

import { Card, DrawerProps, Image, Tabs, Tag } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormRadio, ProFormSwitch, ProFormText, } from '@ant-design/pro-form';
import { Employee } from './../../type/EmployeeType';
import EmployeeApi from '../../api/EmployeeApi';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';
import DictItemSelector from '../../../system/pages/DictItem/DictItemSelector';
import EmployeeStatusDisplay from './EmployeeStatusDisplay';

import './index.less';
import GlobalConst from '../../../../utils/GlobalConst';
import EmployeeEntryPage from '../EmployeeEntry';
import EmployeeExitPage from '../EmployeeExit';
import { EmployeeAttendanceInfo } from '../../type/EmployeeAttendanceInfoType';
import EmployeeAttendanceInfoApi from '../../api/EmployeeAttendanceInfoApi';
import SingleFileSelector from '../../../file/component/SingleFileSelector';
import SingleImageSelector from '../../../file/component/SingleImageSelector';
import { ActionType } from '@ant-design/pro-table';

export interface EmployeeDetailProps extends DrawerProps {
    employee?: Employee,
    onFail:()=>void,
    onSuccess:()=>void,
};

const EmployeeDetail:React.FC<EmployeeDetailProps> = (props)=>{

    const {employee, onFail, onSuccess} = props;
    const [title, setTitle] = useState<string|undefined>(employee?.name);
    const attendanceInfoRef = useRef<ActionType>();

    return (
        <Drawer
            title={
                <div>
                    {title} &nbsp;&nbsp;
                    <EmployeeStatusDisplay 
                        value={employee?.workInfo?.status||""}
                    />&nbsp;&nbsp;
                    {
                        employee?.workInfo?.workRole==='10' &&
                        <Tag color={'#108ee9'}>
                            <DictItemDisplay
                                entryCode='JZ_MANAGER_POST_TYPE'
                                value={employee?.workInfo?.manageType}
                            />
                        </Tag>
                    }
                    {
                        employee?.workInfo?.workRole==='20' &&
                        <Tag color={'#108ee9'}>
                            <DictItemDisplay
                                entryCode='HR_WORK_TYPE'
                                value={employee?.workInfo?.workType}
                            />
                        </Tag>
                    }
                </div>
            }
            className="EmployeeDetail"
            width={960}
            closable={false}
            destroyOnClose={true}
            bodyStyle={{paddingTop:0}}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <Tabs className='tabs'>
                <Tabs.TabPane key={'basic'} tab="基本务工信息">
                    <div style={{width:'100%', height:'100%', overflow:'auto'}}>
                        <ProDescriptions<Employee>
                            column={6}
                            request={async ()=>{
                                const result:Employee = await EmployeeApi.findEmployeeById(employee?.id||"");
                                setTitle(result?.name);

                                return {
                                    success: true,
                                    data: result,
                                };
                            }}
                            editable={{
                                onSave:async (keyPath:any, newData, oldData)=>{
                                    const requestData:any = {
                                        id: oldData?.id
                                    };
                                    let rTemp = requestData;
                                    let nTemp = newData;

                                    for(let i=0; i<keyPath.length; i++){
                                        let field = keyPath[i];

                                        if(i===keyPath.length-1){
                                            rTemp[field] = nTemp[field];
                                        }else{
                                            rTemp[field] = {};
                                            rTemp = rTemp[field];
                                            nTemp = nTemp[field];
                                        }
                                    }

                                    await EmployeeApi.patchEditEmployee(requestData);
                                }
                            }}
                        >
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['jobNo']}
                                    label="工号"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['name']}
                                    label="姓名"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['sex']}
                                    label="性别"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='GB_SEX'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='GB_SEX' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['nation']}
                                    label="民族"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='GB_NATION'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='GB_NATION' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['region']}
                                    label="户籍地"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['birthday']}
                                    label="出生日期"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom?.toString()?.substring(0,10)}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormDatePicker />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['maritalStatus']}
                                    label="婚姻状态"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='GB_MARITAL_STATUS'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='GB_MARITAL_STATUS' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['politicalStatus']}
                                    label="政治面貌"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='GB_POLITICAL_STATUS'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='GB_POLITICAL_STATUS' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['firstDegree']}
                                    label="学历"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='HR_EDU_LEVEL'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='HR_EDU_LEVEL' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['idCardInfo', 'idCardType']}
                                    label="证件类型"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <DictItemDisplay 
                                                entryCode='HR_CER_TYPE'
                                                value={dom?.toString()||""}
                                            />
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <DictItemSelector entryCode='HR_CER_TYPE' />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['idCardInfo', 'idCardNo']}
                                    label="证件号码"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['idCardInfo', 'idCardIssuedBy']}
                                    label="发证机关"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['idCardInfo', 'idCardBeginDate']}
                                    label="有效期开始日期"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom?.toString()?.substring(0,10)}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormDatePicker />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['idCardInfo', 'idCardEndDate']}
                                    label="有效期结束日期"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {entity?.idCardInfo?.idCardNoExpirationFlag?"长期":dom?.toString()?.substring(0,10)}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormDatePicker />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={6}
                                    dataIndex={['idCardInfo', 'idCardAddress']}
                                    label="证件地址"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['idCardInfo', 'idCardAvatarImageUrl']}
                                    label="证件头像"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <Image
                                                src={entity?.idCardInfo?.idCardAvatarImageUrl?(GlobalConst.FILE_SERVER + entity?.idCardInfo?.idCardAvatarImageUrl):""}
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                                width={65}
                                                height={80}
                                            />
                                        );
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['idCardInfo', 'idCardFrontImageUrl']}
                                    label="证件正面"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <Image
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                                src={entity?.idCardInfo?.idCardFrontImageUrl?(GlobalConst.FILE_SERVER + entity?.idCardInfo?.idCardFrontImageUrl):""}
                                                width={120}
                                                height={80}
                                            />
                                        );
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['idCardInfo', 'idCardBackImageUrl']}
                                    label="证件反面"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <Image
                                                fallback={GlobalConst.EMPTY_IMAGE}
                                                src={entity?.idCardInfo?.idCardBackImageUrl?(GlobalConst.FILE_SERVER + entity?.idCardInfo?.idCardBackImageUrl):""}
                                                width={120}
                                                height={80}
                                            />
                                        );
                                    }}
                                />

                                <ProDescriptions.Item span={6}>
                                    <div style={{fontWeight:'bold', marginTop:16}}>务工信息</div>
                                </ProDescriptions.Item>

                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['syncToGov']}
                                    label="上报监管机构"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {entity.syncToGov?'上报':'不报'}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormSwitch />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['workInfo', 'workRole']}
                                    label="工人角色"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            entity?.workInfo?.workRole==="10"?"管理人员":"普通工人"
                                        );
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['org']}
                                    label="参建单位"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return entity?.org?.name;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['workTeam']}
                                    label="所属班组"
                                    valueType="text"
                                    copyable={false}
                                    editable={false}
                                    render={(dom, entity, index, action) => {
                                        return entity?.workTeam?.name;
                                    }}
                                />
                                {
                                    employee?.workInfo?.workRole==='20' &&
                                    <ProDescriptions.Item
                                        span={3}
                                        dataIndex={['workInfo', 'workType']}
                                        label="工种类型"
                                        valueType="text"
                                        copyable={false}
                                        editable={false}
                                        render={(dom, entity, index, action) => {
                                            return (
                                                <DictItemDisplay 
                                                    entryCode='HR_WORK_TYPE'
                                                    value={dom?.toString()||""}
                                                />
                                            );
                                        }}
                                    />
                                }
                                {
                                    employee?.workInfo?.workRole==='10' &&
                                    <ProDescriptions.Item
                                        span={3}
                                        dataIndex={['workInfo', 'manageType']}
                                        label="管理类型"
                                        valueType="text"
                                        copyable={false}
                                        editable={false}
                                        render={(dom, entity, index, action) => {
                                            return (
                                                <DictItemDisplay 
                                                    entryCode='JZ_MANAGER_POST_TYPE'
                                                    value={dom?.toString()||""}
                                                />
                                            );
                                        }}
                                    />
                                }
                                <ProDescriptions.Item
                                        span={3}
                                        dataIndex={['workInfo', 'entryTime']}
                                        label="入职日期"
                                        valueType="text"
                                        copyable={false}
                                        editable={false}
                                        render={(dom, entity, index, action) => {
                                            return (
                                                <div>
                                                    {dom}
                                                </div>
                                            );
                                        }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['workInfo', 'teamLeaderFlag']}
                                    label="班组长"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return entity?.workInfo?.teamLeaderFlag?"是":"否";
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormSwitch />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['workInfo', 'insuranceFlag']}
                                    label="保险购买"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return entity?.workInfo?.insuranceFlag?"已购买":"未购买";
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormSwitch />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={2}
                                    dataIndex={['workInfo', 'majorDiseaseHistoryFlag']}
                                    label="重疾病史"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return entity?.workInfo?.majorDiseaseHistoryFlag?"有":"无";
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormSwitch />;
                                    }}
                                />

                                <ProDescriptions.Item span={6}>
                                    <div style={{fontWeight:'bold', marginTop:16}}>联系信息</div>
                                </ProDescriptions.Item>
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'mobile']}
                                    label="手机全号"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'cornet']}
                                    label="手机短号"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'telephone']}
                                    label="固定电话"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'email']}
                                    label="电子邮箱"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'wechat']}
                                    label="微信号"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['contactInfo', 'qq']}
                                    label="QQ号"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />
                                <ProDescriptions.Item
                                    span={6}
                                    dataIndex={['contactInfo', 'liveAddress']}
                                    label="居住地址"
                                    valueType="text"
                                    copyable={false}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />

                                <ProDescriptions.Item span={6}>
                                </ProDescriptions.Item>
                        </ProDescriptions>
                    </div>
                </Tabs.TabPane>

                <Tabs.TabPane key={'attendanceInfo'} tab="考勤特征">
                <ProDescriptions<EmployeeAttendanceInfo>
                            actionRef={attendanceInfoRef}
                            column={6}
                            request={async ()=>{
                                const result:EmployeeAttendanceInfo = await EmployeeAttendanceInfoApi.findEmployeeAttendanceInfoById(employee?.id||"");
                               
                                return {
                                    success: true,
                                    data: result,
                                };
                            }}
                            editable={{
                                onSave:async (keyPath:any, newData, oldData)=>{
                                    const requestData:any = {
                                        id: oldData?.id
                                    };
                                    let rTemp = requestData;
                                    let nTemp = newData;

                                    for(let i=0; i<keyPath.length; i++){
                                        let field = keyPath[i];

                                        if(i===keyPath.length-1){
                                            rTemp[field] = nTemp[field];
                                        }else{
                                            rTemp[field] = {};
                                            rTemp = rTemp[field];
                                            nTemp = nTemp[field];
                                        }
                                    }

                                    if(requestData.faceImageUrl){
                                        requestData.faceImageUrl = requestData.faceImageUrl.fileUrl;
                                    }

                                    const attendanceInfo = await EmployeeAttendanceInfoApi.patchEditEmployeeAttendanceInfo(requestData);

                                    attendanceInfoRef.current?.reload();
                                }
                            }}
                        >

                            <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['icCardNo']}
                                    label="IC卡卡号"
                                    valueType="text"
                                    copyable={true}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                {dom}
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <ProFormText />;
                                    }}
                                />

                                <ProDescriptions.Item span={3}>

                                </ProDescriptions.Item>

                                <ProDescriptions.Item
                                    span={3}
                                    dataIndex={['faceImageUrl']}
                                    label="考勤照片"
                                    valueType="text"
                                    copyable={true}
                                    render={(dom, entity, index, action) => {
                                        return (
                                            <div>
                                                <Image 
                                                    width={120}
                                                    height={160}
                                                    src={entity.faceImageUrl?(GlobalConst.FILE_SERVER+entity.faceImageUrl):""}
                                                    fallback={GlobalConst.EMPTY_IMAGE}
                                                />
                                            </div>
                                        );
                                    }}
                                    renderFormItem={() => {
                                        return <SingleImageSelector 
                                            data={{
                                                rootFolder: 'check-image'
                                            }}
                                        />;
                                    }}
                                />
                    </ProDescriptions>
                </Tabs.TabPane>

                <Tabs.TabPane key={'employeeEntryExit'} tab="入离职信息">
                    <EmployeeEntryPage 
                        employeeId={employee?.id}
                    />
                    <EmployeeExitPage
                        employeeId={employee?.id}
                    />
                </Tabs.TabPane>
            </Tabs>

            
        </Drawer>
    );
};

export default EmployeeDetail;