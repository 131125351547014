
import P330800DirectDeviceInfo from "./pages/P330800DirectDeviceInfo";
import P330800DirectPersonInfo from "./pages/P330800DirectPersonInfo";

export default [{
    path: '/hr-sync/device-info',
    title: '浙里建设备',
    component: P330800DirectDeviceInfo
}, {
    path: '/hr-sync/person-info',
    title: '浙里建人员',
    component: P330800DirectPersonInfo
}, ]