
import {WorkTeam, WorkTeamQto} from './../type/WorkTeamType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addWorkTeam = async (params:WorkTeam)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/work-team`, params);
};


const editWorkTeam = async (params:WorkTeam)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/work-team/${params.id}`, params);
};


const patchEditWorkTeam = async (params:WorkTeam)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/work-team/${params.id}`, params);
};


const deleteWorkTeam = async (params:WorkTeam)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/work-team/${params.id}`, {});
};


const findWorkTeamById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/work-team/${params}`, {});
};

const findWorkTeamList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/work-team/list`, params);
};

const findWorkTeamPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/work-team/page`, params);
};



export default {
    addWorkTeam,
    editWorkTeam,
    patchEditWorkTeam,
    deleteWorkTeam,
    findWorkTeamById,
    findWorkTeamList,
    findWorkTeamPage,
};


