import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, } from '@ant-design/pro-form';
import { ProjectInfo } from './../../type/ProjectInfoType';
import ProjectInfoApi from '../../api/ProjectInfoApi';
import {useSelector, useDispatch} from 'dva';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';

export interface ProjectInfoDetailProps extends DrawerProps {
};

const ProjectInfoDetail:React.FC<ProjectInfoDetailProps> = (props)=>{


    const {staffPosition} = useSelector((state:any)=>(state['global']));
    
    const request = async ()=>{
        const result:ProjectInfo = await ProjectInfoApi.findProjectInfoById(staffPosition?.tenantId||"");
        
        return {
            success: true,
            data: result,
        };
    };


    return (
        <div style={{width:'100%', height:'100%', backgroundColor:'#ffffff'}}>
            <div style={{marginLeft:'auto', marginRight:'auto', width:1020, padding:16, backgroundColor:'#ffffff' }}>

                <ProDescriptions<ProjectInfo>
                    column={2}
                    request={request}
                    title={"项目信息"}
                >
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['name']}
                        label="工程名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['code']}
                        label="项目代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['areaCode']}
                        label="地区代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['status']}
                        label="工程状态"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    <DictItemDisplay 
                                        entryCode='PROJECT_STATUS'
                                        value={dom?.toString()||""}
                                    />
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['category']}
                        label="项目分类"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['approvalLevel']}
                        label="立项级别"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['projectScale']}
                        label="建设规模"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['projectNature']}
                        label="建设性质"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['projectPurpose']}
                        label="工程用途"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['buildingLicenseNo']}
                        label="施工许可证号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['landUsePlanNo']}
                        label="建设用地规划许可证编号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['projectPlanNo']}
                        label="建设工程规划许可证编号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item>
                        
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['address']}
                        label="工程地址"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                <ProDescriptions.Item span={2}>
                        <div style={{fontWeight:'bold', fontSize:16}}>参建单位信息</div>
                </ProDescriptions.Item>
                <ProDescriptions.Item
                        dataIndex={['contractor','primaryContractorCode']}
                        label="总承包单位统一社会信用代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['contractor','primaryContractorName']}
                        label="总承包单位名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['contractor','supervisoryContractorCode']}
                        label="监理单位统一社会信用代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['contractor','supervisoryContractorName']}
                        label="监理单位名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item span={2}>
                            <div style={{fontWeight:'bold', fontSize:16}}>联系人信息</div>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        dataIndex={['leader','pmName']}
                        label="挂证项目经理姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['leader','pmMobile']}
                        label="挂证项目经理电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['leader','realPmName']}
                        label="现场项目经理姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['leader','realPmMobile']}
                        label="现场项目经理电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['leader','hrmName']}
                        label="实名制专员姓名"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['leader','hrmMobile']}
                        label="实名制专员电话"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
            </ProDescriptions>

            </div>

        </div>
    );
};

export default ProjectInfoDetail;