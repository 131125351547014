import _ from 'lodash';

import { DrawerProps, Modal } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import {ProFormProps, ProFormRadio} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormSwitch, ProFormDigit, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';
import OrganizationSelector from "./../../../system/pages/Organization/OrganizationSelector";

import { AttendanceMachine } from './../../type/AttendanceMachineType';
import AttendanceMachineApi from '../../api/AttendanceMachineApi';
import { message } from 'antd';
import AttendanceMachineMetaInfoApi from '../../../iot/api/AttendanceMachineMetaInfoApi';

export interface AttendanceMachineAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const AttendanceMachineAddForm:React.FC<AttendanceMachineAddFormProps> = (props)=>{

    const [form] = Form.useForm<AttendanceMachine>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建考勤机"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  AttendanceMachine)=>{
                    const attendanceMachineList = await AttendanceMachineMetaInfoApi.findAttendanceMachineMetaInfoList({
                        sn: values?.sn||"undefined"
                    });

                    if(!attendanceMachineList || attendanceMachineList.length<1){
                        
                        Modal.error({
                            title: '错误提示',
                            content:"设备[" + values.sn + "]未注册，请联系平台先注册该设备！"
                        });
                        return false;
                    }
                    values.id=attendanceMachineList[0].id;

                    await AttendanceMachineApi.addAttendanceMachine(values);
                    form.resetFields();
                    message.success("新建考勤机成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
                initialValues={{
                    enabled: true,
                }}
            >
                <Row>
                    <Col span={12}>
                        <OrganizationSelector
                            name={['orgId']}
                            label="参建单位"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "参建单位不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormSwitch
                            name={['enabled']}
                            label="启用"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "启用不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="设备名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['sn']}
                            label="设备序列号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "设备序列号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormRadio.Group
                            name={['checkType']}
                            label="进出类型"
                            tooltip=""
                            placeholder=""
                            valueEnum={{
                                CHECK_IN: '进',
                                CHECK_OUT: '出',
                                CHECK_AUTO: '自动判定'
                            }}
                            rules={[{required:false, message: "进出类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['delaySecondsToOpenDoor']}
                            label="延时开门时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "延时开门时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['description']}
                            label="描述"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "描述不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default AttendanceMachineAddForm;