import React, {FC, useState, useRef, useEffect} from "react";
import { stringify } from 'qs';

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert, Radio, Tag, Checkbox} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { EmployeeAttendanceMonthReport, EmployeeAttendanceMonthReportQto } from './../../type/EmployeeAttendanceMonthReportType';

import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "./../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "./../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormDatePicker, ProFormInstance, ProFormRadio, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import EmployeeAttendanceReportApi from "../../api/EmployeeAttendanceReportApi";
import moment from "moment";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import AttendanceResultTypeDisplay from "../AttendanceLog/AttendanceResultTypeDisplay";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";
import DictItemSelector from "../../../system/pages/DictItem/DictItemSelector";
import AttendanceMachineSelector from "../AttendanceMachine/AttendanceMachineSelector";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const EmployeeAttendanceMonthReportPage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<ProFormInstance<EmployeeAttendanceMonthReportQto>>(null);

    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<EmployeeAttendanceMonthReport>>([]); //表格列
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态
    const [includeMobile, setIncludeMobile] = useState<boolean>(true);
    const [withAttendanceLogsFlag, setWithAttendanceLogsFlag] = useState<boolean>(false);
    let displayMode:string = 'sign';

    const defaultColumns:ColumnsType<EmployeeAttendanceMonthReport> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "employee",
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render:(val)=>{
                return val?.jobNo;
            }
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "employee",
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render:(val)=>{
                return val?.name;
            }
        },
        {
            key: "workTeam",
            title: "班组",
            dataIndex: "employee",
            width: 140,
            ellipsis: true,
            render:(val)=>{
                return val?.workTeam?.name;
            },
        },
        {
            key: "workType",
            title: "工种",
            dataIndex: "employee",
            width: 140,
            ellipsis: true,
            render:(val, record)=>{
                if('10'===val?.workInfo?.workRole){
                    return (
                        <DictItemDisplay
                            value={val?.workInfo?.manageType}
                            entryCode="JZ_MANAGER_POST_TYPE"
                        />
                    );
                }else{
                    return (
                        <DictItemDisplay
                            value={val?.workInfo?.workType}
                            entryCode="HR_WORK_TYPE"
                        />
                    );
                }
            }
        },
    ];

    for(let i=0; i<31; i++){
        defaultColumns.push({
            key: "day" + (i+1),
            title: "" + (i+1),
            dataIndex: "dayReportList",
            width: 50,
            render:(val)=>{
                if(displayMode==='sign'){
                    return (
                        <AttendanceResultTypeDisplay 
                            value={val[i]?.checkResult}
                        />
                    );
                }else{
                    return val[i]?.attendanceHours;
                }
            }
        });
    }

    defaultColumns.push({
        key: "attendanceDays",
        title: "出勤天数",
        dataIndex: "attendanceDays",
        width: 80,
        ellipsis: true,
    });
    defaultColumns.push({
        key: "availableAttendanceDays",
        title: "有效天数",
        dataIndex: "availableAttendanceDays",
        width: 80,
        ellipsis: true,
    });

    defaultColumns.push({
        key: "attendanceHours",
        title: "出工工时",
        dataIndex: "attendanceHours",
        width: 80,
        ellipsis: true,
    });
    defaultColumns.push({
        key: "availableAttendanceHours",
        title: "有效工时",
        dataIndex: "availableAttendanceHours",
        width: 80,
        ellipsis: true,
    });

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams:any = {
            ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current.getFieldsFormatValue() ||{}),
            ...(params||{}),
            excludeAttendanceMachineSn: includeMobile?null:'mobile',
            withAttendanceLogsFlag,
            deletedFlag: false,
        };
        setQueryParams(newQueryParams);

        if(withAttendanceLogsFlag){
            newQueryParams.attendanceTimeBegin = newQueryParams.reportMonth + "-01";
            newQueryParams.attendanceTimeEnd = moment(newQueryParams.reportMonth + '-01').endOf('month').format('YYYY-MM-DD HH:mm:ss');
        }

        EmployeeAttendanceReportApi.attendanceMonthReport(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='reportMonth' style={{display:'inline-block', height:40, width:180}}>
            <ProFormDatePicker.Month
                label="统计月份"
                name="reportMonth"
            />
        </div>,
        <div key='orgId' style={{display:'inline-block', height:40, width:260}}>
            <OrganizationSelector
                label="参建单位"
                name="orgId"
            />
        </div>,
        <div key='workTeamId' style={{display:'inline-block', height:40, width:260}}>
            <WorkTeamSelector
                label="所属班组"
                name="workTeamId"
            />
        </div>,
        <div key='nameLike' style={{display:'inline-block', height:40, width:260}}>
            <ProFormText
                label="姓名"
                name="nameLike"
            />
        </div>,
        <div key='workRole' style={{display:'inline-block', height:40, width:160}}>
            <ProFormSelect
                label="人员角色"
                name="workRole"
                valueEnum={{
                    10: '管理人员',
                    20: '普通工人',
                }}
            />
        </div>,
        <div key='workType' style={{display:'inline-block', height:40, width:260}}>
            <DictItemSelector
                label="工种"
                name="workType"
                entryCode="HR_WORK_TYPE"
            />
        </div>,
        <div key='manageType' style={{display:'inline-block', height:40, width:260}}>
            <DictItemSelector
                label="管理类型"
                name="manageType"
                entryCode="JZ_MANAGER_POST_TYPE"
            />
        </div>,
        <div key='status' style={{display:'inline-block', height:40, width:260}}>
            <ProFormSelect
                label="人员状态"
                name="status"
                valueEnum={{
                    EMPLOYMENT: '在职',
                    RESIGNED: '离职',
                    TEMP_RESIGNED: '临时离职'
                }}
            />
        </div>,
        <div key='attendanceMachineSn' style={{display:'inline-block', height:40, width:220}}>
            <AttendanceMachineSelector
                name={['attendanceMachineSn']}
                label="考勤机"
                tooltip=""
                placeholder=""
            />
        </div>
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <ProForm<EmployeeAttendanceMonthReportQto>
                    formRef={queryFormRef}
                    submitter={false}
                    layout="horizontal"
                    initialValues={{
                        reportMonth: moment().format("YYYY-MM")
                    }}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </ProForm>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    人员考勤月统计&nbsp;&nbsp;&nbsp;
                    <Space>
                        <Tag color="green">正常：√</Tag>
                        <Tag color="orange">半日：※</Tag>
                        <Tag color="red">缺卡：⊙</Tag>
                        <Tag color="gray">缺勤：X</Tag>
                    </Space>
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        <Checkbox
                            checked={includeMobile}
                            onChange={(e)=>{
                                setIncludeMobile(e.target.checked);
                            }}
                        >
                            包含手机考勤
                        </Checkbox>
                        <Checkbox
                            checked={withAttendanceLogsFlag}
                            onChange={(e)=>{
                                setWithAttendanceLogsFlag(e.target.checked);
                            }}
                        >
                            只看有考勤人员
                        </Checkbox>
                        <Radio.Group
                            defaultValue={'sign'}
                            onChange={(val)=>{
                                displayMode = val?.target?.value;
                                setColumns(defaultColumns);
                            }}
                        >
                            <Radio value="sign">符号</Radio>
                            <Radio value="hours">工时</Radio>
                        </Radio.Group>
                        
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        const newQueryParams:any = {
                                            ...(queryFormRef.current?.getFieldsFormatValue && queryFormRef.current.getFieldsFormatValue() ||{}),
                                            includeMobile: includeMobile,
                                            deletedFlag: false,
                                            withAttendanceLogsFlag,
                                            Authorization: localStorage.getItem('Authorization')||"",
                                        };

                                        if(withAttendanceLogsFlag){
                                            newQueryParams.attendanceTimeBegin = newQueryParams.reportMonth + "-01";
                                            newQueryParams.attendanceTimeEnd = moment(newQueryParams.reportMonth + '-01').endOf('month').format('YYYY-MM-DD HH:mm:ss');
                                        }

                                        if(key==='batchExportSimple'){
                                            window.open(`/api/tenant/hr/employee-attendance-report/employee-month-report-by-work-team-simple/excel?${stringify(newQueryParams)}`);
                                        } else if(key==='batchExportHours'){
                                           
                                            window.open(`/api/tenant/hr/employee-attendance-report/employee-month-report-by-work-team-hours/excel?${stringify(newQueryParams)}`);
                                        } else if(key==='batchExportHwxldHours'){
                                           
                                            window.open(`/api/tenant/hr/employee-attendance-report/employee-month-report-all-hours/excel?${stringify(newQueryParams)}`);
                                        } else if(key==='batchExportAvaliableLogs'){
                                           

                                            window.open(`/api/tenant/hr/employee-attendance-report/employee-month-report-available-check-time/excel?${stringify(newQueryParams)}`);
                                        } else if(key==='batchExportAllLogs'){
                                           
                                            window.open(`/api/tenant/hr/employee-attendance-report/employee-month-report-check-time-details/excel?${stringify(newQueryParams)}`);
                                        }
                                    }}
                                    items={[{
                                        key: 'batchExportSimple',
                                        label: '导出简易符号版',
                                    }, {
                                        key: 'batchExportHours',
                                        label: '导出工时版',
                                    }, {
                                        key: 'batchExportHwxldHours',
                                        label: '导出工时版（华卫薪乐达）',
                                    }, {
                                        key: 'batchExportAvaliableLogs',
                                        label: '导出有效考勤时间',
                                    }, {
                                        key: 'batchExportAllLogs',
                                        label: '导出所有考勤明细',
                                    }, ]}
                               />
                            }
                        >
                            Excel导出
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            bordered
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default EmployeeAttendanceMonthReportPage;