import { Tag } from "antd";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";

const ContractorTypeDisplay = (props:{type:string})=>{

    const {type} = props;

    const colors:any = {
        '008': "#f50",
        '009': "#2db7f5"
    };

    return (
        <Tag
            color={colors[type]}
        >
            <DictItemDisplay
                entryCode="PROJECT_CORP_TYPE"
                value={type}
            />
        </Tag>
    );
};

export default ContractorTypeDisplay;