import React, {FC, useState, useRef, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert} from 'antd';

import {DownOutlined, RedoOutlined, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';

import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "./../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "./../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormText } from "@ant-design/pro-form";
import { EmployeeEntryExitDayReport } from "./../../type/EmployeeEntryExitDayReportType";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const EmployeeEntryExitDayReportPage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);

    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<EmployeeEntryExitDayReport>>([]); //表格列
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态

    const defaultColumns:ColumnsType<EmployeeEntryExitDayReport> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "orgName",
            title: "参建单位",
            dataIndex: "org",
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "workTeamName",
            title: "班组",
            dataIndex: "workTeam",
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "workTypeName",
            title: "工种",
            dataIndex: "workType",
            width: 120,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "employees",
            title: "在场人数",
            dataIndex: "employeesQuantity",
            width: 140,
            ellipsis: true,
        },
        {
            key: "employeeEntryQuantity",
            title: "进场人数",
            dataIndex: "employeeEntryQuantity",
            width: 140,
            ellipsis: true,
        },
        {
            key: "employeeExitQuantity",
            title: "离场人数",
            dataIndex: "employeeExitQuantity",
            width: 140,
            ellipsis: true,
        }
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
        };
        setQueryParams(newQueryParams);
        setSpinning(false);

        /**
        EmployeeEntryExitDayReportApi.findEmployeeEntryExitDayReportPage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
         */
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='qf1' style={{display:'inline-block', height:40}}>
            <ProFormText
                label="设备名称"
                name="name"
                style={{width:260}}
            />
        </div>,
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        setQueryParams({
                            ...queryParams,
                            ...values,
                        })
                    }}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    参建单位考勤日统计
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        if(key==='batchDelete'){
                                            
                                        }
                                    }}
                                    items={[{
                                        key: 'batchDelete',
                                        label: '导出',
                                    }]}
                               />
                            }
                        >
                            Excel导出
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                        />
                    </Spin>
                </div>
            </div>
        </div>
    );
};

export default EmployeeEntryExitDayReportPage;