
import {ProjectInfo, ProjectInfoQto} from './../type/ProjectInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addProjectInfo = async (params:ProjectInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/project-info`, params);
};


const editProjectInfo = async (params:ProjectInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/project-info/${params.id}`, params);
};


const patchEditProjectInfo = async (params:ProjectInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/project-info/${params.id}`, params);
};


const deleteProjectInfo = async (params:ProjectInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/project-info/${params.id}`, params);
};


const findProjectInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/project-info/${params}`, params);
};

const findProjectInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/project-info/list`, params);
};

const findProjectInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/project-info/page`, params);
};



export default {
    addProjectInfo,
    editProjectInfo,
    patchEditProjectInfo,
    deleteProjectInfo,
    findProjectInfoById,
    findProjectInfoList,
    findProjectInfoPage,
};


