import {BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import dva from 'dva';
import * as history from 'history';
import 'moment/locale/zh-cn';

import './index.less';
import { notification } from 'antd';

if(window.location.search.length>1){
  const params = window.location.search.substring(1).split('&');

  for(let i=0; i<params.length; i++){
    if(params[i].startsWith('Authorization')){
      localStorage.setItem("Authorization", params[i].substring(14));
      break;
    }
  }
}

declare global {
  interface Window {
      store: any,
  }
}

// 1. Initialize
const app:any = dva({
  history: history.createHashHistory(),
  onError:(error:any)=>{
    
  }
});

// 2. Plugins

// 3. Register global model
app.model(require('./modules/main/models/global').default);

// 4. Router
app.router(require('./RouteConfig').default);

// 5. Start
app.start('#root');

window.store = app._store; 

export default app._store; // eslint-disable-line
