import React from 'react';
import {useNavigate} from 'react-router-dom';
import {useSelector, useDispatch} from 'dva';

import { Button, Result } from 'antd';

const NoFoundPage: React.FC = () => {

    const navigate = useNavigate();
    const {routeList} = useSelector((state:any)=>(state['global']));
    const dispatch = useDispatch();

    return <Result
        status="404"
        title="404"
        subTitle="对不起，你访问的页面不存在。"
        extra={
        <Button 
            type="primary" 
            onClick={() => {
                navigate("/home");
            }}
        >
            返回工作台
        </Button>
        }
    />
};

export default NoFoundPage;