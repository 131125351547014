import { BankOutlined, IdcardOutlined, SolutionOutlined, UsergroupAddOutlined, VideoCameraOutlined } from "@ant-design/icons";
import { Card, Col, Descriptions, Image, Row, Statistic } from "antd";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import {useSelector, useDispatch} from 'dva';
import {Column, ColumnOptions} from '@antv/g2plot';

import './Dashboard.less';
import WorkTeamAttendanceReportApi from "../../../hr/api/WorkTeamAttendanceReportApi";
import moment from "moment";
import TenantAttendanceReportApi from "../../../hr/api/TenantAttendanceReportApi";
import GlobalConst from "../../../../utils/GlobalConst";

const Dashboard = ()=>{

    const navigate = useNavigate();

    const {projectInfo} = useSelector((state:any)=>(state['global']));
    const [tenantAttendanceReport, setTenantAttendanceReport] = useState<any>({});
    
    useEffect(()=>{
        WorkTeamAttendanceReportApi.attendanceDayReportBarChart({
            reportDate: moment().format("YYYY-MM-DD")
        }).then(barChart=>{

            const data:any = {
                data: barChart.data,
                xField: "workTeamName",
                yField: "attendanceQuantity",
                minColumnWidth: 20,
                maxColumnWidth: 20,
            };

            const column = new Column("homedashboardWorkTeamReport", data);

            column.render();
        });

        TenantAttendanceReportApi.attendanceMonthReport({
            reportMonth: moment().format("YYYY-MM")
        }).then(result=>{

            result.list[0]?.dayItems.forEach((i:any)=>{
                i.recordDate = i.recordDate?.substring(8, 10);
            })

            const data:any = {
                data: result.list[0]?.dayItems,
                xField: "recordDate",
                yField: "attendanceQuantity",
            };

            const column = new Column("homedashboardTenantReport", data);

            column.render();
        });

        TenantAttendanceReportApi.attendanceDayReport({
            reportDate: moment().format("YYYY-MM-DD"),
        }).then((result)=>{
            setTenantAttendanceReport(result?.list[0]);
        });
    }, []);

    return (
        <Row gutter={16} style={{width:'100%', height:'100%'}} className="dashboard">
            <Col span={8}>
                <Card title="项目信息">
                    <Descriptions column={1}>
                        <Descriptions.Item label="项目代码">
                            {projectInfo?.code}
                        </Descriptions.Item>
                        <Descriptions.Item label="项目名称">
                            {projectInfo?.name}
                        </Descriptions.Item>
                        <Descriptions.Item label="施工单位">
                            {projectInfo?.contractor?.primaryContractorName}
                        </Descriptions.Item>
                        <Descriptions.Item label="监理单位">
                            {projectInfo?.contractor?.supervisoryContractorName}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card title="常用功能" style={{marginTop:16}}>
                    <div style={{color: '#2791fe'}}>
                        <div 
                            className="frequencyButton"
                            onClick={()=>{
                                navigate("/hr/contractor");
                            }}
                        >
                            <div>
                                <BankOutlined style={{fontSize:24}} />
                            </div>
                            <div>参建单位</div>
                        </div>

                        <div 
                            className="frequencyButton"
                            onClick={()=>{
                                navigate("/hr/work-team");
                            }}
                        >
                            <div>
                                <UsergroupAddOutlined style={{fontSize:24}} />
                            </div>
                            <div>班组信息</div>
                        </div>

                        <div 
                            className="frequencyButton"
                            onClick={()=>{
                                navigate("/hr/employee");
                            }}
                        >
                            <div>
                                <IdcardOutlined style={{fontSize:24}} />
                            </div>
                            <div>工人信息</div>
                        </div>

                        <div 
                            className="frequencyButton"
                            onClick={()=>{
                                navigate("/hr/contract");
                            }}
                        >
                            <div>
                                <SolutionOutlined style={{fontSize:24}} />
                            </div>
                            <div>人事合同</div>
                        </div>

                        <div 
                            className="frequencyButton"
                            onClick={()=>{
                                navigate("/hr/attendance-machine");
                            }}
                        >
                            <div>
                                <VideoCameraOutlined style={{fontSize:24}} />
                            </div>
                            <div>考勤机</div>
                        </div>
                    </div>
                </Card>

                <Card title="实时考勤状况" style={{marginTop:16}}>
                    <div style={{textAlign:'center'}}>
                        <Image
                            style={{marginLeft:'auto', marginRight:'auto'}}
                            width={120}
                            height={160}
                            src="error"
                            fallback={GlobalConst.EMPTY_IMAGE}
                        />
                    </div>
                    <div style={{textAlign:'center', lineHeight:'28px', fontSize:14}}>
                        打卡时间：12:00:00(进)<br/>
                        班组：项目部<br/>
                        工种类型：项目经理<br/>
                    </div>
                </Card>
            </Col>

            <Col span={16}>
                <Card>
                    <Row gutter={16} >
                        <Col span={12}>
                            <Statistic 
                                title={<div style={{color:'#fff'}}>当前在场</div>}
                                value={tenantAttendanceReport?.workingQuantity}
                                valueStyle={{ color: '#fff' }}
                                suffix="人"
                                style={{backgroundColor:'#54ade8', color:'#fff', padding:8}}
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic 
                                title={<div style={{color:'#fff'}}>今日出勤</div>}
                                value={tenantAttendanceReport?.attendanceQuantity}
                                valueStyle={{ color: '#fff' }}
                                suffix="人"
                                style={{backgroundColor:'#ff6c60', color:'#fff', padding:8}}
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic 
                                title={<div style={{color:'#fff'}}>当前在职</div>}
                                value={tenantAttendanceReport?.employmentQuantity}
                                valueStyle={{ color: '#fff' }}
                                suffix="人"
                                style={{backgroundColor:'#4dccb3', color:'#fff', padding:8, marginTop:16}}
                            />
                        </Col>
                        <Col span={12}>
                            <Statistic 
                                title={<div style={{color:'#fff'}}>临时离场</div>}
                                value={tenantAttendanceReport?.tempExitQuantity}
                                valueStyle={{ color: '#fff' }}
                                suffix="人"
                                style={{backgroundColor:'#6385a7', color:'#fff', padding:8, marginTop:16}}
                            />
                        </Col>
                    </Row>
                </Card>

                <Card title="班组今日出勤统计" style={{marginTop:16}}>
                    <div style={{height:280}} id={'homedashboardWorkTeamReport'}>
                        
                    </div>
                </Card>

                <Card title="本月考勤状况" style={{marginTop:16}}>
                    <div style={{height:280}} id={'homedashboardTenantReport'}>

                    </div>
                </Card>
            </Col>
        </Row>
    );
}

export default Dashboard;