import _ from 'lodash';

import { Divider, DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDatePicker, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';
import DictItemSelector from "./../../../system/pages/DictItem/DictItemSelector";

import { Contractor } from './../../type/ContractorType';
import ContractorApi from '../../api/ContractorApi';
import { message } from 'antd';

export interface ContractorAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const ContractorAddForm:React.FC<ContractorAddFormProps> = (props)=>{

    const [form] = Form.useForm<Contractor>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建参建单位"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Contractor)=>{
                    await ContractorApi.addContractor(values);
                    form.resetFields();
                    message.success("新建参建单位成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['uniCreditCode']}
                            label="社会统一信用代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "社会统一信用代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="单位名称"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "单位名称不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <DictItemSelector
                            entryCode='PROJECT_CORP_TYPE'
                            name={['type']}
                            label="参见类型"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "参见类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['regulatoryAgenciesAreaCode']}
                            label="管理地区代码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "管理地区代码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['registeredAddress']}
                            label="注册地址"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "注册地址不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['regulatoryAgenciesAddress']}
                            label="管理部门地址"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "管理部门地址不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation='left' style={{fontWeight:'bold'}}>法人信息</Divider>
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['legalMan']}
                            label="法人姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "法人姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <DictItemSelector
                            entryCode='HR_CER_TYPE'
                            name={['legalManIdCardType']}
                            label="法人证件类型"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "法人证件类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['legalManIdCardNo']}
                            label="法人证件号码"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "法人证件号码不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['legalManMobile']}
                            label="法人电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "法人电话不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation='left' style={{fontWeight:'bold'}}>联系人信息</Divider>
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['linkmanName']}
                            label="联系人姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "联系人姓名不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['linkmanMobile']}
                            label="联系人电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "联系人电话不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['linkmanCornet']}
                            label="联系人短号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "联系人短号不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['hrmName']}
                            label="劳务专员姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "劳务专员姓名不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['hrmMobile']}
                            label="劳务专员电话"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "劳务专员电话不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={8}>
                        <ProFormText
                            name={['hrmCornet']}
                            label="劳务专员短号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "劳务专员短号不能为空"}]}
                            {...FormLayout.itemLayout.span8}
                        />
                    </Col>
                    <Col span={24}>
                        <Divider orientation='left' style={{fontWeight:'bold'}}>进离场信息</Divider>
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['entryDate']}
                            label="进场日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "进场日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDatePicker
                            name={['exitDate']}
                            label="离场日期"
                            tooltip=""
                            placeholder=""
                            rules={[{required:false, message: "离场日期不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default ContractorAddForm;