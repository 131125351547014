import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { P330800DirectPersonInfo } from './../../type/P330800DirectPersonInfoType';
import P330800DirectPersonInfoApi from '../../api/P330800DirectPersonInfoApi';
import { message } from 'antd';

export interface P330800DirectPersonInfoAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const P330800DirectPersonInfoAddForm:React.FC<P330800DirectPersonInfoAddFormProps> = (props)=>{

    const [form] = Form.useForm<P330800DirectPersonInfo>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建浙里建设备信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  P330800DirectPersonInfo)=>{
                    await P330800DirectPersonInfoApi.addP330800DirectPersonInfo(values);
                    form.resetFields();
                    message.success("新建浙里建设备信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['personId']}
                            label="租户ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "租户ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="姓名"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "姓名不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['idCardNo']}
                            label="身份证号"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "身份证号不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['headImageUrl']}
                            label="考勤头像URL"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "考勤头像URL不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default P330800DirectPersonInfoAddForm;