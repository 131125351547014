import _ from 'lodash';

import { DrawerProps, Modal } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Space, Row, Col, Form, } from 'antd';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { EmployeeEntry } from './../../type/EmployeeEntryType';
import EmployeeEntryApi from '../../api/EmployeeEntryApi';
import { message } from 'antd';

export interface EmployeeEntryAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const EmployeeEntryAddForm:React.FC<EmployeeEntryAddFormProps> = (props)=>{

    const [form] = Form.useForm<EmployeeEntry>();
    const {onFail, onSuccess} = props;

    return (
        <Modal
            title="新建人员入职信息"
            destroyOnClose={false}
            closable={true}
            width={960}
            footer={null}
            onCancel={()=>{
                onFail();
            }}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  EmployeeEntry)=>{
                    await EmployeeEntryApi.addEmployeeEntry(values);
                    form.resetFields();
                    message.success("新建人员入职信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    {
                        <Col span={12}>
                            <ProFormText
                                name={['workRole']}
                                label="工人角色"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "工人角色不能为空"}]}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                    }
                    <Col span={12}>
                        <ProFormText
                            name={['workType']}
                            label="工种类型"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "工种类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['manageType']}
                            label="管理类型"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "管理类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['workTeamId']}
                            label="班组ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "班组ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['entryTime']}
                            label="入职时间"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "入职时间不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['entryComment']}
                            label="入职说明"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "入职说明不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Modal>
    );
};

export default EmployeeEntryAddForm;