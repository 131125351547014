import React from "react";
import zhCN from 'antd/lib/locale-provider/zh_CN';
import history, {History}  from 'history';
import {BrowserRouter as Router, Route, Routes, useLocation, useNavigate} from 'react-router-dom';
import { ReactReduxContextValue } from 'react-redux';
import {routerRedux} from 'dva/router';
import { ConfigProvider } from "antd";
import LoginPage from "./modules/auth/pages/login/LoginPage";
import HomePage from "./modules/main/pages/home/HomePage";

const {ConnectedRouter} = routerRedux;

export default ({history, app}:{
    history: History,
    app: any,
})=>{
    
    if(window.location.pathname==""||window.location.pathname=="/"){
        window.location.href = window.location.origin + "/login";
        
        return;
    }
  
    return (
        <ConfigProvider
            locale={zhCN}
            componentSize={'middle'}
            autoInsertSpaceInButton={true}
        >
            <Router>
                <Routes>
                    <Route path="/login" element={<LoginPage />} />
                    <Route  path="/*" element={<HomePage />} />
                </Routes>
            </Router>
        </ConfigProvider>
    );
}