import { Tag } from "antd";

const EmployeeWorkingStatusDisplay:React.FC<{value:string}> = (props)=>{

    let text = "-";
    let color = "";

    switch(props.value){
        case 'WORKING':
            text="工作中";
            color="#87d068";
            break;
        case 'NOT_WORKING':
            text="未工作";
            color="#f50";
            break;
    }

    return (
        <Tag color={color}>
            {text}
        </Tag>
    );
}

export default EmployeeWorkingStatusDisplay;