import _ from 'lodash';

import { Descriptions, DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormDigit, ProFormRadio, ProFormText, } from '@ant-design/pro-form';
import { LaborContract } from './../../type/LaborContractType';
import LaborContractApi from '../../api/LaborContractApi';
import { Employee } from '../../type/EmployeeType';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';

export interface LaborContractDetailProps extends DrawerProps {

    employee?: Employee,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const LaborContractDetail:React.FC<LaborContractDetailProps> = (props)=>{

    const {employee, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(employee?.name);
    const [laborContract, setLaborContract] = useState<LaborContract>({});

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <Descriptions
                title={"人员基本信息"}
                column={3}
            >
                <Descriptions.Item
                    label="工号"
                >
                    {employee?.jobNo}
                </Descriptions.Item>
                <Descriptions.Item
                    label="姓名"
                >
                    {employee?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="手机号码"
                >
                    {employee?.contactInfo?.mobile}
                </Descriptions.Item>
                <Descriptions.Item
                    label="证件类型"
                >
                   <DictItemDisplay 
                        entryCode='HR_CER_TYPE'
                        value={employee?.idCardInfo?.idCardType}
                   />
                </Descriptions.Item>
                <Descriptions.Item
                    label="证件号码"
                >
                    {employee?.idCardInfo.idCardNo}
                </Descriptions.Item>
                <Descriptions.Item
                    label="工人类型"
                >
                    {employee?.workInfo.workRole=='10'?'管理人员':'建筑工人'}
                </Descriptions.Item>
                <Descriptions.Item
                    label="参建单位"
                >
                    {employee?.org?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="所属班组"
                >
                    {employee?.workTeam?.name}
                </Descriptions.Item>
                <Descriptions.Item
                    label="工种类型"
                >
                    {
                        employee?.workInfo.workRole=='10'?
                        <DictItemDisplay 
                            entryCode='JZ_MANAGER_POST_TYPE'
                            value={employee?.workInfo?.manageType}
                        /> : 
                        <DictItemDisplay 
                            entryCode='HR_WORK_TYPE'
                            value={employee?.workInfo?.workType}
                        /> 
                    }
                </Descriptions.Item>
            </Descriptions>

            <ProDescriptions<LaborContract>
                title={'合同内容'}
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:LaborContract = await LaborContractApi.findLaborContractById(employee?.laborContract?.laborContractId||"");

                    setLaborContract(result);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await LaborContractApi.patchEditLaborContract(requestData);
                        actionRef?.current?.reload();
                        onSuccess();
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['contractDate']}
                        label="签订日期"
                        valueType="text"
                        copyable={false}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom?.toString().substring(0,10)}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker />;
                        }}
                    />

                    <ProDescriptions.Item
                        dataIndex={['periodType']}
                        label="签订类型"
                        valueType="text"
                        copyable={false}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            switch(dom?.toString()){
                                case 'FIXED_RANGE':
                                    return "定期合同";
                                case 'WORK_COMPLETED':
                                    return "定量合同";
                            }
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker />;
                        }}
                    />

                    {
                        laborContract?.periodType=='FIXED_RANGE' &&
                        <ProDescriptions.Item
                            dataIndex={['beginDate']}
                            label="开始日期"
                            valueType="text"
                            copyable={false}
                            render={(dom, entity, index, action) => {
                                return dom?.toString().substring(0,10);
                            }}
                            renderFormItem={() => {
                                return <ProFormDatePicker />;
                            }}
                        />
                    }

                    {
                        laborContract?.periodType=='FIXED_RANGE' &&
                        <ProDescriptions.Item
                            dataIndex={['endDate']}
                            label="结束日期"
                            valueType="text"
                            copyable={false}
                            render={(dom, entity, index, action) => {
                                return dom?.toString().substring(0,10);
                            }}
                            renderFormItem={() => {
                                return <ProFormDatePicker />;
                            }}
                        />
                    }

                    {
                        laborContract?.periodType=='WORK_COMPLETED' &&
                        <ProDescriptions.Item
                            dataIndex={['workload']}
                            label="总工作量"
                            valueType="text"
                            copyable={false}
                            render={(dom, entity, index, action) => {
                                return <div>{dom}</div>;
                            }}
                            renderFormItem={() => {
                                return <ProFormDigit />;
                            }}
                        />
                    }

                    {
                        laborContract?.periodType=='WORK_COMPLETED' &&
                        <ProDescriptions.Item
                            dataIndex={['measureUnit']}
                            label="计量单位"
                            valueType="text"
                            copyable={false}
                            render={(dom, entity, index, action) => {
                                switch(dom?.toString()){
                                    case 'METRE':
                                        return "米";
                                    case 'SQUARE_METRE':
                                        return "平方米";
                                    case 'CUBIC_METRE':
                                        return "立方米";
                                }
                            }}
                            renderFormItem={() => {
                                return (
                                    <ProFormRadio.Group
                                        name={['measureUnit']}
                                        tooltip=""
                                        placeholder=""
                                        rules={[{required:true, message: "计量单位不能为空"}]}
                                        options={[{
                                            label: '米',
                                            value: 'METRE'
                                        }, {
                                            label: '平方米',
                                            value: 'SQUARE_METRE',
                                        }, {
                                            label: '立方米',
                                            value: 'CUBIC_METRE',
                                        }]}
                                    />
                                );
                            }}
                        />
                    }

                        <ProDescriptions.Item
                            dataIndex={['wageType']}
                            label="结算方式"
                            valueType="text"
                            copyable={false}
                            render={(dom, entity, index, action) => {
                                switch(dom?.toString()){
                                    case 'DAILY':
                                        return "日结";
                                    case 'MONTHLY':
                                        return "月结";
                                    case 'UNIT':
                                        return "计量";
                                }
                            }}
                            renderFormItem={() => {
                                return <ProFormRadio.Group
                                        name={['wageType']}
                                        tooltip=""
                                        placeholder=""
                                        options={[{
                                            label: '日结',
                                            value: 'DAILY'
                                        }, {
                                            label: '月结',
                                            value: 'MONTHLY',
                                        }, {
                                            label: '计量',
                                            value: 'UNIT',
                                        }]}
                                    />
                                }}
                        />

                    <ProDescriptions.Item
                        dataIndex={['unitPrice']}
                        label="结算单价"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />

                    <ProDescriptions.Item
                        dataIndex={['salaryDate']}
                        label="发薪日"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default LaborContractDetail;