
import {Person, PersonQto} from './../type/PersonType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addPerson = async (params:Person)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/person`, params);
};


const editPerson = async (params:Person)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/person/${params.id}`, params);
};


const patchEditPerson = async (params:Person)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/person/${params.id}`, params);
};


const deletePerson = async (params:Person)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/person/${params.id}`, params);
};


const findPersonById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/person/${params}`, params);
};

const findPersonList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/person/list`, params);
};

const findPersonPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/person/page`, params);
};

const findPersonByMobile = async (mobile:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/person/mobile_${mobile}`, {});
};



export default {
    addPerson,
    editPerson,
    patchEditPerson,
    deletePerson,
    findPersonById,
    findPersonList,
    findPersonPage,
    findPersonByMobile,
};


