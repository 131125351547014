import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import StaffApi from "../../api/StaffApi";
import type { Staff } from "../../type/StaffType";
import { ProFormSelect } from "@ant-design/pro-form";
import { ProFormSelectProps } from "@ant-design/pro-form/es/components/Select";

const StaffSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        StaffApi.findStaffList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((staff:Staff)=>{
                return {
                    key: staff.id,
                    value: staff.id,
                    label: staff.name,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default StaffSelector;