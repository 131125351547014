
import {DustLog, DustLogQto} from './../type/DustLogType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addDustLog = async (params:DustLog)=>{

    return HttpInvoker.postObject(`/api/tenant/jz/dust-log`, params);
};


const editDustLog = async (params:DustLog)=>{

    return HttpInvoker.putObject(`/api/tenant/jz/dust-log/${params.id}`, params);
};


const patchEditDustLog = async (params:DustLog)=>{

    return HttpInvoker.patchObject(`/api/tenant/jz/dust-log/${params.id}`, params);
};


const deleteDustLog = async (params:DustLog)=>{

    return HttpInvoker.deleteObject(`/api/tenant/jz/dust-log/${params.id}`, params);
};


const findDustLogById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-log/${params}`, params);
};

const findDustLogList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-log/list`, params);
};

const findDustLogPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/jz/dust-log/page`, params);
};



export default {
    addDustLog,
    editDustLog,
    patchEditDustLog,
    deleteDustLog,
    findDustLogById,
    findDustLogList,
    findDustLogPage,
};


