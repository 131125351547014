import HttpInvoker from "../../../utils/http/HttpInvoker";


const attendanceDayReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/employee-attendance-report/employee-attendance-day-report`, params);
};

const attendanceMonthReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/employee-attendance-report/employee-attendance-month-report`, params);
};

export default {
    attendanceDayReport,
    attendanceMonthReport,
}