import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import type {ProFormProps} from '@ant-design/pro-form';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDigit, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';
import PostSelector from "./../../../system/pages/Post/PostSelector";

import { Post } from './../../type/PostType';
import PostApi from '../../api/PostApi';
import { message } from 'antd';

export interface PostAddFormProps extends DrawerProps {
    onSuccess:()=>void,
    onFail:()=>void,
};


const PostAddForm:React.FC<PostAddFormProps> = (props)=>{

    const [form] = Form.useForm<Post>();
    const {onFail} = props;

    return (
        <Drawer
            title="新建职务"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Post)=>{
                    await PostApi.addPost(values);
                    form.resetFields();
                    message.success("新建职务成功！");
                    onFail();
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                        <Col span={12}>
                            <PostSelector
                                name={['parentId']}
                                label="上级职务ID"
                                tooltip=""
                                placeholder=""
                                required={false}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['code']}
                                label="职务编码"
                                tooltip=""
                                placeholder=""
                                required={true}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['name']}
                                label="职务名称"
                                tooltip=""
                                placeholder=""
                                required={true}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormText
                                name={['nameAbbr']}
                                label="职务简称"
                                tooltip=""
                                placeholder=""
                                required={false}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                        <Col span={12}>
                            <ProFormDigit
                                name={['sortNo']}
                                label="显示顺序"
                                tooltip=""
                                placeholder=""
                                required={false}
                                {...FormLayout.itemLayout.span12}
                            />
                        </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

    export default PostAddForm;