import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select, TreeSelect } from "antd";
import PostApi from "../../api/PostApi";
import type { Post } from "../../type/PostType";
import { ProFormSelect, ProFormTreeSelect } from "@ant-design/pro-form";

const {TreeNode} = TreeSelect;

const PostSelector:React.FC<any> = (props)=>{


    return (
        <ProFormTreeSelect
            request={async ()=>{
                const treeList = await PostApi.findPostTree({
                    deletedFlag: false,
                });

                return treeList;
            }}
            fieldProps={{
                fieldNames:{
                    value: 'id',
                    label: 'name',
                    children: 'children',
                }
            }}
            {...props}
        >
        </ProFormTreeSelect>
    );
};

export default PostSelector;