
import {ElevatorMachineMetaInfo, ElevatorMachineMetaInfoQto} from './../type/ElevatorMachineMetaInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addElevatorMachineMetaInfo = async (params:ElevatorMachineMetaInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/elevator-machine-meta-info`, params);
};


const editElevatorMachineMetaInfo = async (params:ElevatorMachineMetaInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/elevator-machine-meta-info/${params.id}`, params);
};


const patchEditElevatorMachineMetaInfo = async (params:ElevatorMachineMetaInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/elevator-machine-meta-info/${params.id}`, params);
};


const deleteElevatorMachineMetaInfo = async (params:ElevatorMachineMetaInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/elevator-machine-meta-info/${params.id}`, params);
};


const findElevatorMachineMetaInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-meta-info/${params}`, params);
};

const findElevatorMachineMetaInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-meta-info/list`, params);
};

const findElevatorMachineMetaInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/elevator-machine-meta-info/page`, params);
};



export default {
    addElevatorMachineMetaInfo,
    editElevatorMachineMetaInfo,
    patchEditElevatorMachineMetaInfo,
    deleteElevatorMachineMetaInfo,
    findElevatorMachineMetaInfoById,
    findElevatorMachineMetaInfoList,
    findElevatorMachineMetaInfoPage,
};


