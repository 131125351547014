import _ from 'lodash';

import { DrawerProps, Image } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormText, } from '@ant-design/pro-form';
import { EmployeeBankCard } from './../../type/EmployeeBankCardType';
import EmployeeBankCardApi from '../../api/EmployeeBankCardApi';
import SingleFileSelector from '../../../file/component/SingleFileSelector';
import GlobalConst from '../../../../utils/GlobalConst';

export interface EmployeeBankCardDetailProps extends DrawerProps {
    employeeBankCard?: EmployeeBankCard,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const EmployeeBankCardDetail:React.FC<EmployeeBankCardDetailProps> = (props)=>{

    const {employeeBankCard, onFail, onSuccess, actionRef} = props;
    const [title, setTitle] = useState<string|undefined>(employeeBankCard?.employee?.name + "(" + employeeBankCard?.employee?.jobNo + ")");

    return (
        <Drawer
            title={title}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onSuccess}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<EmployeeBankCard>
                column={2}
                actionRef={actionRef}
                request={async ()=>{
                    const result:EmployeeBankCard = await EmployeeBankCardApi.findEmployeeBankCardById(employeeBankCard?.id||"");
                    setTitle(result?.employee.name+ "(" + result?.employee?.jobNo + ")");

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{

                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await EmployeeBankCardApi.patchEditEmployeeBankCard(requestData);
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['employee']}
                        label="姓名"
                        valueType="text"
                        copyable={false}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.employee?.name}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['employee']}
                        label="工号"
                        valueType="text"
                        copyable={false}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.employee?.jobNo}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['bankCode']}
                        label="银行代码"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['bankName']}
                        label="银行名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['bankAccount']}
                        label="银行账号"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item>
                        
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        dataIndex={['bankCardFrontImageUrl']}
                        label="银行卡正面图片"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    <Image
                                        src={GlobalConst.FILE_SERVER + dom?.toString()}
                                        width={240}
                                        height={120}
                                    />
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <SingleFileSelector />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['bankCardBackImageUrl']}
                        label="银行卡背面图片"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    <Image
                                        src={GlobalConst.FILE_SERVER + dom?.toString()}
                                        width={240}
                                        height={120}
                                    />
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <SingleFileSelector />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default EmployeeBankCardDetail;