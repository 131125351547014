
import {AttendanceMachine, AttendanceMachineQto} from './../type/AttendanceMachineType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addAttendanceMachine = async (params:AttendanceMachine)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/attendance-machine`, params);
};


const editAttendanceMachine = async (params:AttendanceMachine)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/attendance-machine/${params.id}`, params);
};


const patchEditAttendanceMachine = async (params:AttendanceMachine)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/attendance-machine/${params.id}`, params);
};


const deleteAttendanceMachine = async (params:AttendanceMachine)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/attendance-machine/${params.id}`, {});
};


const findAttendanceMachineById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-machine/${params}`, {});
};

const findAttendanceMachineList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-machine/list`, params);
};

const findAttendanceMachinePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/attendance-machine/page`, params);
};

const recheckEmployeeAttendanceMachineAuthSyncInfo = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/employee-attendance-machine-auth-sync-info/recheck-request`, params);
};


export default {
    addAttendanceMachine,
    editAttendanceMachine,
    patchEditAttendanceMachine,
    deleteAttendanceMachine,
    findAttendanceMachineById,
    findAttendanceMachineList,
    findAttendanceMachinePage,
    recheckEmployeeAttendanceMachineAuthSyncInfo,
};


