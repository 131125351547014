import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormRadio } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { P330800DirectDeviceInfo } from './../../type/P330800DirectDeviceInfoType';
import P330800DirectDeviceInfoApi from '../../api/P330800DirectDeviceInfoApi';
import { message } from 'antd';

export interface P330800DirectDeviceInfoAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const P330800DirectDeviceInfoAddForm:React.FC<P330800DirectDeviceInfoAddFormProps> = (props)=>{

    const [form] = Form.useForm<P330800DirectDeviceInfo>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建浙里建设备信息"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  P330800DirectDeviceInfo)=>{
                    await P330800DirectDeviceInfoApi.addP330800DirectDeviceInfo(values);
                    form.resetFields();
                    message.success("新建浙里建设备信息成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['sn']}
                            label="sn"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "sn不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormRadio.Group
                            name={['checkType']}
                            label="进出类型"
                            tooltip=""
                            placeholder=""
                            valueEnum={{
                                CHECK_IN: '进',
                                CHECK_OUT: '出',
                            }}
                            rules={[{required:false, message: "进出类型不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['accessId']}
                            label="accessId"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "accessId不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['accessKey']}
                            label="accessKey"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "accessKey不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                    <Col span={24}>
                        <ProFormText
                            name={['accessSecret']}
                            label="accessKey"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "accessKey不能为空"}]}
                            {...FormLayout.itemLayout.span24}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default P330800DirectDeviceInfoAddForm;