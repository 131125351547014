
import {DustMachineMetaInfo, DustMachineMetaInfoQto} from './../type/DustMachineMetaInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addDustMachineMetaInfo = async (params:DustMachineMetaInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/dust-machine-meta-info`, params);
};


const editDustMachineMetaInfo = async (params:DustMachineMetaInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/dust-machine-meta-info/${params.id}`, params);
};


const patchEditDustMachineMetaInfo = async (params:DustMachineMetaInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/dust-machine-meta-info/${params.id}`, params);
};


const deleteDustMachineMetaInfo = async (params:DustMachineMetaInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/dust-machine-meta-info/${params.id}`, params);
};


const findDustMachineMetaInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-meta-info/${params}`, params);
};

const findDustMachineMetaInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-meta-info/list`, params);
};

const findDustMachineMetaInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/dust-machine-meta-info/page`, params);
};



export default {
    addDustMachineMetaInfo,
    editDustMachineMetaInfo,
    patchEditDustMachineMetaInfo,
    deleteDustMachineMetaInfo,
    findDustMachineMetaInfoById,
    findDustMachineMetaInfoList,
    findDustMachineMetaInfoPage,
};


