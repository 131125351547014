import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, ProFormDigit, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { SubWorkType } from './../../type/SubWorkTypeType';
import SubWorkTypeApi from '../../api/SubWorkTypeApi';
import { message } from 'antd';

export interface SubWorkTypeAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const SubWorkTypeAddForm:React.FC<SubWorkTypeAddFormProps> = (props)=>{

    const [form] = Form.useForm<SubWorkType>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建子工种类型"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  SubWorkType)=>{
                    await SubWorkTypeApi.addSubWorkType(values);
                    form.resetFields();
                    message.success("新建子工种类型成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['orgId']}
                            label="机构ID"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "机构ID不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['text']}
                            label="显示文本"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "显示文本不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['value']}
                            label="值"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "值不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormDigit
                            name={['sortNo']}
                            label="显示顺序"
                            tooltip=""
                            placeholder=""
                            rules={[{required:true, message: "显示顺序不能为空"}]}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default SubWorkTypeAddForm;