
import {LedMachine, LedMachineQto} from './../type/LedMachineType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addLedMachine = async (params:LedMachine)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/led-machine`, params);
};


const editLedMachine = async (params:LedMachine)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/led-machine/${params.id}`, params);
};


const patchEditLedMachine = async (params:LedMachine)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/led-machine/${params.id}`, params);
};


const deleteLedMachine = async (params:LedMachine)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/led-machine/${params.id}`, params);
};


const findLedMachineById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/led-machine/${params}`, params);
};

const findLedMachineList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/led-machine/list`, params);
};

const findLedMachinePage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/led-machine/page`, params);
};



export default {
    addLedMachine,
    editLedMachine,
    patchEditLedMachine,
    deleteLedMachine,
    findLedMachineById,
    findLedMachineList,
    findLedMachinePage,
};


