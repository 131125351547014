import { Tag } from "antd";

const EmployeeHealthQrCodeDisplay:React.FC<{value:string}> = (props)=>{

    let text = "-";
    let color = "";

    switch(props.value){
        case 'NONE':
            text="-";
            color="gray";
            break;
        case 'GREEN':
            text="绿码";
            color="green";
            break;
        case 'YELLOW':
            text="黄码";
            color="organe";
            break;
        case 'RED':
            text="红码";
            color="red";
            break;
    }

    return (
        <Tag color={color}>
            {text}
        </Tag>
    );
}

export default EmployeeHealthQrCodeDisplay;