
import {LaborContract, LaborContractQto} from './../type/LaborContractType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addLaborContract = async (params:LaborContract)=>{

    return HttpInvoker.postObject(`/api/tenant/hr/labor-contract`, params);
};


const editLaborContract = async (params:LaborContract)=>{

    return HttpInvoker.putObject(`/api/tenant/hr/labor-contract/${params.id}`, params);
};


const patchEditLaborContract = async (params:LaborContract)=>{

    return HttpInvoker.patchObject(`/api/tenant/hr/labor-contract/${params.id}`, params);
};


const deleteLaborContract = async (params:LaborContract)=>{

    return HttpInvoker.deleteObject(`/api/tenant/hr/labor-contract/${params.id}`, params);
};


const findLaborContractById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract/${params}`, params);
};

const findLaborContractList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract/list`, params);
};

const findLaborContractPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/hr/labor-contract/page`, params);
};



export default {
    addLaborContract,
    editLaborContract,
    patchEditLaborContract,
    deleteLaborContract,
    findLaborContractById,
    findLaborContractList,
    findLaborContractPage,
};


