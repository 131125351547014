
import {GovPlatform, GovPlatformQto} from './../type/GovPlatformType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addGovPlatform = async (params:GovPlatform)=>{

    return HttpInvoker.postObject(`/api/platform/hr-sync/gov-platform`, params);
};


const editGovPlatform = async (params:GovPlatform)=>{

    return HttpInvoker.putObject(`/api/platform/hr-sync/gov-platform/${params.id}`, params);
};


const patchEditGovPlatform = async (params:GovPlatform)=>{

    return HttpInvoker.patchObject(`/api/platform/hr-sync/gov-platform/${params.id}`, params);
};


const deleteGovPlatform = async (params:GovPlatform)=>{

    return HttpInvoker.deleteObject(`/api/platform/hr-sync/gov-platform/${params.id}`, params);
};


const findGovPlatformById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform/${params}`, params);
};

const findGovPlatformList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform/list`, params);
};

const findGovPlatformPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/platform/hr-sync/gov-platform/page`, params);
};



export default {
    addGovPlatform,
    editGovPlatform,
    patchEditGovPlatform,
    deleteGovPlatform,
    findGovPlatformById,
    findGovPlatformList,
    findGovPlatformPage,
};


