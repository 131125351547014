import React, {FC, useState, useRef, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, Button, message, Tree, TreeProps, Spin} from 'antd';
import { ProColumns, ActionType } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import {DownOutlined, RedoOutlined, } from '@ant-design/icons';

import type {Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { Post } from './../../type/PostType';
import PostApi from "../../api/PostApi";
import PostAddForm from "./PostAddForm";
import PostDetail from "./PostDetail";
import ProCard from "@ant-design/pro-card";
import { resolve } from "path";


const PostTable:React.FC<{
    refreshTreeData:()=>void,
    rootId: string|undefined,
}> = (props)=>{

    const {rootId, refreshTreeData} = props;

    const tableActionRef = useRef<ActionType>();
    const detailActionRef = useRef<ProDescriptionsActionType>();
    const [addFormVisible, setAddFormVisible] = useState<boolean>(false);
    const [detailVisible, setDetailVisible] = useState<boolean>(false);
    const [currentPost, setCurrentPost] = useState<Post>({});

    const refreshData = ()=>{
        tableActionRef.current?.reload();
        refreshTreeData();
    }

    useEffect(()=>{
        tableActionRef.current?.reload();
    }, [rootId]);

    const deletePost = (post:Post)=>{
        Modal.confirm({
            title: "删除职务确认",
            content: "确定删除该职务吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await PostApi.deletePost(post);
                refreshData();
            }
        });
    }

    const showAddForm = ()=>{
        setAddFormVisible(true);
    }

    const hideAddForm = ()=>{
        setAddFormVisible(false);
    }

    const showDetail = (post:Post)=>{
        setCurrentPost(post);
        setDetailVisible(true);
    }

    const hideDetail = ()=>{
        setCurrentPost({});
        setDetailVisible(false);
    }

    const rowExtraButtons : React.FC<{
        item: Post
    }> = ({item}) => {
        return (
            <Dropdown
                key="row-extra-btns"
                overlay={
                    <Menu
                        onClick={({key})=>{
                            if(key==='delete') {
                                deletePost(item);
                            }
                        }}
                    >
                        <Menu.Item key="delete">
                            删除
                        </Menu.Item>
                    </Menu>
                }
            >
                <a>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const columns:ProColumns<Post>[] = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 60,
            hideInSearch: true,
            hideInSetting: true,
            hideInForm: true,
            copyable: false,
        },
        {
            key: "parentId",
            title: "上级职务ID",
            dataIndex: "parentId",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "code",
            title: "职务编码",
            dataIndex: "code",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "name",
            title: "职务名称",
            dataIndex: "name",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "nameAbbr",
            title: "职务简称",
            dataIndex: "nameAbbr",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            key: "sortNo",
            title: "显示顺序",
            dataIndex: "sortNo",
            width: 100,
            hideInTable: false,
            hideInSearch: false,
            hideInSetting: false,
            hideInForm: false,
            copyable: false,
        },
        {
            title: '操作',
            valueType: 'option',
            dataIndex: 'actions',
            render: (text, record:Post, _, action) =>{
                return [
                    <a key='view' onClick={()=>{
                        setCurrentPost(record);
                        setDetailVisible(true);
                        }}>
                        查看
                    </a>,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    return (
        <>
            <ProTable<Post, Pagination>
                headerTitle="职务列表"
                rowKey="id"
                bordered={true}
                columns={columns}
                actionRef={tableActionRef}
                scroll={{x: TableUtils.getTableScrollX(columns)}}
                rowSelection={{
                    alwaysShowAlert:true,
                    selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                    defaultSelectedRowKeys: [],
                }}
                tableAlertRender={({ selectedRowKeys, selectedRows, onCleanSelected }) => (
                    <Space size={24}>
                          <span>
                            已选 {selectedRowKeys.length} 项
                            <a style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                              取消选择
                            </a>
                          </span>
                        </Space>
                )}
                tableAlertOptionRender={({selectedRowKeys}) => {
                    return (
                        <Space size={16}>
                            <a onClick={async ()=>{
                                Modal.confirm({
                                    title: '删除职务',
                                    content: '确认删除所选职务吗？',
                                    okText: '删除所选',
                                    cancelText: '取消',
                                    onOk:async ()=>{
                                        const hide = message.loading("数据删除中....");
                                        for(let i=0; i<selectedRowKeys.length; i++){
                                            let id:string = selectedRowKeys[i]+"";
                                            try{
                                            await PostApi.deletePost({id:id});
                                            }catch(ex){

                                            }
                                        }
                                        hide();
                                        refreshData();
                                        message.success("数据删除完成！");
                                    }
                                });
                            }}>
                                批量删除
                            </a>
                        </Space>
                    );
                }}
                toolBarRender={()=>{
                    return [
                        <Button
                            type="primary"
                            onClick={showAddForm}
                        >
                            新建
                        </Button>
                    ];
                }}
                request={async (params={}, sort, filter)=>{

                    params.page = params.current;
                    const pageInfo = await PostApi.findPostPage({
                        deletedFlag: false,
                        rootId: rootId,
                        ...params,
                    });

                    pageInfo.list.forEach((item:any, idx:number)=>{
                        item.rowNo = ((params.current||1)-1)*(params.pageSize||20)+idx+1;
                    });

                    return {
                        success: true,
                        data: pageInfo.list,
                    };
                }}
            />

            <PostAddForm
                visible={addFormVisible}
                onFail={hideAddForm}
                onSuccess={refreshData}
            />

            <PostDetail
                visible={detailVisible}
                post={currentPost}
                actionRef={detailActionRef}
                onFail={hideDetail}
                onSuccess={refreshData}
            />
        </>
    );
};


const PostPage:React.FC = () => {

    const [rootId, setRootId] = useState<string>();
    const [treeData, setTreeData] = useState<Post[]>([]);
    const [treeLoading, setTreeLoading] = useState<boolean>(false);

    const refreshTreeData = ()=>{
        setTreeLoading(true);
        PostApi.findPostTree({
            deletedFlag: false,
        }).then((treeList)=>{
            setTreeData(treeList);
        }).finally(()=>{
            setTreeLoading(false);
        });
    }

    useEffect(()=>{
        refreshTreeData();
    }, []);

    return (
        <ProCard split="vertical">
            <ProCard
                title={"职务列表"}
                headerBordered={true}
                colSpan={"240px"}
                bodyStyle={{paddingLeft:8, paddingRight:8}}
                extra={[
                    <Button
                        key="refresh"
                        type="link"
                        shape="circle"
                        icon={<RedoOutlined />}
                        onClick={()=>{
                                refreshTreeData();
                        }}
                    />
                ]}
            >
                <Spin spinning={treeLoading} tip="数据加载中..." >
                    <Tree
                        treeData={treeData}
                        fieldNames={{
                            title: 'name',
                            key: 'id',
                            children: 'children'
                        }}
                        onSelect={(selectedKeys)=>{
                            setRootId(selectedKeys[0]?selectedKeys[0].toString():"");
                        }}
                    />
                </Spin>
            </ProCard>
            <ProCard bodyStyle={{backgroundColor:'rgb(240,242,245)', padding:"0px 0px 0px 16px"}}>
                <PostTable
                    refreshTreeData={refreshTreeData}
                    rootId={rootId}
                />
            </ProCard>
        </ProCard>
    );
}

export default PostPage;