import _ from 'lodash';

import type { DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";
import type {ProFormProps} from '@ant-design/pro-form';

import React, { useRef } from 'react';
import {Button, Drawer, Space, Row, Col, Form, } from 'antd';
import {FooterToolbar} from '@ant-design/pro-layout';
import ProForm, {ModalForm, DrawerForm, ProFormText, } from '@ant-design/pro-form';

import FormLayout from './../../../../utils/ui/layout/FormLayout';

import { Staff } from './../../type/StaffType';
import StaffApi from '../../api/StaffApi';
import { message } from 'antd';

export interface StaffAddFormProps extends DrawerProps {
    onFail:()=>void,
    onSuccess:()=>void,
};



const StaffAddForm:React.FC<StaffAddFormProps> = (props)=>{

    const [form] = Form.useForm<Staff>();
    const {onFail, onSuccess} = props;

    return (
        <Drawer
            title="新建人员"
            destroyOnClose={false}
            closable={false}
            width={960}
            extra={[
                <Button
                    key='closeBtn'
                    type='primary'
                    style={{backgroundColor:'red', border:0}}
                    onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess')}
        >
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onFinish={async (values:  Staff)=>{
                    await StaffApi.addStaff(values);
                    form.resetFields();
                    message.success("新建人员成功！");
                    if(onSuccess){
                        onSuccess();
                    }else{
                        onFail();
                    }
                    return true;
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                    <Col span={12}>
                        <ProFormText
                            name={['jobNo']}
                            label="工号"
                            tooltip=""
                            placeholder=""
                            required={false}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['name']}
                            label="姓名"
                            tooltip=""
                            placeholder=""
                            required={true}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['sex']}
                            label="性别"
                            tooltip=""
                            placeholder=""
                            required={true}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['mobile']}
                            label="手机号"
                            tooltip=""
                            placeholder=""
                            required={true}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                    <Col span={12}>
                        <ProFormText
                            name={['email']}
                            label="电子邮箱"
                            tooltip=""
                            placeholder=""
                            required={false}
                            {...FormLayout.itemLayout.span12}
                        />
                    </Col>
                </Row>
            </ProForm>
        </Drawer>
    );
};

export default StaffAddForm;