import React, {FC, useState, useRef, useEffect} from "react";

import {Table, Space, Modal, Dropdown, Menu, message, Layout, Form, Row, Col, Input, Button, Divider, Spin, FormInstance, Alert, Tag} from 'antd';

import {DownOutlined, RedoOutlined, SettingFilled, UpOutlined} from '@ant-design/icons';

import {Pageable, Pagination} from './../../../../utils/data/Type';
import TableUtils from './../../../../utils/ui/table/TableUtils';
import { LaborContract } from './../../type/LaborContractType';
import LaborContractApi from "../../api/LaborContractApi";
import LaborContractAddForm from "./LaborContractAddForm";
import LaborContractDetail from "./LaborContractDetail";
import { ColumnsType, ColumnType } from "antd/lib/table";
import TableSizeSetting from "../../../../components/antx/table/TableSizeSetting";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import TableColumnSetting from "../../../../components/antx/table/TableColumnSetting";
import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import EmployeeStatusDisplay from "../Employee/EmployeeStatusDisplay";
import DictItemDisplay from "../../../system/pages/DictItem/DictItemDisplay";
import EmployeeApi from "../../api/EmployeeApi";
import LaborContractStatusDisplay from "./LaborContractStatusDisplay";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";
import LaborContractSettingSettingDetail from "./LaborContractSettingDetail";

const getTableScrollY = (size:SizeType, pagination:any, tableHeight:number)=>{

    let scrollY = tableHeight;

    switch(size){
        case 'small':
            scrollY = scrollY-39*2;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        case 'middle':
            scrollY = scrollY-47*2;
            if(pagination){
                scrollY = scrollY-56;
            }
            return scrollY;
        default:
            scrollY = scrollY-55*2;
            if(pagination){
                scrollY = scrollY-64;
            }
            return scrollY;
    }
}

const LaborContractPage:React.FC = ()=>{
    const container = useRef<HTMLDivElement>(null);
    const queryFormRef = useRef<FormInstance>(null);

    const [addFormVisible, setAddFormVisible] = useState<boolean>(false); //新建表单可见状态
    const [detailVisible, setDetailVisible] = useState<boolean>(false); //明细可见状态
    const [queryFormExpand, setQueryFormExpand] = useState<boolean>(false); //查询表单展开状态
    const [currentEmployee, setCurrentEmployee] = useState<LaborContract>({}); //当前记录
    const [tableSize, setTableSize] = useState<SizeType>('middle'); //表格尺寸
    const [columns, setColumns] = useState<ColumnsType<LaborContract>>([]); //表格列
    const [tableHeight, setTableHeight] = useState<number>(0); //表格高度
    const [queryParams, setQueryParams] = useState<any>({}); //查询参数
    const [pageData, setPageData] = useState<Pagination>(); //分页查询服务端返回数据
    const [spinning, setSpinning] = useState<boolean>(false); //加载状态
    const [selectedRows, setSelectedRows] = useState<LaborContract[]>([]);
    const [settingDetailVisible, setSettingDetailVisible] = useState<boolean>(false); //加载状态

    const defaultColumns:ColumnsType<LaborContract> = [
        {
            key: "rowNo",
            title: "序号",
            dataIndex: "rowNo",
            width: 70,
            fixed: 'left',
        },
        {
            key: "jobNo",
            title: "工号",
            dataIndex: "jobNo",
            width: 100,
            ellipsis: true,
            fixed: 'left',
        },
        {
            key: "name",
            title: "姓名",
            dataIndex: "name",
            width: 120,
            ellipsis: true,
            fixed: 'left',
            render:(val, record)=>{
                return (
                    <div>
                        {val}&nbsp;
                        <EmployeeStatusDisplay value={record?.workInfo?.status} />
                    </div>
                );
            }
        },
        {
            key: 'idCardInfo',
            title: '证件信息',
            children: [
                {
                    key: "idCardType",
                    title: "证件类型",
                    dataIndex: "idCardInfo",
                    width: 120,
                    ellipsis: true,
                    render:(val)=>{
                        return (
                            <DictItemDisplay 
                                entryCode="HR_CER_TYPE"
                                value={val?.idCardType}
                            />
                        );
                    },
                },
                {
                    key: "idCardNo",
                    title: "证件号码",
                    dataIndex: "idCardInfo",
                    width: 180,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.idCardNo
                    }
                },
            ]
        },
        {
            key: 'workInfo',
            title: '务工信息',
            children: [
                {
                    key: "orgName",
                    title: "参建单位",
                    dataIndex: "org",
                    width: 180,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.name;
                    }
                },
                {
                    key: "workTeamName",
                    title: "所属班组",
                    dataIndex: "workTeam",
                    width: 160,
                    ellipsis: true,
                    render:(val)=>{
                        return val?.name;
                    }
                },
                {
                    key: "workType",
                    title: "工种类型",
                    dataIndex: "workInfo",
                    width: 120,
                    ellipsis: true,
                    render:(val)=>{
                        if(val?.workType==='900'){
                            return (
                                <Tag color={'#108ee9'}>
                                    <DictItemDisplay 
                                        entryCode="JZ_MANAGER_POST_TYPE"
                                        value={val?.manageType}
                                    />
                                </Tag>
                            );
                        } else {
                            return (
                                <Tag>
                                    <DictItemDisplay 
                                        entryCode="HR_WORK_TYPE"
                                        value={val?.workType}
                                    />
                                </Tag>
                            );
                        }
                    }
                },
            ]
        },
        {
            key: "laborContractStatus",
            title: "人事合同状态",
            dataIndex: "laborContract",
            width: 180,
            ellipsis: true,
            render:(val)=>{
                return (
                    <LaborContractStatusDisplay 
                        value={val?.laborContractStatus||""}
                    />
                );
            }
        },
        {
            title: '操作',
            width: 120,
            dataIndex: 'actions',
            fixed: 'right',
            render: (val, record) =>{
                return [
                    record.laborContract.laborContractStatus==='SIGNED'?
                    <a key='view' onClick={()=>{
                        setCurrentEmployee(record);
                        setDetailVisible(true);
                        }}>
                        查看
                    </a>:
                    <a key='view' onClick={()=>{
                        setCurrentEmployee(record);
                        setAddFormVisible(true);
                        }}>
                        签订
                    </a>,
                    <Divider type="vertical" />,
                    rowExtraButtons({item:record}),
                ];
            }
        },
    ];

    const reloadData = (params:object|undefined)=>{

        setSpinning(true);
        const newQueryParams = {
            ...queryParams,
            ...(params||{}),
            deletedFlag: false,
            orderBy: 'employee.job_no desc',
        };
        setQueryParams(newQueryParams);

        EmployeeApi.findEmployeePage(newQueryParams).then(pageInfo=>{
            pageInfo.list.forEach((i:any,idx:number)=>{
                i.rowNo = idx + 1 + (pageInfo.pageNum-1)*pageInfo.pageSize;
            });
            setPageData(pageInfo);
        }).finally(()=>{
            setSpinning(false);
        });
    }

    const batchDelete = async()=>{
        if(selectedRows.length<1){
            message.error("请先选择数据");
            return;
        }

        Modal.confirm({
            title: "批量删除",
            content: "确定删除所选人事合同吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                for(let i=0; i<selectedRows.length; i++){
                    const row = selectedRows[i];

                    await LaborContractApi.deleteLaborContract(row);
                }

                message.success("数据删除成功！");
                reloadData({});
            }
        });
    }

    //表格大小变化事件
    useEffect(()=>{

        setColumns(defaultColumns);
        reloadData({});

        const interval = setInterval(()=>{
            if(container.current?.offsetHeight!=tableHeight){
                console.log(tableHeight);
                setTableHeight(container.current?.offsetHeight||0);
            }
        }, 200);

        return ()=>{
            clearInterval(interval);
        };
    }, []);

    const deleteLaborContract = (laborContract:LaborContract)=>{
        Modal.confirm({
            title: "删除人事合同确认",
            content: "确定删除该人事合同吗？",
            okText: "确定",
            cancelText: "取消",
            onOk: async () => {
                await LaborContractApi.deleteLaborContract(laborContract);
            }
        });
    }

    const rowExtraButtons : React.FC<{
        item: LaborContract
    }> = ({item}) => {
        return (
            <Dropdown
                key="row-extra-btns"
                overlay={
                    <Menu
                        onClick={({key})=>{
                            if(key==='delete') {
                                deleteLaborContract(item);
                            } else if(key==='download') {
                                window.open("/api/tenant/hr/labor-contract/word/" + item?.laborContract?.laborContractId + "?Authorization=" + localStorage.getItem("Authorization"));
                            } else if(key==='downloadEmpty') {
                                window.open("/api/tenant/hr/labor-contract/empty-word/" + item?.id + "?Authorization=" + localStorage.getItem("Authorization"));
                            }
                        }}
                    >
                        <Menu.Item key="downloadEmpty">
                            下载空白合同
                        </Menu.Item>
                        <Menu.Item key="download">
                            下载合同
                        </Menu.Item>
                        <Menu.Item key="delete">
                            删除
                        </Menu.Item>
                    </Menu>
                }
            >
                <a key='extraBtn'>
                    更多 <DownOutlined />
                </a>
            </Dropdown>
        );
    };

    const formFieldSpan={ xs: 24, sm: 12, md: 8, lg: 6, xl:6, xxl:6 };

    const queryFormFields = [
        <div key='jobNo' style={{display:'inline-block', height:40, width:140}}>
            <ProFormText
                label="工号"
                name="jobNo"
            />
        </div>,
        <div key='nameLike' style={{display:'inline-block', height:40, width:140}}>
            <ProFormText
                label="姓名"
                name="nameLike"
            />
        </div>,
        <div key='idCardNo' style={{display:'inline-block', height:40, width:260}}>
            <ProFormText
                label="证件号码"
                name="idCardNo"
            />
        </div>,
        <div key='mobile' style={{display:'inline-block', height:40, width:200}}>
            <ProFormText
                label="手机号"
                name="mobile"
            />
        </div>,
        <div key='orgId' style={{display:'inline-block', height:40, width:260}}>
            <OrganizationSelector
                label="参建单位"
                name="orgId"
            />
        </div>,
        <div key='workTeamId' style={{display:'inline-block', height:40, width:260}}>
            <WorkTeamSelector
                label="所属班组"
                name="workTeamId"
            />
        </div>,
        <div key='workRole' style={{display:'inline-block', height:40, width:160}}>
            <ProFormSelect
                label="人员角色"
                name="workRole"
                valueEnum={{
                    10: '管理人员',
                    20: '普通工人',
                }}
            />
        </div>,
        <div key='status' style={{display:'inline-block', height:40, width:260}}>
            <ProFormSelect
                label="人员状态"
                name="status"
                valueEnum={{
                    EMPLOYMENT: '在职',
                    RESIGNED: '离职',
                    TEMP_RESIGNED: '临时离职'
                }}
            />
        </div>,
    ];

    const getQueryFields = ()=>{
        let endIdx = queryFormExpand&&queryFormFields.length>2?queryFormFields.length:2;

        const fields = [];

        for(let i=0; i<endIdx; i++){
            fields.push(queryFormFields[i])
        }

        return fields;
    }

    return (
        <div style={{width:'100%', height:'100%', display: "flex", flexDirection: 'column'}}>
            <div style={{backgroundColor:'#ffffff', padding:"8px 16px 0px 16px", marginBottom:12}}>
                <Form
                    ref={queryFormRef}
                    onValuesChange={(values)=>{
                        setQueryParams({
                            ...queryParams,
                            ...values,
                        })
                    }}
                >
                    <Space align="start" wrap>
                        { getQueryFields()}

                        <Button
                            type="primary"
                            onClick={()=>{
                                reloadData({});
                            }}
                        >
                            查询
                        </Button>

                        <Button
                            type="primary"
                            danger
                            onClick={()=>{
                                queryFormRef.current?.resetFields();
                            }}
                        >
                            重置
                        </Button>

                        {
                            queryFormFields.length>2 &&
                            <Button
                                type="link"
                                onClick={()=>{
                                    setQueryFormExpand(!queryFormExpand);
                                }}
                            >
                                {queryFormExpand?'收起':'展开'}{queryFormExpand?<UpOutlined />:<DownOutlined />}
                            </Button>
                        }
                    </Space>
                </Form>
            </div>

            <div style={{backgroundColor:'#ffffff', height:56, lineHeight:'56px', paddingLeft:16, paddingRight:16}}>
                <div style={{fontWeight:'bold', display:'inline-block'}}>
                    人事合同签订状况
                </div>
                <div style={{display: 'inline-block', float: 'right'}}>
                    <Space size={16}>
                        {
                            selectedRows.length>0 &&
                            <Button type="link">
                                已选择 <strong>{selectedRows.length}</strong> 条
                            </Button>
                        }
                        <Button type="primary" icon={<SettingFilled />} onClick={()=>{
                            setSettingDetailVisible(true);
                        }}>
                            合同设置
                        </Button>
                        <Dropdown.Button
                            overlay={
                               <Menu
                                    onClick={({key})=>{
                                        if(key==='batchDelete'){
                                            batchDelete();
                                        }
                                    }}
                                    items={[{
                                        key: 'batchDelete',
                                        label: '批量删除',
                                    }]}
                               />
                            }
                        >
                            批量操作
                        </Dropdown.Button>
                        <RedoOutlined
                            style={{fontSize:16, cursor:'pointer'}}
                            onClick={()=>{
                                reloadData({});
                            }}
                        />
                        <TableSizeSetting
                            onChange={(val)=>{
                                setTableSize(val)
                            }}
                        />
                        <TableColumnSetting
                            columns={columns}
                            onChange={(value)=>{
                                setColumns(value)
                            }}
                        />
                    </Space>
                </div>
            </div>
            <div style={{flex:1, position:'relative'}}>
                <div
                    ref={container}
                    style={{width: '100%', height:'100%', position:'absolute'}}
                >
                    <Spin spinning={spinning}>
                        <Table
                            bordered
                            dataSource={pageData?.list}
                            pagination={{
                                current: queryParams?.page||1,
                                pageSize: queryParams?.pageSize||10,
                                total: pageData?.total,
                                pageSizeOptions: [10, 20, 50, 100],
                                showQuickJumper: true,
                                showSizeChanger: true,
                                onChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });
                                },
                                onShowSizeChange:(page, pageSize)=>{
                                    reloadData({
                                        page: page,
                                        pageSize: pageSize,
                                    });

                                }
                            }}
                            size={tableSize}
                            rowKey="id"
                            columns={columns}
                            scroll={{x: TableUtils.getTableScrollX(columns), y: getTableScrollY(tableSize, true, tableHeight), scrollToFirstRowOnChange:true}}
                            rowSelection={{
                                selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                                defaultSelectedRowKeys: [],
                                onChange:(selectedRowKeys, selectedRows)=>{
                                    setSelectedRows(selectedRows);
                                }
                            }}
                        />
                    </Spin>
                </div>
            </div>


            {
                addFormVisible &&
                <LaborContractAddForm
                    visible={true}
                    employee={currentEmployee}
                    onSuccess={()=>{
                        setAddFormVisible(false);
                        reloadData({});
                    }}
                    onFail={()=>{
                        setAddFormVisible(false);
                    }}
                />
            }

            {
                detailVisible &&
                <LaborContractDetail
                    employee={currentEmployee}
                    visible={true}
                    onSuccess={()=>{
                        setDetailVisible(false);
                        reloadData({});
                    }}
                    onFail={()=>{
                        setDetailVisible(false);
                    }}
                />
            }

            {
                settingDetailVisible &&
                <LaborContractSettingSettingDetail
                    visible={true}
                    onSuccess={()=>{
                        setSettingDetailVisible(false);
                    }}
                    onFail={()=>{
                        setSettingDetailVisible(false);
                    }}
                />
            }   
        </div>
    );
};

export default LaborContractPage;