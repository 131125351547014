import React, {useState, useEffect} from "react";

import type {SelectProps} from 'antd';
import { Select } from "antd";
import AttendanceMachineApi from "../../api/AttendanceMachineApi";
import type { AttendanceMachine } from "../../type/AttendanceMachineType";
import { ProFormSelectProps } from "@ant-design/pro-form/es/components/Select";
import { ProFormSelect } from "@ant-design/pro-form";

const AttendanceMachineSelector:React.FC<ProFormSelectProps> = (props)=>{

    const [options, setOptions] = useState<[]>([]);

    useEffect(()=>{
        AttendanceMachineApi.findAttendanceMachineList({
            limit: 100,
            deletedFlag: false,
        }).then((result:any)=>{
            const newOptions = result.map((attendanceMachine:AttendanceMachine)=>{
                return {
                    key: attendanceMachine.id,
                    value: attendanceMachine.sn,
                    label: `${attendanceMachine.sn}(${attendanceMachine.name})`,
                };
            });

            setOptions(newOptions);
        });
    }, []);

    return (
        <ProFormSelect
            options={options}
            {...props}
        />
    );
};

export default AttendanceMachineSelector;