import ObjectUtils from "../../../utils/data/ObjectUtils";

import routeList from './../../../config/RouterList';

export interface GlobalState {
    settings?: any,
    routeList: [],
    staffPosition?:any,
    dictEntryMap:{
        [propName: string]: any;
    },
    dictEntryList:{
        [propName: string]: any;
    },
    [propName: string]: any;
}

const defaultSettings = {
    "navTheme": "light",
    "primaryColor": "#1890ff",
    "layout": "mix",
    "contentWidth": "Fluid",
    "fixedHeader": true,
    "fixSiderbar": true,
    "headerHeight": 48,
    "splitMenus": false
  };

export default {

    namespace: 'global',

    state: <GlobalState> {
        settings: defaultSettings,
        routeList: routeList,
        staffPosition: null,
        dictEntryMap: {},
        dictEntryList: {},
    },

    effects: {

    },

    reducers: {
        updateState(state:GlobalState, action:any) {
            return { ...state, ...action.payload };
        },
      
        mergeState(state:GlobalState, action:any) {
            if (!action.payload) {
              return;
            }
            return ObjectUtils.mergeDeep(state, action.payload);
        },
    },

    subscriptions:{

    }
}