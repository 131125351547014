import _ from 'lodash';

import { Descriptions, DrawerProps } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormDigit, ProFormRadio, ProFormText, } from '@ant-design/pro-form';
import { LaborContractSetting } from './../../type/LaborContractSettingType';
import LaborContractSettingApi from '../../api/LaborContractSettingApi';
import { Employee } from '../../type/EmployeeType';
import DictItemDisplay from '../../../system/pages/DictItem/DictItemDisplay';

export interface LaborContractSettingSettingDetailProps extends DrawerProps {

    employee?: Employee,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const LaborContractSettingSettingDetail:React.FC<LaborContractSettingSettingDetailProps> = (props)=>{

    const {employee, onFail, onSuccess, actionRef} = props;
    const [laborContractSetting, setLaborContractSettingSetting] = useState<LaborContractSetting>({});

    return (
        <Drawer
            title={'合同设置'}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >

            <ProDescriptions<LaborContractSetting>
                title={'合同设置'}
                column={1}
                actionRef={actionRef}
                request={async ()=>{
                    const result:LaborContractSetting = await LaborContractSettingApi.findLaborContractSettingById(window?.store?.getState()?.global?.staffPosition?.tenantId);

                    setLaborContractSettingSetting(result);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: window.store.getState().global.staffPosition.tenantId
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        await LaborContractSettingApi.patchEditLaborContractSetting(requestData);
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['projectName']}
                        label="项目名称"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />

                     <ProDescriptions.Item
                        dataIndex={['companyName']}
                        label="甲方"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />

                    <ProDescriptions.Item
                       dataIndex={['legalMan']}
                       label="甲方法人"
                       valueType="text"
                       copyable={false}
                       render={(dom, entity, index, action) => {
                           return (
                               <div>
                                   {dom}
                               </div>
                           );
                       }}
                       renderFormItem={() => {
                           return <ProFormText />;
                       }}
                   />

                   <ProDescriptions.Item
                      dataIndex={['legalManIdCardNo']}
                      label="甲方身份证号"
                      valueType="text"
                      copyable={false}
                      render={(dom, entity, index, action) => {
                          return (
                              <div>
                                  {dom}
                              </div>
                          );
                      }}
                      renderFormItem={() => {
                          return <ProFormText />;
                      }}
                  />

                  <ProDescriptions.Item
                     dataIndex={['officeAddress']}
                     label="办公地址"
                     valueType="text"
                     copyable={false}
                     render={(dom, entity, index, action) => {
                         return (
                             <div>
                                 {dom}
                             </div>
                         );
                     }}
                     renderFormItem={() => {
                         return <ProFormText />;
                     }}
                 />

                 <ProDescriptions.Item
                    dataIndex={['telephone']}
                    label="联系电话"
                    valueType="text"
                    copyable={false}
                    render={(dom, entity, index, action) => {
                        return (
                            <div>
                                {dom}
                            </div>
                        );
                    }}
                    renderFormItem={() => {
                        return <ProFormText />;
                    }}
                />

                <ProDescriptions.Item
                   dataIndex={['projectProvince']}
                   label="项目所在省份"
                   valueType="text"
                   copyable={false}
                   render={(dom, entity, index, action) => {
                       return (
                           <div>
                               {dom}
                           </div>
                       );
                   }}
                   renderFormItem={() => {
                       return <ProFormText />;
                   }}
               />

               <ProDescriptions.Item
                  dataIndex={['projectCity']}
                  label="项目所在城市"
                  valueType="text"
                  copyable={false}
                  render={(dom, entity, index, action) => {
                      return (
                          <div>
                              {dom}
                          </div>
                      );
                  }}
                  renderFormItem={() => {
                      return <ProFormText />;
                  }}
                />

              <ProDescriptions.Item
                 dataIndex={['projectDistrict']}
                 label="项目所在区县"
                 valueType="text"
                 copyable={false}
                 render={(dom, entity, index, action) => {
                     return (
                         <div>
                             {dom}
                         </div>
                     );
                 }}
                 renderFormItem={() => {
                     return <ProFormText />;
                 }}
             />

             <ProDescriptions.Item
                dataIndex={['salaryDay']}
                label="工资发放日"
                valueType="text"
                copyable={false}
                render={(dom, entity, index, action) => {
                    return (
                        <div>
                            {dom}
                        </div>
                    );
                }}
                renderFormItem={() => {
                    return <ProFormText />;
                }}
            />

            <ProDescriptions.Item
               dataIndex={['settlementDays']}
               label="离职结算最大天数"
               valueType="text"
               copyable={false}
               render={(dom, entity, index, action) => {
                   return (
                       <div>
                           {dom}
                       </div>
                   );
               }}
               renderFormItem={() => {
                   return <ProFormText />;
               }}
           />

           <ProDescriptions.Item
              dataIndex={['courtName']}
              label="仲裁法院"
              valueType="text"
              copyable={false}
              render={(dom, entity, index, action) => {
                  return (
                      <div>
                          {dom}
                      </div>
                  );
              }}
              renderFormItem={() => {
                  return <ProFormText />;
              }}
          />

          <ProDescriptions.Item
             dataIndex={['leaderName']}
             label="项目负责人"
             valueType="text"
             copyable={false}
             render={(dom, entity, index, action) => {
                 return (
                     <div>
                         {dom}
                     </div>
                 );
             }}
             renderFormItem={() => {
                 return <ProFormText />;
             }}
         />

         <ProDescriptions.Item
            dataIndex={['leaderMobile']}
            label="项目负责人电话"
            valueType="text"
            copyable={false}
            render={(dom, entity, index, action) => {
                return (
                    <div>
                        {dom}
                    </div>
                );
            }}
            renderFormItem={() => {
                return <ProFormText />;
            }}
        />
            </ProDescriptions>
        </Drawer>
    );
};

export default LaborContractSettingSettingDetail;