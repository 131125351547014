import ProForm, { ProFormDatePicker, ProFormDigit, ProFormRadio, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Modal, Space, Form, Row, Col, Tag, Divider } from "antd";
import React, { useState } from "react";
import FormLayout from "../../../../utils/ui/layout/FormLayout";
import DictItemSelector from "../../../system/pages/DictItem/DictItemSelector";
import OrganizationSelector from "../../../system/pages/Organization/OrganizationSelector";
import ContractorApi from "../../api/ContractorApi";
import EmployeeEntryApi from "../../api/EmployeeEntryApi";
import { Contractor } from "../../type/ContractorType";
import { Employee } from "../../type/EmployeeType";
import WorkTeamSelector from "../WorkTeam/WorkTeamSelector";

interface EmployeeBatchEntryProps {
    employees: Employee[],
    onSuccess: ()=>void,
    onFail: ()=>void,
    visible: boolean,
}

const EmployeeBatchEntry:React.FC<EmployeeBatchEntryProps> = (props)=>{
    
    const [form] = Form.useForm<any>();
    const {employees, onSuccess, onFail, visible} = props;

    const [contractor, setContractor] = useState<Contractor>({});
    const [workRole, setWorkRole] = useState<string>("");

    return (
        <Modal
            title={"人员入职"}
            visible={visible}
            footer={null}
            onCancel={()=>{
                onFail();
            }}
        >
            <div>
                {(employees||[]).map(e=>{
                    return (
                        <Tag key={e.id}>{e.name}({e.jobNo})</Tag>
                    );
                })}
            </div>
            <Divider orientation="left">入职信息</Divider>
            <ProForm
                submitter={{
                    render: (_, dom) => <div style={{textAlign:'right', marginTop:16}}><Space>{dom}</Space></div>,
                }}
                onValuesChange={async (values)=>{
                    if(values?.orgId){
                        const contractor = await ContractorApi.findContractorById(values.orgId);

                        setContractor(contractor);
                    }

                    if(values?.workRole){
                        setWorkRole(values.workRole);
                    }
                }}
                onFinish={async (values:any)=>{
                    for(let i=0; i<employees.length; i++){
                        const emp = employees[i];

                        const newValues = {
                            employeeId: emp.id,
                            ...values,
                        };

                        if(workRole==='10'){
                            newValues.workType = '900';
                        }else{
                            newValues.manageType = null;
                        }

                        try{
                            await EmployeeEntryApi.addEmployeeEntry(newValues);
                            onSuccess();
                        }catch(ex){
                            return false;
                        }
                    }
                }}
                form={form}
                layout={'horizontal'}
            >
                <Row>
                        <Col span={24}>
                            <ProFormRadio.Group
                                name={['workRole']}
                                label="工人角色"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "工人角色不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                                options={[{
                                    label: '管理人员',
                                    value: '10'
                                }, {
                                    label: '建筑工人',
                                    value: '20',
                                }]}
                            />
                        </Col>
                        <Col span={24}>
                            <OrganizationSelector
                                name={['orgId']}
                                label="参建单位"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "参建单位不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                        <Col span={24}>
                            <WorkTeamSelector
                                params={{orgId: contractor?.id, managerTeamFlag: workRole==='10'}}
                                name={['workTeamId']}
                                label="所属班组"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "班组不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                        {
                            workRole==='20' &&
                            <Col span={24}>
                                <DictItemSelector
                                    entryCode='HR_WORK_TYPE'
                                    excludeValues={['900']}
                                    name={['workType']}
                                    label="工种类型"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "工种类型不能为空"}]}
                                    {...FormLayout.itemLayout.span24}
                                />
                            </Col>
                        }
                        {
                            workRole==='10' &&
                            <Col span={24}>
                                <DictItemSelector
                                    entryCode='JZ_MANAGER_POST_TYPE'
                                    groupKey={contractor.type}
                                    name={['manageType']}
                                    label="管理类型"
                                    tooltip=""
                                    placeholder=""
                                    rules={[{required:true, message: "管理类型不能为空"}]}
                                    {...FormLayout.itemLayout.span24}
                                />
                            </Col>
                        }
                        <Col span={24}>
                            <ProFormDatePicker
                                name={['entryTime']}
                                label="入职时间"
                                tooltip=""
                                placeholder=""
                                rules={[{required:true, message: "入职时间不能为空"}]}
                                {...FormLayout.itemLayout.span24}
                            />
                        </Col>
                </Row>
            </ProForm>
        </Modal>
    );
};

export default EmployeeBatchEntry;