import _ from 'lodash';

import { Divider, DrawerProps, Tag } from 'antd';
import type { ProDescriptionsActionType } from "@ant-design/pro-descriptions";

import React, { useRef, useState } from 'react';
import { Button, Drawer, message,   } from 'antd';
import ProDescriptions from '@ant-design/pro-descriptions';
import ProForm, {ProFormDatePicker, ProFormDigit, ProFormFieldSet, ProFormSwitch, ProFormText, ProFormTimePicker, } from '@ant-design/pro-form';
import { WorkTeam } from './../../type/WorkTeamType';
import WorkTeamApi from '../../api/WorkTeamApi';
import OrganizationSelector from '../../../system/pages/Organization/OrganizationSelector';

export interface WorkTeamDetailProps extends DrawerProps {
    workTeam?: WorkTeam,
    actionRef?: React.MutableRefObject<ProDescriptionsActionType | undefined>,
    onFail:()=>void,
    onSuccess:()=>void,
};

const WorkTeamDetail:React.FC<WorkTeamDetailProps> = (props)=>{

    const {workTeam, onFail, onSuccess} = props;
    const [title, setTitle] = useState<string|undefined>(workTeam?.name);

    return (
        <Drawer
            title={<div>{title}&nbsp;&nbsp;{workTeam?.managerTeamFlag && <Tag color={'#f50'}>管理班组</Tag>}</div>}
            width={960}
            closable={false}
            destroyOnClose={true}
            extra={[
                <Button
                        key='closeBtn'
                        type='primary'
                        style={{backgroundColor:'red', border:0}}
                        onClick={onFail}
                >
                    关闭
                </Button>
            ]}
            {..._.omit(props, 'onFail', 'onSuccess', 'actionRef')}
        >
            <ProDescriptions<WorkTeam>
                column={2}
                request={async ()=>{
                    const result:WorkTeam = await WorkTeamApi.findWorkTeamById(workTeam?.id||"");
                    setTitle(result?.name);

                    return {
                        success: true,
                        data: result,
                    };
                }}
                editable={{
                    onSave:async (keyPath:any, newData, oldData)=>{
                        const requestData:any = {
                            id: oldData?.id
                        };
                        let rTemp = requestData;
                        let nTemp = newData;

                        for(let i=0; i<keyPath.length; i++){
                            let field = keyPath[i];

                            if(i===keyPath.length-1){
                                rTemp[field] = nTemp[field];
                            }else{
                                rTemp[field] = {};
                                rTemp = rTemp[field];
                                nTemp = nTemp[field];
                            }
                        }

                        if(requestData['checkBeginTime']){
                            requestData['checkBeginTime'] = requestData['checkBeginTime']?.substring(11,16);
                        }
                        if(requestData['checkEndTime']){
                            requestData['checkEndTime'] = requestData['checkEndTime']?.substring(11,16);
                        }

                        const workTeam = await WorkTeamApi.patchEditWorkTeam(requestData);
                        setTitle(workTeam?.name);
                    }
                }}
            >
                    <ProDescriptions.Item
                        dataIndex={['orgId']}
                        label="参建单位"
                        valueType="text"
                        copyable={false}
                        editable={false}
                        render={(dom, entity, index, action) => {
                            
                            return (
                                <div>
                                    {entity?.org?.name||dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <OrganizationSelector />;
                        }}
                    />
                    
                    <ProDescriptions.Item
                        dataIndex={['name']}
                        label="班组名称"
                        valueType="text"
                        copyable={false}
                        editable={()=>{
                            return !workTeam?.managerTeamFlag;
                        }}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom} 
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['sortNo']}
                        label="显示顺序"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />
                    <ProDescriptions.Item
                        label=""
                    />
                    <ProDescriptions.Item
                        span={2}
                        dataIndex={['description']}
                        label="描述"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormText />;
                        }}
                    />


                    <ProDescriptions.Item 
                        span={2}
                    >
                        <div style={{fontWeight:'bold', marginTop:16}}>考勤设置</div>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        dataIndex={['enableMobileCheckWithoutImage']}
                        label="启用手机端无照考勤"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.enableMobileCheckWithoutImage?'启用':'禁用'}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormSwitch />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['enableMobileCheckWithImage']}
                        label="启用手机端照片考勤"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {entity?.enableMobileCheckWithImage?'启用':'禁用'}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormSwitch />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['checkBeginTime']}
                        label="考勤开始时段"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormTimePicker fieldProps={{format:'HH:mm'}} />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['checkEndTime']}
                        label="考勤结束时段"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormTimePicker fieldProps={{format:'HH:mm',}} />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['checkTimes']}
                        label="打卡次数"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['validRangeMinutes']}
                        label="两卡间隔分钟"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['halfCheckTimes']}
                        label="半日打卡次数"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['halfValidRangeMinutes']}
                        label="半日两卡间隔分钟"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDigit />;
                        }}
                    />

                    <ProDescriptions.Item 
                        span={2}
                    >
                        <div style={{fontWeight:'bold', marginTop:16}}>进离场信息</div>
                    </ProDescriptions.Item>
                    <ProDescriptions.Item
                        dataIndex={['entryTime']}
                        label="进场日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker fieldProps={{format:'HH:mm'}} />;
                        }}
                    />
                    <ProDescriptions.Item
                        dataIndex={['exitTime']}
                        label="离场日期"
                        valueType="text"
                        copyable={false}
                        render={(dom, entity, index, action) => {
                            return (
                                <div>
                                    {dom}
                                </div>
                            );
                        }}
                        renderFormItem={() => {
                            return <ProFormDatePicker fieldProps={{format:'HH:mm'}} />;
                        }}
                    />
            </ProDescriptions>
        </Drawer>
    );
};

export default WorkTeamDetail;