
import {TowerCraneMachineMetaInfo, TowerCraneMachineMetaInfoQto} from './../type/TowerCraneMachineMetaInfoType';
import HttpInvoker from './../../../utils/http/HttpInvoker';


const addTowerCraneMachineMetaInfo = async (params:TowerCraneMachineMetaInfo)=>{

    return HttpInvoker.postObject(`/api/tenant/iot/tower-crane-machine-meta-info`, params);
};


const editTowerCraneMachineMetaInfo = async (params:TowerCraneMachineMetaInfo)=>{

    return HttpInvoker.putObject(`/api/tenant/iot/tower-crane-machine-meta-info/${params.id}`, params);
};


const patchEditTowerCraneMachineMetaInfo = async (params:TowerCraneMachineMetaInfo)=>{

    return HttpInvoker.patchObject(`/api/tenant/iot/tower-crane-machine-meta-info/${params.id}`, params);
};


const deleteTowerCraneMachineMetaInfo = async (params:TowerCraneMachineMetaInfo)=>{

    return HttpInvoker.deleteObject(`/api/tenant/iot/tower-crane-machine-meta-info/${params.id}`, params);
};


const findTowerCraneMachineMetaInfoById = async (params:string)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-meta-info/${params}`, params);
};

const findTowerCraneMachineMetaInfoList = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-meta-info/list`, params);
};

const findTowerCraneMachineMetaInfoPage = async (params:any)=>{

    return HttpInvoker.getObject(`/api/tenant/iot/tower-crane-machine-meta-info/page`, params);
};



export default {
    addTowerCraneMachineMetaInfo,
    editTowerCraneMachineMetaInfo,
    patchEditTowerCraneMachineMetaInfo,
    deleteTowerCraneMachineMetaInfo,
    findTowerCraneMachineMetaInfoById,
    findTowerCraneMachineMetaInfoList,
    findTowerCraneMachineMetaInfoPage,
};


