import HttpInvoker from "../../../utils/http/HttpInvoker";


const attendanceDayReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/contractor-attendance-report/contractor-attendance-day-report`, params);
};

const attendanceMonthReport = (params:any)=>{
    return HttpInvoker.getObject(`/api/tenant/hr/contractor-attendance-report/contractor-attendance-month-report`, params);
};

export default {
    attendanceDayReport,
    attendanceMonthReport,
}