import { Tag } from "antd";

const LaborContractStatusDisplay:React.FC<{value:string}> = (props)=>{

    let text = "-";
    let color = "";

    switch(props.value){
        case 'SIGNED':
            text="已签订";
            color="#2db7f5";
            break;
        case 'EXPIRED':
            text="已过期";
            color="#f50";
            break;
        case 'EXPIRED':
            text="已作废";
            color="#f50";
            break;
        case 'UNSIGNED':
            text="未签订";
            color="#87d068";
            break;
    }

    return (
        <Tag color={color}>
            {text}
        </Tag>
    );
}

export default LaborContractStatusDisplay;